/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import SyncIcon from "@mui/icons-material/Sync";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LogoutIcon from "@mui/icons-material/Logout";
import { useCacheBuster } from "../hooks/CacheBuster";
import { useAuth } from "../hooks/useAuth";
import { NavLink } from "react-router-dom";
import Services from "./../services";
import { useLocalStorage } from "./../hooks/useLocalStorage";
import { Select, MenuItem, FormControl, InputLabel, TextField } from "@mui/material";
import FullScreenLoader from "./../components/FullScreenLoader";
import { useHistory } from "react-router";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import { navigatorStyle } from "../utils";
import HomeIcon from "@mui/icons-material/Home";
import InputRegex from "./../components/InputRegex";
import { points } from "../utils/points";
const urlObtenerSucursal = "sucursal";
const urlObtenerSucursales = "perfil/obtener-sucursales";
const urlObtenerPermisoSucursal = "perfil/obtener-permiso-sucursal";

const item = {
  py: "2px",
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
};

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
};

const navigatorStyleJSS = navigatorStyle();
export default function Navigator(props) {
  const navigatorJSS = navigatorStyleJSS();
  const { ...other } = props;
  const { signOut, user, permiso, setPermiso, setSucursal } = useAuth();
  const { refreshCacheAndReload } = useCacheBuster();
  const [loading, setLoading] = useState(false);
  const [permisoStorage, setPermisoStorage] = useLocalStorage("permiso", null);
  const [sucursalStorage, setSucursalStorage] = useLocalStorage("sucursal", null);
  const [sucursales, setSucursales] = useLocalStorage("sucursales", null);
  const [idSucursal, setIdSucursal] = useLocalStorage("idSucursal", "");
  const [admin, setAdmin] = useLocalStorage("admin", null);
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [filter, setFilter] = useState("");
  const [openInventario, setOpenInventario] = React.useState(false);

  const [pointsLinks, setPointsLinks] = useState(points.links);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickInventario = () => {
    setOpenInventario(!openInventario);
  };

  const guardarPermisos = useCallback(
    async (idSucursal, tipo) => {
      try {
        const response = await Services.get(
          urlObtenerPermisoSucursal + "?idSucursal=" + idSucursal
        );
        setPermiso(response.resultado);
        setPermisoStorage(response.resultado);
        if (tipo === "2") {
          history.push("/inicio");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [setPermiso, history]
  );

  const obtenerSucursal = useCallback(
    async (idSucursal, tipo) => {
      try {
        const response = await Services.get(
          urlObtenerSucursal + "?idSucursal=" + idSucursal + "&eliminado=0"
        );
        setSucursal(response.resultado[0]);
        setSucursalStorage(response.resultado[0]);
        guardarPermisos(idSucursal, tipo);
      } catch (error) {
        console.error(error);
      }
    },
    [setSucursal, setIdSucursal, guardarPermisos]
  );

  const obtenerPrimeraSucursal = useCallback(
    async (ad) => {
      try {
        const response = await Services.get(
          urlObtenerSucursales + "?eliminado=0"
        );
        setSucursales(response.resultado);
        if (!ad) setIdSucursal(response.resultado[0].idSucursal);
        obtenerSucursal(response.resultado[0].idSucursal, "1");
      } catch (error) {
        console.error(error);
      }
    },
    [sucursales]
  );

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setLoading(true);
      if (admin == null) {
        if (user && !user?.admin) {
          obtenerPrimeraSucursal(false);
          setAdmin(false);
        }
        if (user && user?.admin) {
          obtenerPrimeraSucursal(true);
          setAdmin(true);
        }
      } else {
        setSucursal(sucursalStorage);
        setPermiso(permisoStorage);
        setLoading(false);
      }
    }

    return () => (mounted = false);
  }, [user]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (filter !== "") {
        setPointsLinks(points.links.filter(p => p.title.toUpperCase().includes(filter.toUpperCase())));
      } else {
        setPointsLinks(points.links);
      }
    }

    return () => (mounted = false);
  }, [filter]);

  if (loading) return <FullScreenLoader color2="#2F2F2F" />;
  return (
    <Drawer className={navigatorJSS.whitoutScroll} variant="permanent" {...other}>
      <List disablePadding>
        <NavLink to="/inicio" style={{ textDecoration: "none" }}>
          <ListItem
            style={{ boxShadow: "none" }}
            sx={{ ...item, ...itemCategory }}
          >
            <ListItemText>Panel de Control</ListItemText>
          </ListItem>
        </NavLink>
        <Divider
          textAlign="left"
          sx={{
            width: "100%",
            height: "3px",
          }}
        />
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText style={{ color: "#FFF" }}>
              Módulos
              <TextField
                variant="standard"
                fullWidth
                label=""
                size="medium"
                typeInput="text"
                value={filter}
                style={{ color: "#FFF !important" }}
                onChange={(e) => setFilter(e.target.value)}
              />
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider
          textAlign="left"
          sx={{
            width: "40%",
            height: "1px",
            bgcolor: "#FFF",
          }}
        />
        <NavLink
          exact
          className={(isActive) =>
            !isActive ? navigatorJSS.actiNo : navigatorJSS.acti
          }
          to="/inicio"
          style={{ textDecoration: "none" }}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon style={{ color: "#FFF" }} />
              </ListItemIcon>
              <ListItemText style={{ color: "#FFF" }}>Inicio</ListItemText>
            </ListItemButton>
          </ListItem>
        </NavLink>

        {!admin && (
          <>
            <ListItemButton onClick={handleClick} style={{ color: "#FFF" }}>
              <ListItemIcon>
                <LocationOnIcon />
              </ListItemIcon>
              <ListItemText primary="Proyecto" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </>
        )}

        <Collapse in={open} timeout="auto" unmountOnExit>
          {!admin && (
            <>
              <ListItem style={{ backgroundColor: "#FFF" }}>
                <FormControl
                  style={{}}
                  variant="outlined"
                  size="small"
                  fullWidth
                >
                  <InputLabel>Proyectos</InputLabel>
                  <Select
                    value={idSucursal}
                    label="Proyectos"
                    style={{ textAlign: "left", fontSize: "14px" }}
                    onChange={(e) => {
                      setLoading(true);
                      setIdSucursal(e.target.value);
                      obtenerSucursal(e.target.value, "2");
                    }}
                  >
                    {sucursales != null
                      ? sucursales.map((opt, index) => (
                        <MenuItem key={index} value={opt.idSucursal}>
                          {opt.nombreSucursal}
                        </MenuItem>
                      ))
                      : []}
                  </Select>
                </FormControl>
              </ListItem>
            </>
          )}
        </Collapse>

        {pointsLinks
          .sort(({ title: a }, { title: b }) => a.localeCompare(b))
          .map((point, index) => {
            return point.type === "NavLink"
              ? (admin === true ||
                (permiso && permiso[0][point.permission] & 1) === 1) && (
                <NavLink
                  key={index}
                  exact
                  className={(isActive) =>
                    !isActive ? navigatorJSS.actiNo : navigatorJSS.acti
                  }
                  to={point.to}
                  style={{ textDecoration: "none" }}
                >
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>{point.icon}</ListItemIcon>
                      <ListItemText style={{ color: "#FFF" }}>
                        {point.title}
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </NavLink>
              ) : point.type === "Collapse" ? (admin === true || (permiso && permiso[0][point.permission] & 1) === 1) && (
                <div key={index}>
                  <ListItemButton
                    key={"A" + index}
                    onClick={handleClickInventario}
                    style={{ color: "#FFF" }}
                  >
                    <ListItemIcon>{point.icon}</ListItemIcon>
                    <ListItemText primary={point.title} />
                    {openInventario ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse key={"B" + index} in={openInventario} timeout="auto" unmountOnExit>
                    {point.items.map((item, index) => {
                      return (
                        (admin === true ||
                          (permiso && permiso[0][point.permission] & 1) ===
                          1) && (
                          <ListItem
                            key={"C" + index + index}
                            style={{ backgroundColor: "#FFF" }}
                            disablePadding
                          >
                            <NavLink

                              exact
                              className={(isActive) =>
                                !isActive
                                  ? navigatorJSS.actiNo
                                  : navigatorJSS.acti
                              }
                              to={item.to}
                              style={{
                                textDecoration: "none",
                                width: "100%",
                              }}
                            >
                              <ListItem disablePadding>
                                <ListItemButton>
                                  <ListItemIcon>{item.icon}</ListItemIcon>
                                  <ListItemText
                                    style={{ color: "#2D2D2D" }}
                                  >
                                    {item.title}
                                  </ListItemText>
                                </ListItemButton>
                              </ListItem>
                            </NavLink>
                          </ListItem>
                        )
                      );
                    })}
                  </Collapse>
                </div>
              ) : null;
          })}

        <ListItem disablePadding>
          <ListItemButton onClick={refreshCacheAndReload}>
            <ListItemIcon>
              <SyncIcon style={{ color: "#FFF" }} />
            </ListItemIcon>
            <ListItemText style={{ color: "#FFF" }}>Actualizar</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={signOut}>
            <ListItemIcon>
              <LogoutIcon style={{ color: "#FFF" }} />
            </ListItemIcon>
            <ListItemText style={{ color: "#FFF" }}>
              Cerrar Sesión
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
