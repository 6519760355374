
import { useState, useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { useAlert } from "./../hooks/useAlert";
import Service from "../services/api";

export function usePost() {
  const history = useHistory();
  const [errores, setErrores] = useState(null);
  const [cargando, setCargando] = useState(false);
  const [detalles, setDetalles] = useState(null);
  const { showAlert } = useAlert();

  const peticion = useCallback(
    async (url = "", parametros = {}, ruta = "", token = true, reload = false, method="POST") => {
      if (!cargando) setCargando(true);
      if (url) {
        if (token === false) {
          if(method === "DELETE"){
            await Service.deleteWithOutToken(url, parametros).then((response) => {
              if (response && response?.cuerpo?.detalle.estatus === 400) {
                setDetalles({ estatus: response?.cuerpo?.detalle.estatus, mesagge: response?.cuerpo?.mensaje, link: response?.cuerpo?.link, type: response?.cuerpo?.detalle.tipo });                
                if(response?.cuerpo?.errores){
                  setErrores(response?.cuerpo?.errores);
                }
                setCargando(false);
                showAlert({
                  message: response?.cuerpo?.mensaje,
                  severity: "error",
                });
              } else {
                setDetalles({ estatus: response?.cuerpo?.detalle.estatus, mesagge: response?.cuerpo?.mensaje, link: response?.cuerpo?.link, type: response?.cuerpo?.detalle.tipo });
                setErrores(null);
                setCargando(false);
                if (ruta) history.push(ruta);
                if (reload) window.location.reload();
                showAlert({
                  message: response?.cuerpo?.mensaje,
                  severity: "success",
                });
              }
            });
          }else{
            await Service.postWithOutToken(url, parametros).then((response) => {
              if (response && response?.cuerpo?.detalle.estatus === 400) {
                setDetalles({ estatus: response?.cuerpo?.detalle.estatus, mesagge: response?.cuerpo?.mensaje, link: response?.cuerpo?.link, type: response?.cuerpo?.detalle.tipo });               
                if(response?.cuerpo?.errores){
                  setErrores(response?.cuerpo?.errores);
                }
                setCargando(false);
                showAlert({
                  message: response?.cuerpo?.mensaje,
                  severity: "error",
                });
              } else {
                setDetalles({ estatus: response?.cuerpo?.detalle.estatus, mesagge: response?.cuerpo?.mensaje, link: response?.cuerpo?.link, type: response?.cuerpo?.detalle.tipo });
                setErrores(null);
                setCargando(false);
                if (ruta) history.push(ruta);
                if (reload) window.location.reload();
                showAlert({
                  message: response?.cuerpo?.mensaje,
                  severity: "success",
                });
              }
            });
          }
        
        } else {

          if(method === "DELETE"){
            await Service.delete(url, parametros).then((response) => {
              if (response && response?.detalle.estatus === 400) {
                if(response?.errores){
                  setErrores(response?.errores);
                }
                  setDetalles({estatus: response?.detalle.estatus, mesagge: response?.mensaje});
                  setCargando(false);
                  showAlert({
                    message: response?.mensaje,
                    severity: "error",
                  });
              }else{
                setDetalles({estatus: response?.detalle.estatus, mesagge: response?.mensaje});
                setErrores(null);
                setCargando(false);
                if (ruta) history.push(ruta);
                if (reload) window.location.reload();
                showAlert({
                  message: response?.mensaje,
                  severity: "success",
                });
              }
            });

          }else{
            await Service.post(url, parametros).then((response) => {
              if (response && response?.detalle.estatus === 400) {
                  if(response?.errores){
                    setErrores(response?.errores);
                  }
                  setDetalles({estatus: response?.detalle.estatus, mesagge: response?.mensaje});
                  setCargando(false);
                  if(response?.detalle?.exist === false){
                    history.push("/"+response?.detalle?.ruta);
                  }
                  showAlert({
                    message: response?.mensaje,
                    severity: "error",
                  });
                 
                 
              }else{
                setDetalles({estatus: response?.detalle.estatus, mesagge: response?.mensaje});
                setErrores(null);
                setCargando(false);
                if (ruta) history.push(ruta);
                if (reload) window.location.reload();
                showAlert({
                  message: response?.mensaje,
                  severity: "success",
                });
                
              }
            });
          }
        }
      }
    }, [history, cargando]);

  return useMemo(() => {
    return [
      errores,
      cargando,
      peticion,
      setCargando,
      detalles
    ]
  }, [errores, cargando, peticion, setCargando, detalles]);
}