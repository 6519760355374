import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import UrlBreadcrumb from "./UrlBreadcrumb";
import { makeStyles } from "@mui/styles";
import BackButton from "./BackButton";
import { spacing } from "@mui/system";
import Titulo from "./Titulo";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
    fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "32px",
      lineHeight: "48px",
      color: "#1E2024",
  },
}));

const PageHeader = ({ history, ...props }) => {
  const classes = useStyles();
  const {
    title = "Página sin título",
    links,
    preActionButton,
    actionButton,
  } = props;

  return (
    <>
      {props.dialog ? (
        <></>
      ) : (
        <>

              <Typography
                variant="h4"
                className={classes.title}
                style={{ marginLeft: "10px"}}
              >
                {title}
              </Typography>


            <Grid item xs={12}>
              <UrlBreadcrumb links={links} history={history} />
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container justify="flex-end">
                  <Grid item xs={12} style={{ margin: "12px 0" }}>
                    {preActionButton && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={(e) => {
                          if (preActionButton.to) {
                            history.push(preActionButton.to);
                          } else if (preActionButton.onClick) {
                            preActionButton.onClick(e);
                          }
                        }}
                        style={{
                          float: "left",
                          backgroundColor: "#2F2F2F",
                          color: "whitesmoke",
                        }}
                        size="small"
                        endIcon={preActionButton.icon}
                        disabled={preActionButton.disabled}
                      >
                        {preActionButton.label}
                      </Button>
                    )}
                    {actionButton && (
                      <Button
                        variant="contained"
                        onClick={(e) => {
                          if (actionButton.to) {
                            history.push(actionButton.to);
                          } else if (actionButton.onClick) {
                            actionButton.onClick(e);
                          }
                        }}
                        style={{
                          float: "right",
                          color: "whitesmoke",
                          padding: "7px 15px",
                        }}
                        size="small"
                      >
                        {actionButton.icon} {actionButton.label}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

        </>
      )}
    </>
  );
};

export default PageHeader;
