import { Button, Grid } from '@mui/material';
import React from "react";
import PageHeader from "./PageHeader";
import Loading from "../components/Loader";
import { useHistory } from "react-router";
import { makeStyles } from "@mui/styles";
import {
  Paper
} from '@mui/material'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
  paper: {
    padding: '10px !important',
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const TablePageLayout = ({
  SearchComponents,
  DisableComponents,
  TableComponent,
  Dialog,
  preActionButton,
  actionButton,
  bottomActionButton,
  customCss,
  customCssDisableButtons,
  ...props
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { title, links, tableLoading, tableErrors, children } = props;

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} style={customCss}>
            <Grid container style={{ padding: "10px", zIndex: 0 }} >
              <PageHeader
                history={history}
                title={title}
                links={links}
                preActionButton={preActionButton}
                actionButton={actionButton}
              />
              <Grid container spacing={3}>
                {SearchComponents}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {
          DisableComponents &&
          <Grid item xs={12}>
            <Paper className={classes.paper} style={{ textAlign: "start", padding: '20px 20px', display: 'flex', flexDirection: 'row', alignItems: 'center', ...customCss, ...customCssDisableButtons }}>
              {DisableComponents}
            </Paper>
          </Grid>
        }

        <Grid item xs={12} style={{ zIndex: 0 }}>
          {tableLoading ? (
            <Loading />
          ) : tableErrors ? (
            <div>Error al cargar la tabla</div>
          ) : (
            TableComponent
          )}
        </Grid>
      </Grid>

      {!tableLoading && (
        <Grid container spacing={3} justify="flex-end" >
          <Grid item xs={2}>
            {bottomActionButton && (
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => {
                  if (bottomActionButton.to) {
                    history.push(bottomActionButton.to);
                  } else if (bottomActionButton.onClick) {
                    bottomActionButton.onClick(e);
                  }
                }}
                style={{
                  float: "right",
                }}
                size="small"
                endIcon={bottomActionButton.icon}
                {...bottomActionButton.buttonProps}
              >
                {bottomActionButton.label}
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      {children}
      {Dialog}
    </div>
  );
};

export default TablePageLayout;
