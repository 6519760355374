/* eslint-disable no-mixed-operators */
//React
import React, { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../utils/variables";
import { baseStyles, capitalizeFirst } from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import {
  Grid,
  Menu,
  MenuItem,
  Switch,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { useModels } from "../../hooks/useModels";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
import Modal from 'react-modal';
import { useGet } from "../../hooks/useGet";
import ReactFileManager from "../../components/ReactFileManager";
//----------------------------------------------------------------------------------------------------------------------

//Librerias
import Swal from "sweetalert2";
//----------------------------------------------------------------------------------------------------------------------

//Iconos
import IconButton from "@mui/material/IconButton";
import {
  Add,
  Delete,
  Edit,
  Search,
} from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MdPictureAsPdf } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import InventoryIcon from '@mui/icons-material/Inventory';
import scanerGif from "../../assets/img/barcode-scan.gif";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';
import { useAlert } from "../../hooks/useAlert";
import { usePost } from "../../hooks/usePost";
//----------------------------------------------------------------------------------------------------------------------

const useStyles = baseStyles();

const modalStyle = {
  position: "absolute",
  top: "50%",
  right: "auto",
  left: "50%",
  bottom: "auto",
  transform: "translate(-50%, -50%)",
  marginRight: "-50%",
  background: "#FFF",
  outline: "none",
  padding: "15px 0 0 0",
  borderRadius: "5px",
  boxShadow: "0 0 15px black",
};

let urlGet = "producto-inventario";
let Barcode = require('react-barcode');

const Index = () => {
  //Estados Iniciales
  const classes = useStyles();
  const history = useHistory();
  const { showAlert } = useAlert();
  const [anchorEl, setAnchorEl] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [pagina, setPagina] = useState(1);
  const [openMenuRow, setOpenMenuRow] = useState(null);
  const [idSucursal, setIdSucursal] = useLocalStorage("idSucursal", "");
  const [admin] = useLocalStorage("admin", null);
  const { user, permiso } = useAuth();
  const [qr, setQr] = useState("");
  const [errores, cargandoEliminado, peticion, setCargando, detalles] = usePost();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalType, setModalType] = useState(null);
  const [imgProductoModal, setImgProductoModal] = useState("");
  const [campos, setCampos] = useState({ nombreProducto: "", serie: "", idSucursal: "", marca: "", modelo: "", eliminado: false });
  const [sucursalesPu] = useLocalStorage("sucursales", "");
  const [servSuc, setServSuc] = useState([]);


  let paramsGet = { limite: 10, pagina: 1, idSucursal: idSucursal, expand: 'lote,productoSucursal.sucursal,inventario' };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  //----------------------------------------------------------------------------------------------------------------------

  //Estados Iniciales Controller
  const header = [
    {
      prop: "nombreProducto",
      name: "Nombre del producto",
      sortable: false,
    },
    {
      prop: "modelo",
      name: "Modelo",
      sortable: false,
    },
    {
      prop: "marca",
      name: "Marca",
      sortable: false,
    },
  ];

  const [propiedades, setPropiedades] = useState({
    nombreProducto: "",
    modelo: "",
    marca: "",
    descripcion: "",
    precio: 0,
    cantidad: 0,
    serie: "",
    urlFoto: "",
    eliminado: 0,
  });

  const parametrosInicialesMemo = useMemo(
    () => ({
      name: "producto-inventario",
      ordenar: "creado.desc",
    }),
    []
  );
  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({ ...parametrosInicialesMemo });

  const [productos, cargando, actualizar] = useGet({
    initUrl: urlGet,
    params: paramsGet,
    action: true,
  });

  const [sucursales] = useGet({
    initUrl: 'sucursal',
    params: { pagina: 1, ordenar: "nombreSucursal.asc" },
    action: true,
  })
  //----------------------------------------------------------------------------------------------------------------------
  const isOdd = num => {
    return (num & 1) ? true : false
  }

  const buscarProductos = (eliminado = null, tipo = null, via = null, idSucursal = null) => {
    let obj = { ...paramsGet }
    if (campos.nombreProducto) obj = { ...obj, nombreProducto: campos.nombreProducto }
    if (campos.serie) obj = { ...obj, serie: campos.serie }
    if (campos.marca) obj = { ...obj, marca: campos.marca }
    if (campos.modelo) obj = { ...obj, modelo: campos.modelo }

    if (eliminado != null) {
      if (eliminado) obj = { ...obj, eliminado: eliminado }
    } else {
      if (campos.eliminado) obj = { ...obj, eliminado: campos.eliminado }
    }

    if (idSucursal != null) {
      if (idSucursal) obj = { ...obj, idSucursal: idSucursal }
    } else {
      if (campos.idSucursal) obj = { ...obj, idSucursal: campos.idSucursal }
    }

    actualizar({
      urlUpdate: urlGet,
      properties: obj,
      loading: true,
    });
  }

  const cambiarPagina = (event, newPage) => {
    let obj = { ...paramsGet }
    if (campos.nombreProducto) obj = { ...obj, nombreProducto: campos.nombreProducto }
    if (campos.serie) obj = { ...obj, serie: campos.serie }
    if (campos.marca) obj = { ...obj, marca: campos.marca }
    if (campos.modelo) obj = { ...obj, modelo: campos.modelo }
    if (campos.eliminado) obj = { ...obj, eliminado: campos.eliminado }
    if (campos.idSucursal) obj = { ...obj, idSucursal: campos.idSucursal }
    if (newPage) obj.pagina = newPage
    actualizar({
      urlUpdate: urlGet,
      properties: obj,
      loading: true,
    });
  };

  //Funciones

  const onSearchClicked = async (eliminado) => {
    let params = {};
    if (eliminado != null) {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
      };
    } else {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage },
      };
    }
    await refreshModels({ isCargando: true, params });
  };

  const changePage = async (page) => {
    setPagina(page + 1);
    let params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ pagina: page + 1, limite: perPage },
    };
    await refreshModels({ isCargando: true, params });
  };

  const changePageRow = async (per) => {
    setPerPage(per);
    const params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ limite: per, pagina: pagina },
    };
    await refreshModels({ isCargando: true, params });
  };

  const onDeleteClicked = async (row, type) => {
    try {
      setOpenMenuRow(false);
      await Swal.fire({
        title: type,
        text: `¿Desea ${type} el dato seleccionado  "${capitalizeFirst(
          row.nombreProducto.toLowerCase()
        )}"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34AA44",
        cancelButtonColor: "#E72C33",
        confirmButtonText: "Si, deseo Continuar.",
      }).then((result) => {
        if (result.isConfirmed) {
          let params = {};
          params = {
            ...parametrosInicialesMemo,
            ...propiedades,
            ...{ pagina: pagina, limite: perPage },
          };
          deleteModel(row.idProducto, type, params);
        }
      });
    } catch (error) {
      console.modelsError(error);
    }
  };

  const habilitarProducto = async idProducto => {
    if (idProducto) {
      const res = await Swal.fire({
        title: '¿Deseas habilitar este Producto?',
        text: "Favor de corroborar sus datos antes de continuar.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Habilitar!',
        cancelButtonText: 'Cancelar'
      });

      if (res.isConfirmed) {
        showAlert({
          message: "Espera un momento.....",
          severity: "warning",
        });
        peticion("producto-inventario/habilitar", { idProducto: idProducto }, "productos", true, false, "DELETE");

      }
    }
  }

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }
  const getFilterArraySucursales = array => {
    return array.map((e) => {
      return { idSucursal: e.idSucursal, nombreSucursal: e.nombreSucursal, active: false }
    })
  }

  const onCloseBarcode = () => {
    closeModal();
    buscarProductos();
  }
  //----------------------------------------------------------------------------------------------------------------------
  //Efectos
  useEffect(() => {
    if (detalles?.estatus === 200) {
      buscarProductos();
    }
  }, [detalles]);

  useEffect(() => {
    const newCampos = campos;
    newCampos.serie = qr;
    setCampos({ ...campos, ...newCampos });
  }, [qr]);

  useEffect(() => {
    if (sucursales.resultado) {
      const newObj = getFilterArraySucursales(sucursales.resultado);
      setServSuc(newObj);
    }
  }, [sucursales.resultado])
  //----------------------------------------------------------------------------------------------------------------------

  return (
    <>

      <div id="modal" style={{ zIndex: '99' }}>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          {(modalType == "img") && (
            <img style={{ maxHeight: '600px', minHeight: '600px', width: '100%', height: '100%' }} src={imgProductoModal} alt="imgProductoModal"></img>
          )}
          {(modalType == "barcode") && (
            <ReactFileManager typeFile={"barcode"} setBarcode={setQr} barcode={qr} onCloseBarcode={onCloseBarcode} />
          )}
          {(modalType == "qr") && (
            <>
              <input style={{ opacity: "0", position: "absolute", cursor: "default" }} autoFocus={true} onBlur={({ target }) => { target.focus() }}
                onChange={
                  (e) => {
                    setQr(e.target.value.replaceAll("'", "-"));
                  }
                }
                onKeyPress={(event) => {
                  if (event.which == 13 || event.keyCode == 13) {
                    closeModal();
                    event.target.value = ""
                    buscarProductos();
                    return false;
                  }
                  return true;
                }}
              />
              <img src={scanerGif} alt="Escanea codigo de barras" style={{ width: '300px', height: '300px' }} />
              <p style={{ zIndex: 999, textAlign: 'center', color: 'rgba(100,100,100,1)', fontSize: '20px' }}>{propiedades.serie.replaceAll("'", "-")}</p>
            </>
          )}
        </Modal>
      </div>

      <TablePageLayout
        title="Productos Inventario"
        model="producto-inventario"
        history={history}
        actionButton={{
          to: "/producto/nuevo",
          icon: <Add />,
          label: "Agregar Producto",
          permiso: "productos",
        }}
        links={[{ label: "Productos Inventario", icon: <InventoryIcon fontSize="small" /> }]}
        SearchComponents={
          <>
            <Grid
              item
              xs={12}
              sm={3}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <InputRegex
                label="Nombre del producto"
                fullWidth
                variant="outlined"
                typeInput="text"
                size="small"
                maxLength={50}
                value={propiedades.nombreProducto}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    nombreProducto: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <TextField
                label="Modelo"
                fullWidth
                variant="outlined"
                typeInput="text"
                size="small"
                maxLength={50}
                value={propiedades.modelo}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    modelo: e.target.value,
                  }))
                }
              />
            </Grid>



            {qr != "" ?
              <Grid item xs={12} sm={12} md={4} xl={4} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                <IconButton style={{ height: 'fit-content', margin: '5px' }} onClick={() => {
                  setModalType("qr")
                  openModal();
                }}>
                  <DocumentScannerIcon />
                </IconButton>
                <IconButton style={{ height: 'fit-content', margin: '5px' }} onClick={() => {
                  setModalType("barcode")
                  openModal();
                }}>
                  <CameraAltIcon />
                </IconButton>
                <Barcode height={30} width={1.2} fontSize={13} margin={0} marginTop={20} value={qr}
                  onClick={() => {
                    setModalType("qr")
                    openModal();
                  }}
                />
                <IconButton style={{ height: 'fit-content', margin: '5px' }}
                  onClick={
                    () => {
                      setQr("");
                      const newPropiedades = propiedades;
                      newPropiedades.serie = "";
                      setPropiedades({ ...propiedades, ...newPropiedades });
                      buscarProductos();
                    }
                  }
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              :
              <Grid item xs={12} sm={12} md={3} xl={3} style={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton style={{ height: 'fit-content', margin: '5px' }} onClick={() => {
                  setModalType("qr")
                  openModal();
                }}>
                  <DocumentScannerIcon />
                </IconButton>
                <IconButton style={{ height: 'fit-content', margin: '5px' }} onClick={() => {
                  setModalType("barcode")
                  openModal();
                }}>
                  <CameraAltIcon />
                </IconButton>
              </Grid>
            }

            <Grid item xs={12} sm={3}
              style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
              <LoadingButton
                variant="contained"
                onClick={() => onSearchClicked(null)}
                loading={modelsLoading && true}
                style={{
                  float: "right",
                  color: "whitesmoke",
                  justifyContent: "flex-end",
                }}
                startIcon={<Search />}
              >
                Buscar
              </LoadingButton>
            </Grid>

            <Grid
              item
              xs={12}
              sm={3}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <TextField
                label="Marca"
                fullWidth
                variant="outlined"
                typeInput="text"
                size="small"
                maxLength={50}
                value={propiedades.marca}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    marca: e.target.value,
                  }))
                }
              />
            </Grid>
          </>
        }
        DisableComponents={
          <>
            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={modelsLoading && true}
                    onChange={(e) => {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        eliminado: e.target.checked ? 1 : 0,
                      }));
                      onSearchClicked(e.target.checked ? 1 : 0);
                    }}
                  />
                }
                label="Ver Deshabilitados"
              />
            </Grid>

            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="error"
                  aria-label="upload picture"
                  component="span"
                >
                  <a
                    className={classes.btnPdf}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}pdf/proveedores?
                    ${"admin=" + (admin ? 1 : 0)}
                    ${"&uid=" + user?.idUsuario}
                    ${"&nombreProveedor=" + propiedades.nombreProveedor}
                    ${"&razonSocial=" + propiedades.razonSocial}
                    ${"&domicilioFiscal=" + propiedades.domicilioFiscal}
                    ${"&rfc=" + propiedades.rfc}
                    ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)
                      }`}
                  >
                    <MdPictureAsPdf />
                  </a>
                </IconButton>
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="warning"
                  aria-label="upload picture"
                  component="span"
                >
                  <a
                    className={classes.btnExcel}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}excel/proveedores?
                    ${"admin=" + (admin ? 1 : 0)}
                    ${"&uid=" + user?.idUsuario}
                    ${"&nombreProveedor=" + propiedades.nombreProveedor}
                    ${"&razonSocial=" + propiedades.razonSocial}
                    ${"&domicilioFiscal=" + propiedades.domicilioFiscal}
                    ${"&rfc=" + propiedades.rfc}
                    ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)
                      }`}
                  >
                    <RiFileExcel2Fill />
                  </a>
                </IconButton>
              </Grid>
            </Grid>
          </>
        }
        tableLoading={modelsLoading}
        tableErrors={modelsError}
        TableComponent={
          <TablePagination
            nombrePermiso="inventarios"
            permiso={permiso}
            header={header}
            data={models}
            paginatedApi
            pagina={pagina}
            paginationPageSize={perPage}
            onRowsPerPageChangeApi={changePageRow}
            changePageApi={changePage}
            count={modelsPage !== null ? modelsPage.total : 0}
            labelRowsPerPage={"Renglones por página"}
            extraRows={[
              {
                prop: "acciones",
                name: "Acciones",
                sortable: false,
                cell: (row, index) =>
                  ((permiso && (permiso[0]["inventarios"] & 4) === 4) ||
                    (permiso[0]["inventarios"] & 8) === 8) && (
                    <div>
                      <IconButton
                        onClick={(event) => {
                          setOpenMenuRow(index);
                          setAnchorEl(event.currentTarget);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={index === openMenuRow}
                        onClose={() => setOpenMenuRow(null)}
                      >
                        {row?.eliminado === null && (
                          <MenuItem
                            onClick={() =>
                              history.push(
                                `/producto/editar/${row.idProducto}`
                              )
                            }
                          >
                            <Edit style={{ paddingRight: 5 }} />
                            Editar
                          </MenuItem>
                        )}
                        {row?.eliminado === null ? (
                          <MenuItem
                            onClick={() => onDeleteClicked(row, "Desabilitar")}
                          >
                            <Delete style={{ paddingRight: 5 }} />
                            Desabilitar
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={() => onDeleteClicked(row, "Habilitar")}
                          >
                            <RestoreFromTrashIcon style={{ paddingRight: 5 }} />
                            Habilitar
                          </MenuItem>
                        )}
                      </Menu>
                    </div>
                  ),
              },
            ]}
          />
        }
      />
    </>
  );
};
export default Index;
