import React from "react";
import { makeStyles } from "@mui/styles";
import { TableCell,TableSortLabel } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      cursor: "pointer",
      color: "whitesmoke !important", 

    },
    userSelect: "none",
    fontSize: 15,
    backgroundColor: "#2F2F2F !important",
    color: "whitesmoke !important", 
    fontWeight: "600"
  },
  root2: {
    "& svg":{
        cursor: "pointer",
        color: "#E72C33 !important"
    },
    color: "whitesmoke !important", 
  },
}));

const HeaderCell = ({ name, sortable, sorted, direction, onClick }) => {
  const classes = useStyles();
  if (!name) {
    return <TableCell />;
  }
  if (!sortable) {
    return <TableCell className={classes.root}>{name}</TableCell>;
  }
  return (
    <TableCell className={classes.root}>
      <TableSortLabel className={classes.root2} active={sorted} direction={direction} onClick={onClick}>
        {name}
      </TableSortLabel>
    </TableCell>
  );
};

export default HeaderCell;
