import React from "react";
import { makeStyles } from "@mui/styles";
import { Edit as EditIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Cell from "./Cell";
import { TableRow } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      "& .name": {
        display: "none",
      },
    },
    "& > *.main .name": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      // rearranging stuff when mobile
      "& > *:not(.main) .name": {
        // label under the cell
        position: "absolute",
        bottom: "6px",
        color: "#6d6d6d",
        display: "none",
      },
    },
  },
  icon: {
    color: "#a1a1a1",
    cursor: "pointer",
  },
}));

// const useStyles = makeStyles(theme => ({
//   root: {
//     [theme.breakpoints.up('md')]: {
//       '& .name': {
//        display: 'none'
//       }
//     },
//     [theme.breakpoints.down('sm')]: { // rearranging stuff when mobile
//       display: "flex",
//       flexDirection: "row",
//       flexWrap: "wrap",         // cells can accomodate in several lines
//       borderBottom: "1px solid #cccccc",
//       '& > *': {
//         flexGrow: "1",        // cells take up all space
//         border: 'none'
//       },
//       '& > *.main': {       // cell that will work as an identifier
//         order: -1,          // it will be on top
//         width: "100%"
//       },
//       '& > *:not(.main)': {  // all the other cells
//         backgroundColor: "#d4d4d4",
//         display: "flex",
//         justifyContent: "center",
//         position: 'relative',
//         paddingBottom: "27px"
//       },
//       '& > *.main .name': {
//          display: 'none'
//       },
//       '& > *:not(.main) .name': {  // label under the cell
//         position: 'absolute',
//         bottom: '6px',
//         color: '#6d6d6d'
//       }
//     }
//   },
//   icon: {
//     color: '#a1a1a1',
//     cursor: 'pointer'
//   },
// }));

const Row = ({ headers, data, route, editable, mainField, index }) => {
  const classes = useStyles();
  return (
    <TableRow hover className={classes.root}>
      <Cell key={`trc-0`} >
        {index + 1}
      </Cell>

      {headers.map((field, index) => {
        const { prop, name } = field;
        const content = data[prop];
        const main = mainField === prop;
        return (
          <Cell key={`trc-${index}`} main={main} name={name}>
            {content}
          </Cell>
        );
      })}
      {editable ? (
        <Cell name="Editar">
          <Link to={data && route ? route(data) : null}>
            <EditIcon className={classes.icon} />
          </Link>
        </Cell>
      ) : null}
    </TableRow>
  );
};
export default Row;
