import GroupIcon from "@mui/icons-material/Group";
import { StoreMallDirectory } from "@mui/icons-material";
import { FaUserTag } from "react-icons/fa";
import { BiPurchaseTagAlt } from "react-icons/bi";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import InventoryIcon from '@mui/icons-material/Inventory';
import { FaBoxes } from "react-icons/fa";
import { ImBoxAdd } from "react-icons/im";


const points = {
  title: "Navigation Bar",
  icon: "",
  links: [
    {
      type: "NavLink",
      to: "/usuarios",
      icon: <GroupIcon style={{ color: "#FFF" }} />,
      title: "Usuarios",
      permission: "usuarios",
    },
    {
      type: "NavLink",
      to: "/sucursales",
      icon: <StoreMallDirectory style={{ color: "#FFF" }} />,
      title: "Proyectos",
      permission: "sucursales",
    },
    {
      type: "NavLink",
      to: "/ordenCompra",
      icon: <BiPurchaseTagAlt style={{ color: "#FFF" }} />,
      title: "Orden de pagos",
      permission: "ordenCompras",
    },
    {
      type: "NavLink",
      to: "/dieselMovimientos",
      icon: <ImportExportIcon style={{ color: "#FFF" }} />,
      title: "Diesel Movimientos",
      permission: "dieselMovimientos",
    },
    {
      type: "NavLink",
      to: "/reporteMaquinarias",
      icon: <ContentPasteIcon style={{ color: "#FFF" }} />,
      title: "Reporte Maquinaria",
      permission: "reporteMaquinarias",
    },
    {
      type: "NavLink",
      to: "/departamentos",
      icon: <HolidayVillageIcon style={{ color: "#FFF" }} />,
      title: "Departamentos",
      permission: "departamentos",
    },
    {
      type: "NavLink",
      to: "/proveedores",
      icon: <FaUserTag style={{ color: "#FFF" }} />,
      title: "Proveedores",
      permission: "proveedores",
    },
    {
      type: "NavLink",
      to: "/equipos",
      icon: <AgricultureIcon style={{ color: "#FFF" }} />,
      title: "Equipos",
      permission: "equipos",
    },
    // {
    //   id: "Inventario",
    //   type: "Collapse",
    //   items: [
    //     { to: "/productos", title: "Productos", id: "Inventario", icon: <FaBoxes style={{ color: "#2D2D2D" }} /> },
    //     { to: "/entradas", title: "Entradas/Productos", id: "Inventario", icon: <ImBoxAdd style={{ color: "#2D2D2D" }} /> }
    //   ],
    //   icon: <InventoryIcon />,
    //   title: "Inventario",
    //   permission: "inventarios",
    // }
  ],
};

export { points };
