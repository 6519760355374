import React from "react";

import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import deLocale from "date-fns/locale/es";
import {
  TextField,
  Stack,
} from '@mui/material';

const DatePicker = ({
  labelText,
  error,
  helperText,
  disabled,
  typePicker,
  minDate,
  maxDate,
  disableFuture = false,
  size,
  color,
  onChange,
  value,
  inputFormat,
  variant,
  ...rest
}) => {

  if (typePicker === "mobile") {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={3}>
          <MobileDatePicker
            label={labelText}
            inputFormat={inputFormat}
            value={value}
            onChange={onChange}
            renderInput={(params) => <TextField {...params} variant={variant} style={{background:'#FFF', marginBottom:'20px'}} />}
          />
        </Stack>
      </LocalizationProvider>
    );
  } else {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} >
        <Stack spacing={3}>
          <DesktopDatePicker
            disabled={disabled}
            disableFuture={disableFuture}
            helperText={helperText}
            inputFormat={inputFormat}
            label={labelText}
            minDate={minDate}
            onChange={onChange}
            onError={error}
            value={value}
            renderInput={(params) => <TextField color={color} size={size} variant={variant} {...params} />}
            {...rest}
          />
        </Stack>
      </LocalizationProvider>
    );
  }
};

export default DatePicker;
