import React from "react";
import { Grid, Typography } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import FullScreenLoader from "../../components/FullScreenLoader";

import BackButton from "../../components/BackButton";
import Titulo from "../../components/Titulo";

const Home = () => {
  const { loginLoading } = useAuth();

  if (loginLoading) return <FullScreenLoader />;

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        style={{ padding: 20 }}
        spacing={3}
      >
        <Grid item xs={12}>
          <Typography
            component="h6"
            variant="h5"
            color="textPrimary"
            style={{ textAlign: "center" }}
          >
            INICIO
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default Home;
