import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import SignIn from "../views/signIn";
import {Navigation} from "../components/navigation";

const PublicRoutes = () => {
  return (
      <Router>
          <Navigation />
          <Switch>
            <Route exact path="/acceso" component={SignIn} />
            <Route path="*" component={() => <Redirect to="/acceso" />} />
          </Switch>
      </Router>
  );
};

export default PublicRoutes;