
import { useState, useEffect, useCallback, useMemo } from "react";
import Service from "../services/api";

const paramsToQuery = (params) =>
    Object.keys(params).map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    ).join("&");
    
export function useGet({ initUrl = "", params = null, action = false, withOutToken = false }) {
  const [resultado,setResultado] = useState([]);
  const [cargando, setCargando] = useState(true);

  const init = async() => {
    const getData = async () => {
      try {
        let response = null;
        initUrl += Object.keys(params).length > 0 ? `?${paramsToQuery(params)}` : "";
        if(withOutToken){
          response = await Service.getWithOutToken(initUrl);
        }else{
          response = await Service.get(initUrl);
        }
        setResultado(response);
      } catch (error) {
        console.error(error);
      } finally {
        setCargando(false);
      }
    };
    getData();
  }

  useEffect(() => {
    if(initUrl && action) init();
  }, [initUrl,action])
  
  const actualizar = useCallback(
    async ({ urlUpdate = "", properties = null, loading = false, withOutToken = false  }) => {
      if(urlUpdate){
        if(loading) setCargando(true);
          try {
            urlUpdate += Object.keys(properties).length > 0 ? `?${paramsToQuery(properties)}` : "";
            let response = null;
            if(withOutToken){
              response = await Service.getWithOutToken(urlUpdate);
            }else{
              response = await Service.get(urlUpdate);
            }

            setResultado(response);
          } catch (error) {
             console.error(error);
          } finally {
             setCargando(false);
          }
      }
    }, []
  );

  return useMemo(() => {
    return [
      resultado,
      cargando,
      actualizar
    ]
  },[resultado, cargando, actualizar]);
}