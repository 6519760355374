import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Box, useMediaQuery, ThemeProvider, CssBaseline } from "@mui/material";
import PrivateRoute from "../components/PrivateRoute";
import Navigator from "../components/Navigator";
import Header from "../components/Header";
import { useApp } from "../hooks/useApp";

import Home from "../views/home";

import Usuario from "../views/usuario/";
import UsuarioDetail from "../views/usuario/detail";
import UsuarioPermisos from "../views/usuario/permisos";

import Sucursal from "../views/sucursal";
import SucursalDetail from "../views/sucursal/detail";
import SucursalHorario from "../views/sucursal/horarios";

import DieselMovimientos from "../views/dieselMovimientos";
import DieselMovimientosDetail from "../views/dieselMovimientos/detail";

import OrdenCompra from "../views/ordenCompra/";
import OrdenCompraDetail from "../views/ordenCompra/detail";

import ReporteMaquinaria from "../views/reporteMaquinaria";
import ReporteMaquinariaDetail from "../views/reporteMaquinaria/detail";

import Departamento from "../views/departamentos";
import DepartamentoDetail from "../views/departamentos/detail";

import Proveedores from "../views/proveedores";
import ProveedoresDetail from "../views/proveedores/detail";

import Equipo from "../views/equipo";
import EquipoDetail from "../views/equipo/detail";

import Bancos from "../views/bancos";
import BancosDetail from "../views/bancos/detail";

import Inventario from "../views/inventario";
import InventarioDetail from "../views/inventario/detail";




const drawerWidth = 256;

const PrivateRoutes = () => {
  const { MuiTheme } = useApp();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(MuiTheme.breakpoints.up("sm"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <Router>
      <>
        <ThemeProvider theme={MuiTheme}>
          <Box sx={{ display: "flex", minHeight: "100vh" }}>
            <CssBaseline />
            <Box
              component="nav"
              sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            >
              {isSmUp ? null : (
                <Navigator
                  PaperProps={{ style: { width: drawerWidth } }}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                />
              )}
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                sx={{ display: { sm: "block", xs: "none" } }}
              />
            </Box>
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Header onDrawerToggle={handleDrawerToggle} />
              <Box
                component="main"
                sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}
              >
                <Switch>
                  {/*USUARIOS*/}
                  <PrivateRoute
                    exact
                    path="/usuarios"
                    permisoCheck="usuarios"
                    component={Usuario}
                  />

                  <PrivateRoute
                    exact
                    path="/usuario/nuevo"
                    permisoCheck="usuarios"
                    component={UsuarioDetail}
                  />

                  <PrivateRoute
                    exact
                    path="/usuario/permisos/:idUsuario"
                    permisoCheck="usuarios"
                    component={UsuarioPermisos}
                  />

                  <PrivateRoute
                    exact
                    path="/usuario/editar/:idUsuario"
                    permisoCheck="usuarios"
                    component={UsuarioDetail}
                  />

                  {/*SUCURSALES*/}
                  <PrivateRoute
                    exact
                    path="/sucursales"
                    permisoCheck="sucursales"
                    component={Sucursal}
                  />
                  <PrivateRoute
                    exact
                    path="/sucursal/nuevo"
                    permisoCheck="sucursales"
                    component={SucursalDetail}
                  />

                  <PrivateRoute
                    exact
                    path="/sucursal/editar/:id"
                    permisoCheck="sucursales"
                    component={SucursalDetail}
                  />

                  <PrivateRoute
                    exact
                    path="/sucursal/horario/:id"
                    permisoCheck="sucursales"
                    component={SucursalHorario}
                  />

                  {/*DIESEL MOVIMIENTOS*/}
                  <PrivateRoute
                    exact
                    path="/dieselMovimientos"
                    permisoCheck="dieselMovimientos"
                    component={DieselMovimientos}
                  />

                  <PrivateRoute
                    exact
                    path="/dieselMovimientos/nuevo"
                    permisoCheck="dieselMovimientos"
                    component={DieselMovimientosDetail}
                  />

                  <PrivateRoute
                    exact
                    path="dieselMovimientos/editar/:id"
                    permisoCheck="dieselMovimientos"
                    component={DieselMovimientosDetail}
                  />

                  {/*Orden de compra*/}
                  <PrivateRoute
                    exact
                    path="/ordenCompra"
                    permisoCheck="ordenCompras"
                    component={OrdenCompra}
                  />
                  <PrivateRoute
                    exact
                    path="/ordenCompra/nuevo"
                    permisoCheck="ordenCompras"
                    component={OrdenCompraDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/ordenCompra/editar/:id"
                    permisoCheck="ordenCompras"
                    component={OrdenCompraDetail}
                  />

                  {/*Reporte Maquinaria*/}
                  <PrivateRoute
                    exact
                    path="/reporteMaquinarias"
                    permisoCheck="reporteMaquinarias"
                    component={ReporteMaquinaria}
                  />
                  <PrivateRoute
                    exact
                    path="/reporteMaquinarias/nuevo"
                    permisoCheck="reporteMaquinarias"
                    component={ReporteMaquinariaDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/reporteMaquinarias/editar/:id"
                    permisoCheck="reporteMaquinarias"
                    component={ReporteMaquinariaDetail}
                  />

                  {/*Departamentos*/}
                  <PrivateRoute
                    exact
                    path="/departamentos"
                    permisoCheck="departamentos"
                    component={Departamento}
                  />
                  <PrivateRoute
                    exact
                    path="/departamentos/nuevo"
                    permisoCheck="departamentos"
                    component={DepartamentoDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/departamentos/editar/:id"
                    permisoCheck="departamentos"
                    component={DepartamentoDetail}
                  />

                  {/*Proveedores*/}
                  <PrivateRoute
                    exact
                    path="/proveedores"
                    permisoCheck="proveedores"
                    component={Proveedores}
                  />
                  <PrivateRoute
                    exact
                    path="/proveedores/nuevo"
                    permisoCheck="proveedores"
                    component={ProveedoresDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/proveedores/editar/:id"
                    permisoCheck="proveedores"
                    component={ProveedoresDetail}
                  />

                  {/*Equipo*/}
                  <PrivateRoute
                    exact
                    path="/equipos"
                    permisoCheck="equipos"
                    component={Equipo}
                  />
                  <PrivateRoute
                    exact
                    path="/equipos/nuevo"
                    permisoCheck="equipos"
                    component={EquipoDetail}
                  />
                  <PrivateRoute
                    exact
                    path="/equipos/editar/:id"
                    permisoCheck="equipos"
                    component={EquipoDetail}
                  />

                  {/*Bancos*/}
                  <PrivateRoute
                    exact
                    path="/bancos"
                    permisoCheck="bancos"
                    component={Bancos}
                  />

                  <PrivateRoute
                    exact
                    path="/bancos/nuevo"
                    permisoCheck="bancos"
                    component={BancosDetail}
                  />

                  <PrivateRoute
                    exact
                    path="/bancos/editar/:id"
                    permisoCheck="bancos"
                    component={BancosDetail}
                  />

                   {/*INVENTARIO*/}
                   <PrivateRoute
                    exact
                    path="/productos"
                    permisoCheck="inventarios"
                    component={Inventario}
                  />

                  <PrivateRoute
                    exact
                    path="/producto/nuevo"
                    permisoCheck="inventarios"
                    component={InventarioDetail}
                  />

                  <PrivateRoute
                    exact
                    path="/producto/editar/:idProducto"
                    permisoCheck="inventarios"
                    component={InventarioDetail}
                  />


                  <Route exact path="/inicio" component={Home} />
                  <Route path="*" component={() => <Redirect to="/inicio" />} />
                </Switch>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </>
    </Router>
  );
};

export default PrivateRoutes;
