import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  capitalizeFirst,
  permisosStyle,
  tableCellStyles,
  tableRowStyles,
  internationalCurrency,
} from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import { useModel } from "../../hooks/useModel";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAlert } from "../../hooks/useAlert";
import Loading from "../../components/FullScreenLoader";
import PageHeader from "../../components/PageHeader";
import DatePicker from "../../components/DatePickerBeta";
import InputRegex from "../../components/InputRegex";
import FirebaseService from "../../services/firebase";
import FirmaComponentBeta from "../../components/FirmaComponentBeta";
import { useModels } from "../../hooks/useModels";
import {
  Grid,
  Divider,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Swal from "sweetalert2";
import moment from "moment-timezone";
import { Add, Delete, Edit, Save } from "@mui/icons-material";
import AsyncAutocompleteSearchRework from "../../components/AsyncAutocompleteSearchRework";
import { BiPurchaseTagAlt } from "react-icons/bi";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
const TableCellModify = tableCellStyles()(TableCell);
const TableRowModify = tableRowStyles()(TableRow);
const pStyles = permisosStyle();

const Index = ({ match, history, dialog }) => {
  const classes = useStyles();
  const ps = pStyles();
  const { showAlert } = useAlert();
  const id = match.params.id;
  const editing = !!id;
  const [admin] = useLocalStorage("admin", null);
  const [idSucursal] = useLocalStorage("idSucursal", "");
  const { user, permiso } = useAuth();

  const parametrosInicialesMemo = useMemo(
    () => ({
      name: "orden-compra",
      ordenar: "idOrdenCompra.desc",
      extraParams: { idCreador: user?.idUsuario },
    }),
    [user?.idUsuario]
  );
  const [models] = useModels({ ...parametrosInicialesMemo });

  const [proveedor, setProveedor] = useState(null);
  const [departamento, setDepartamento] = useState(null);
  const [firma, setFirma] = useState(null);
  const [firma2, setFirma2] = useState(null);
  const [totales, setTotales] = useState({
    cantidadTotal: 0,
    costoTotal: 0,
    subtotal: 0,
    iva: 0,
    total: 0,
  });
  const [producto, setProducto] = useState({
    cantidad: "",
    descripcionArticulo: "",
    costoUnitario: "",
    unidadMedida: "Pieza",
  });
  const [desglose, setDesglose] = useState([]);
  const [propiedades, setPropiedades] = useState({
    idProveedor: "",
    rfc: "",
    idDepartamento: "",
    proyecto: "",
    fecha: moment(new Date()).add(1, "d").format("YYYY-MM-DD"),
    noOrden: "",
    fechaConclusion: "",
    status: "Proceso",
    subtotal: "",
    iva: "",
    total: 0,
    firmaElabora: "",
    firmaAutoriza: "",
    nombreElabora: "",
    nombreAutoriza: "",
    puestoElabora: "",
    puestoAutoriza: "",
    justificacionCompra: "",
    fechaAutorizacion: "",
  });

  const proveedorParamsMemo = useMemo(
    () =>
      editing
        ? {
            pagina: 1,
            limite: 10,
            ordenar: "nombreProveedor.desc",
            idProveedor: proveedor,
          }
        : { pagina: 1, limite: 10, ordenar: "nombreProveedor.desc" },
    [editing, proveedor]
  );

  const departamentoParamsMemo = useMemo(
    () =>
      editing
        ? {
            pagina: 1,
            limite: 10,
            ordenar: "nombreDepartamento.desc",
            idDepartamento: departamento,
          }
        : { pagina: 1, limite: 10, ordenar: "nombreDepartamento.desc" },
    [departamento, editing]
  );

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "orden-compra",
      id,
      redirectOnPost: true,
      expand: "desglose",
    });

  const handleRemove = (position, row) => {
    let subtotal = totales.costoTotal - parseInt(row.costoUnitario) * parseInt(row.cantidad);
    setTotales({
      cantidadTotal: totales.cantidadTotal - parseInt(row.cantidad),
      costoTotal: totales.costoTotal - parseInt(row.costoUnitario) * parseInt(row.cantidad),
      subtotal: (subtotal - subtotal * 0.16).toFixed(2),
      iva: (subtotal * 0.16).toFixed(2),
      total: subtotal,
    });
    const copyRows = [...desglose];
    copyRows.splice(position, 1);
    setDesglose(copyRows);
  };

  const addProduct = () => {
    if (
      producto.cantidad !== "" &&
      producto.descripcionArticulo !== "" &&
      producto.costoUnitario !== "" &&
      producto.unidadMedida !== ""
    ) {
      setDesglose([
        ...desglose,
        {
          cantidad: producto.cantidad,
          descripcionArticulo: producto.descripcionArticulo,
          costoUnitario: producto.costoUnitario,
          unidadMedida: producto.unidadMedida,
          total: (producto.cantidad * producto.costoUnitario).toString(),
        },
      ]);
      let subtotal = totales.costoTotal + parseInt(producto.costoUnitario) * parseInt(producto.cantidad);
      setTotales({
        cantidadTotal: totales.cantidadTotal + parseInt(producto.cantidad),
        costoTotal: totales.costoTotal + parseInt(producto.costoUnitario) * parseInt(producto.cantidad),
        subtotal: (subtotal - subtotal * 0.16).toFixed(2),
        iva: (subtotal * 0.16).toFixed(2),
        total: subtotal,
      });
      setProducto({
        cantidad: "",
        descripcionArticulo: "",
        costoUnitario: "",
        unidadMedida: "",
      });
    } else {
      showAlert({
        message: "Todos los campos son obligatorios",
        severity: "warning",
      });
    }
  };

  const guardar = async (body) => {
    let title = "";
    editing
      ? (title = "¿Deseas Editar esta orden de compra?")
      : (title = "¿Deseas Guardar esta orden de compra?");
    const res = await Swal.fire({
      title: title,
      text: "Favor de corroborar sus datos antes de continuar.",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Continuar!",
      cancelButtonText: "Cancelar",
    });
    if (res.isConfirmed) {
      showAlert({
        message: "Guardando orden de compra",
        severity: "warning",
      });
      updateModel(body, true, "ordenCompra");
    }
  };

  const getFirma = async (tipo) => {
    if (tipo == "elabora") {
      if (firma) {
        let firmaElaborador = "";
        let ranm = Math.floor(Math.random() * 1000);
        const pathFirebase1 = "OrdenCompra/Firma_ELABORA_" + ranm;
        let resp = await fetch(firma).then((res) => res.blob());
        firmaElaborador = await FirebaseService.uploadFile(pathFirebase1, resp);
        return firmaElaborador;
      }
    } else {
      if (firma2) {
        let firmaAutorizacion = "";
        let ranm = Math.floor(Math.random() * 1000);
        const pathFirebase2 = "OrdenCompra/Firma_AUTORIZA_" + ranm; //Creo que esto es en el otro módulo
        let resp = await fetch(firma2).then((res) => res.blob());
        firmaAutorizacion = await FirebaseService.uploadFile(
          pathFirebase2,
          resp
        );
        return firmaAutorizacion;
      }
    }
  };

  const onGuardar = async () => {
    if (editing) {
      let firma = await getFirma("autoriza");
      await guardar({
        idOrdenCompra: id,
        status: propiedades.status,
        nombreAutoriza: user?.nombreCompleto,
        firmaAutoriza: firma,
        puestoAutoriza: propiedades.puestoAutoriza,
      });
    } else {
      let firma = await getFirma("elabora");
      if (desglose.length > 0) {
        await guardar({
          ...propiedades,
          nombreElabora: user.nombreCompleto,
          firmaElabora: firma,
          noOrden: `${user.nombreCompleto
            .split(" ")
            .map((n) => n.slice(0, 1))
            .join("")
            .toUpperCase()}-${models.length + 1}`,
          ...totales,
          idSucursal: idSucursal,
          desgloseOrdenCompra: desglose,
        });
      } else {
        showAlert({
          message: "Debe agregar por lo menos 1 artículo.",
          severity: "warning",
        });
      }
    }
  };

  console.log(totales)
  //----------------------------------------------------------------------------------------------------------------------
  //Efectos Callbacks
  useEffect(() => {
    let folio = models?.length + 1;
    let nombre = user?.nombreCompleto.split(" ");
    let abreviatura = nombre.map((n) => n.slice(0, 1));
    setPropiedades((prop) => ({
      ...prop,
      noOrden: abreviatura.join("").toUpperCase() + "-" + folio,
    }));
  }, [models, user?.nombreCompleto]);

  useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setPropiedades(model);
      setFirma(model?.firmaElabora);
      if (model?.firmaAutoriza) {
        setFirma2(model?.firmaAutoriza);
      }
      setProveedor(model?.idProveedor);
      setDepartamento(model?.idDepartamento);
      setDesglose(model?.desglose);
    }
    return () => {
      mounted = false;
      setPropiedades({
        proyecto: "",
        fecha: "",
        noOrden: "",
        rfc: "",
        fechaConclusion: "",
        status: "",
        subtotal: "",
        iva: "",
        total: 0,
        firmaElabora: "",
        firmaAutoriza: "",
        nombreElabora: "",
        nombreAutoriza: "",
        puestoElabora: "",
        puestoAutoriza: "",
        justificacionCompra: "",
        fechaAutorizacion: "",
      });
    };
  }, [model]);

  useEffect(() => {
    if (desglose) {
      console.log(desglose)
      let ct = desglose.reduce((acc, cur) => acc + Number(cur.costoUnitario) * Number(cur.cantidad), 0);
      setTotales({
        cantidadTotal: desglose.reduce((acc, cur) => acc + Number(cur.cantidad), 0),
        subtotal: (ct - ct * 0.16).toFixed(2),
        iva: (ct * 0.16).toFixed(2),
        total: ct,
      });
    }
  }, [desglose]);

  const setProveedorCallback = useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setProveedor(v);
        setPropiedades((props) => ({
          ...props,
          idProveedor: v.idProveedor,
          rfc: v.rfc,
        }));
      } else {
        setProveedor(null);
      }
    },
    [setProveedor]
  );

  const setDepartamentoCallback = useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setDepartamento(v);
        setPropiedades((props) => ({
          ...props,
          idDepartamento: v.idDepartamento,
        }));
      } else {
        setDepartamento(null);
      }
    },
    [setDepartamento]
  );

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title={(!editing ? "Nueva" : "Editar") + " Bancos"}
        links={[
          {
            to: "/ordenCompra",
            icon: <BiPurchaseTagAlt fontSize="medium" />,
            label: "Orden de Compra",
          },
          {
            to: !editing ? "/ordenCompra/nuevo" : "/ordenCompra/editar/" + id,
            icon: !editing ? (
              <Add fontSize="medium" />
            ) : (
              <Edit fontSize="medium" />
            ),
            label: !editing
              ? "Nuevo"
              : "Editar - " +
                capitalizeFirst(propiedades?.noOrden?.toLocaleUpperCase()),
          },
        ]}
        editing={editing}
        dialog={dialog}
      />
      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div>
            <strong>{"Datos de la Orden de compra"}</strong>
          </div>
          <Divider />
        </Grid>

        {!editing && (
          <>
            <Grid item xs={12} md={4}>
              <AsyncAutocompleteSearchRework
                label="Nombre del proveedor"
                name="proveedores"
                labelProp="nombreProveedor"
                extraParams={proveedorParamsMemo}
                onChange={setProveedorCallback}
                campoError="idProveedor"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="R. F. C."
                fullWidth
                value={proveedor?.rfc}
                helperText={modelError?.rfc}
                error={Boolean(modelError?.rfc)}
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AsyncAutocompleteSearchRework
                label="Nombre del departamento"
                name="departamentos"
                labelProp="nombreDepartamento"
                extraParams={departamentoParamsMemo}
                onChange={setDepartamentoCallback}
                campoError="idDepartamento"
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={4}>
          <TextField
            label="Proyecto"
            fullWidth
            value={propiedades?.proyecto}
            helperText={modelError?.proyecto}
            error={Boolean(modelError?.proyecto)}
            disabled={editing ? true : false}
            size="small"
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                proyecto: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Fecha"
            fullWidth
            value={propiedades?.fecha}
            helperText={modelError?.fecha}
            error={Boolean(modelError?.fecha)}
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Numero de orden"
            fullWidth
            value={propiedades?.noOrden}
            helperText={modelError?.noOrden}
            error={Boolean(modelError?.noOrden)}
            size="small"
            disabled
          />
        </Grid>

        {/* <Grid item xs={12} md={4}>
          <DatePicker
            labelText="Fecha Conclusion"
            fullWidth
            inputFormat="dd/MM/yyyy"
            value={propiedades.fechaConclusion}
            helperText={modelError?.fechaConclusion}
            error={Boolean(modelError?.fechaConclusion)}
            size="small"
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                fechaConclusion: moment(e).format("yyyy/MM/DD"),
              }))
            }
          />
        </Grid> */}

        <Grid item xs={12} md={4}>
          <FormControl variant="standard" fullWidth size="large">
            <InputLabel variant="standard" id="demo-select-small">
              Status
            </InputLabel>
            <Select
              variant="standard"
              size="large"
              id="demo-select-small"
              label="Status"
              value={propiedades.status}
              disabled
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  status: e.target.value,
                }))
              }
            >
              <MenuItem value="Proceso">Proceso</MenuItem>
              <MenuItem value="Aceptado">Aceptado</MenuItem>
              <MenuItem value="Rechazado">Rechazado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Nombre de quien elabora"
            fullWidth
            value={user.nombreCompleto}
            size="small"
            disabled
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            label="Puesto de quien elabora"
            fullWidth
            value={propiedades?.puestoElabora}
            helperText={modelError?.puestoElabora}
            error={Boolean(modelError?.puestoElabora)}
            size="small"
            disabled={editing ? true : false}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                puestoElabora: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div>
            <strong>{"Desglose de la Orden de compra"}</strong>
          </div>
          <Divider />
        </Grid>

        <Grid item xs={12} md={1}>
          <TextField
            label="Cantidad"
            fullWidth
            value={producto?.cantidad}
            helperText={modelError?.cantidad}
            error={Boolean(modelError?.cantidad)}
            size="medium"
            typeInput="number"
            disabled={editing ? true : false}
            onChange={(e) =>
              setProducto((prevState) => ({
                ...prevState,
                cantidad: e.target.value,
              }))
            }
          />
        </Grid>

        <Grid item xs={12} md={7}>
          <TextField
            label="Descripcion del Articulo"
            fullWidth
            value={producto?.descripcionArticulo}
            helperText={modelError?.descripcionArticulo}
            error={Boolean(modelError?.descripcionArticulo)}
            size="medium"
            disabled={editing ? true : false}
            onChange={(e) =>
              setProducto((prevState) => ({
                ...prevState,
                descripcionArticulo: e.target.value,
              }))
            }
          />
        </Grid>

        <Grid item xs={12} md={1}>
          <FormControl variant="standard" fullWidth size="medium">
            <InputLabel variant="standard" id="demo-select-small">
              Unidad de medida
            </InputLabel>
            <Select
              variant="standard"
              size="medium"
              id="demo-select-small"
              label="Status"
              value={producto?.unidadMedida}
              disabled={editing ? true : false}
              onChange={(e) =>
                setProducto((prevState) => ({
                  ...prevState,
                  unidadMedida: e.target.value,
                }))
              }
            >
              <MenuItem value="Metro">Metro</MenuItem>
              <MenuItem value="Pieza">Pieza</MenuItem>
              <MenuItem value="Kilogramo">Kilogramo</MenuItem>
              <MenuItem value="Caja">Caja</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={2}>
          <TextField
            label="Costo Unitario"
            fullWidth
            typeInput="currency"
            value={producto?.costoUnitario}
            helperText={modelError?.costoUnitario}
            error={Boolean(modelError?.costoUnitario)}
            size="medium"
            disabled={editing ? true : false}
            onChange={(e) =>
              setProducto((prevState) => ({
                ...prevState,
                costoUnitario: e.target.value,
              }))
            }
          />
        </Grid>

        <Grid item xs={12} md={1} marginTop={"12px"}>
          <LoadingButton
            disabled={editing ? true : false}
            variant="contained"
            onClick={() => addProduct()}
            size="small"
            style={{
              color: "#FFF",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "8px 17px",
              gap: "8px",
            }}
          >
            <Add style={{ fontSize: "x-large" }} />
          </LoadingButton>
        </Grid>

        {desglose.length > 0 && (
          <Grid
            container
            className={classes.paper}
            spacing={2}
            style={{ marginTop: 10 }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{
                borderRadius: "5px",
                boxShadow: "0 0 10px #00000069",
                marginBottom: "30px",
              }}
            >
              <TableHead sx={{ zIndex: "1", position: "relative" }}>
                <TableRowModify>
                  <TableCellModify
                    className={ps.tableCellHead}
                    style={{ borderTopLeftRadius: "5px" }}
                  >
                    Cantidad
                  </TableCellModify>
                  <TableCellModify className={ps.tableCellHead}>
                    Descripcion del articulo
                  </TableCellModify>
                  <TableCellModify className={ps.tableCellHead}>
                    Unidad de medida
                  </TableCellModify>
                  <TableCellModify className={ps.tableCellHead}>
                    Costo unitario
                  </TableCellModify>
                  <TableCellModify className={ps.tableCellHead}>
                    Total
                  </TableCellModify>
                  {!editing && (
                    <TableCellModify
                      className={ps.tableCellHead}
                      style={{ borderTopRightRadius: "5px" }}
                    >
                      Acción
                    </TableCellModify>
                  )}
                </TableRowModify>
              </TableHead>
              <TableBody>
                {desglose.map((prod, index) => (
                  <TableRowModify
                    key={index}
                    className={ps.tableRowBody}
                    style={{
                      borderBottomRightRadius: "5px",
                      borderBottomLeftRadius: "5px",
                    }}
                  >
                    <TableCellModify className="cellBody">
                      <TextField
                        label="Cantidad"
                        fullWidth
                        value={desglose[index].cantidad}
                        size="medium"
                        typeInput="currency"
                        disabled={editing ? true : false}
                        onChange={(e) =>
                          setDesglose((prevState) =>
                            prevState.map((v, i) =>
                              i === index
                                ? {
                                    ...v,
                                    cantidad: e.target.value,
                                    total:
                                      desglose[index]?.costoUnitario *
                                      desglose[index]?.cantidad,
                                  }
                                : v
                            )
                          )
                        }
                      />
                    </TableCellModify>
                    <TableCellModify className="cellBody">
                      <TextField
                        label="Descripcion del Articulo"
                        fullWidth
                        disabled={editing ? true : false}
                        value={desglose[index]?.descripcionArticulo}
                        size="medium"
                        onChange={(e) =>
                          setDesglose((prevState) =>
                            prevState.map((v, i) =>
                              i === index
                                ? {
                                    ...v,
                                    descripcionArticulo: e.target.value,
                                  }
                                : v
                            )
                          )
                        }
                      />
                    </TableCellModify>
                    <TableCellModify className="cellBody">
                      <FormControl variant="standard" fullWidth size="large">
                        <InputLabel variant="standard" id="demo-select-small">
                          Unidad de medida
                        </InputLabel>
                        <Select
                          variant="standard"
                          size="large"
                          disabled={editing ? true : false}
                          id="demo-select-small"
                          label="Status"
                          value={desglose[index]?.unidadMedida}
                          onChange={(e) =>
                            setDesglose((prevState) =>
                              prevState.map((v, i) =>
                                i === index
                                  ? {
                                      ...v,
                                      unidadMedida: e.target.value,
                                    }
                                  : v
                              )
                            )
                          }
                        >
                          <MenuItem value="Metro">Metro</MenuItem>
                          <MenuItem value="Pieza">Pieza</MenuItem>
                          <MenuItem value="Kilogramo">Kilogramo</MenuItem>
                          <MenuItem value="Caja">Caja</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCellModify>
                    <TableCellModify className="cellBody">
                      <TextField
                        label="Costo Unitario"
                        fullWidth
                        typeInput="currency"
                        disabled={editing ? true : false}
                        value={desglose[index]?.costoUnitario}
                        size="medium"
                        onChange={(e) =>
                          setDesglose((prevState) =>
                            prevState.map((v, i) =>
                              i === index
                                ? {
                                    ...v,
                                    costoUnitario: e.target.value,
                                    total:
                                      desglose[index]?.costoUnitario *
                                      desglose[index]?.cantidad,
                                  }
                                : v
                            )
                          )
                        }
                      />
                    </TableCellModify>
                    <TableCellModify className="cellBody">
                      {internationalCurrency(
                        desglose[index]?.costoUnitario *
                          desglose[index]?.cantidad,
                        2
                      )}{" "}
                      Mxn
                    </TableCellModify>

                    {!editing && (
                      <TableCellModify>
                        <Delete
                          className={ps.tableEliminar}
                          onClick={(e) => handleRemove(index, prod)}
                        />
                      </TableCellModify>
                    )}
                  </TableRowModify>
                ))}
              </TableBody>
            </Table>
          </Grid>
        )}
      </Grid>

      {desglose.length > 0 && (
        <Grid
          component={Paper}
          container
          className={classes.paper}
          spacing={1}
          style={{ marginTop: 10 }}
        >
          <Grid
            container
            className={classes.paper}
            spacing={2}
            style={{ marginTop: 10 }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{
                borderRadius: "5px",
                boxShadow: "0 0 10px #00000069",
                marginBottom: "30px",
              }}
            >
              <TableHead sx={{ zIndex: "1", position: "relative" }}>
                <TableRowModify>
                  <TableCellModify
                    className={ps.tableCellHead}
                    style={{ borderTopLeftRadius: "5px" }}
                  >
                    Cantidad Total
                  </TableCellModify>
                  <TableCellModify className={ps.tableCellHead}>
                    Subtotal
                  </TableCellModify>
                  <TableCellModify className={ps.tableCellHead}>
                    I. V. A.
                  </TableCellModify>
                  <TableCellModify
                    className={ps.tableCellHead}
                    style={{ borderTopRightRadius: "5px" }}
                  >
                    Total
                  </TableCellModify>
                </TableRowModify>
              </TableHead>
              <TableBody>
                <TableRowModify
                  className={ps.tableRowBody}
                  style={{
                    borderBottomRightRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                >
                  <TableCellModify className="cellBody">
                    {totales.cantidadTotal}
                  </TableCellModify>
                  <TableCellModify className="cellBody">
                    {internationalCurrency(totales.subtotal)} MXN
                  </TableCellModify>
                  <TableCellModify className="cellBody">
                    {internationalCurrency(totales.iva)} MXN
                  </TableCellModify>
                  <TableCellModify className="cellBody">
                    {internationalCurrency(totales.total)} MXN
                  </TableCellModify>
                </TableRowModify>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      )}

      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} md={12}>
          <TextField
            label="Justificación de la compra"
            variant="outlined"
            fullWidth
            multiline
            rows={6}
            disabled={editing ? true : false}
            value={propiedades?.justificacionCompra}
            helperText={modelError?.justificacionCompra}
            error={Boolean(modelError?.justificacionCompra)}
            size="medium"
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                justificacionCompra: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Elabora Solicitud
          </Typography>
          <FirmaComponentBeta
            firma={firma}
            disabled={editing ? true : false}
            setFirma={setFirma}
            firmante={user.nombreCompleto + " - " + propiedades.puestoElabora}
          />
        </Grid>
      </Grid>

      {editing && (
        <Grid
          component={Paper}
          container
          className={classes.paper}
          spacing={1}
          style={{ marginTop: 10 }}
        >
          <Grid item xs={12}>
            <div>
              <strong>{"Datos Autoriza"}</strong>
            </div>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Puesto del que Autoriza"
              fullWidth
              value={propiedades?.puestoAutoriza}
              helperText={modelError?.puestoAutoriza}
              error={Boolean(modelError?.puestoAutoriza)}
              size="small"
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  puestoAutoriza: e.target.value,
                }))
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              Autoriza
            </Typography>
            <FirmaComponentBeta
              firma={firma2}
              setFirma={setFirma2}
              firmante={user.nombreCompleto}
            />
          </Grid>
        </Grid>
      )}

      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading ||
                updateModelLoading ||
                admin ||
                (permiso && permiso[0]["ordenCompras"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={() => onGuardar()}
              size="medium"
              startIcon={<Save />}
              style={{ color: "#FFF" }}
            >
              {!editing ? "GUARDAR" : "AUTORIZAR"}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Index;
