/* eslint-disable array-callback-return */
import React, {useEffect,useState,useCallback,Fragment, useRef} from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useModels } from "../hooks/useModels";
import { propertyAccesor } from "../utils/index";
import CircularProgress from '@mui/material/CircularProgress';

function AsyncAutocompleteSearchRework({
  id= "asynchronous-demo",
  size = "small",
  color = "secondary",
  name,
  defaultValue = null,
  label,
  variant= "standard",
  labelProp,
  onChange,
  extraParams,
  clearInput,
  error,
  campoError,
  index,
  ignoreIds = [],
  propId = "",
  helperText = "",
  expand = "",
  searchParam = "",
  disabled = false,
  noOptionsText = "No hay opciones disponibles.",
  ...rest
}) {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");
  const textInput = useRef(null);

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
  ] = useModels({
    name,
    extraParams,
    expand,
  });

  useEffect(() => {
    setInput("");
  }, [clearInput]);

  useEffect(() => {
    if (defaultValue) {
      setInput(propertyAccesor(defaultValue, labelProp));
    }
  }, [defaultValue, labelProp]);

  const refreshSearch = useCallback(
    async (params) => {
      await refreshModels({ isCargando: true, params });
    },
    [refreshModels]
  );

  const onKeyPressCallback = async (e) => {
    let params = {
      [searchParam !== "" ? searchParam : labelProp]: e.target.value,
    };
    await refreshSearch(params);
  };

  return (
    (clearInput) ? null:(
    <Autocomplete
      disablePortal
      disabled={disabled}
      id="combo-box-demo"
      size={size}
      fullWidth
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onKeyUp={onKeyPressCallback}
      onChange={(e, v) => {
        isNaN(index) ? onChange(e, v) : onChange(index, e, v);
      }}
      defaultValue={defaultValue && defaultValue}
      loading={modelsLoading}
      clearOnBlur={false}
      noOptionsText={noOptionsText}
      loadingText="Cargando..."
      options={ignoreIds.length
        ? models.filter((m) => !ignoreIds.includes(m[propId]))
        : models || []
      }
      getOptionLabel={(option) => {
        return propertyAccesor(option, labelProp);
      }}
      onInputChange={(e, r) => setInput(r)}
      onInputCapture={(e) => {
        setInput(e.target.value);
        onChange();
      }}
      inputValue={input}
      clearText={"Clear"}
      renderInput={(params) => (
          <TextField 
            inputRef={textInput}
            {...params}
            key="text"
            size={size}
            color={color}
            disabled={disabled}
            label={label}
            variant={variant}
            error={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {modelsLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
        {...rest}
  />)
  );
}

export default AsyncAutocompleteSearchRework;
