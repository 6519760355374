//React
import React, { useState, useEffect, useMemo } from "react";
import { useAlert } from "../../hooks/useAlert";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import { capitalizeFirst } from "../../utils";
import { useModels } from "../../hooks/useModels";
import { useModel } from "../../hooks/useModel";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  TableContainer,
  CircularProgress,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Switch,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loading from "../../components/FullScreenLoader";
import PageHeader from "../../components/PageHeader";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";

//----------------------------------------------------------------------------------------------------------------------

//Iconos
import SaveIcon from "@mui/icons-material/Save";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import { Add, Edit } from "@mui/icons-material";
//----------------------------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

function Horario({ match, history, dialog }) {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const id = match.params.id;
  const editing = !!id;
  const [objHorario, setObjHorario] = useState([]);
  const [admin] = useLocalStorage("admin", null);
  const { permiso } = useAuth();

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "horario-sucursal",
      expand: "sucursal",
      id,
      redirectOnPost: true,
    });

  const parametrosInicialesMemo = useMemo(
    () => ({
      name: "horario-sucursal",
      ordenar: "idSucursal.desc",
      expand: "sucursal",
      extraParams: { idSucursal: id },
    }),
    [id]
  );

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({ ...parametrosInicialesMemo });

  const cargarHorarios = async (e, i) => {
    const newObj = models;
    if (e.target.name !== "show") {
      newObj[i][e.target.name] = e.target.value;
    } else {
      newObj[i][e.target.name] = e.target.checked;
      if (e.target.checked) {
        if (
          newObj[i]["fechaAbierto"] >= newObj[i]["fechaDescansoInicio"] ||
          newObj[i]["fechaAbierto"] >= newObj[i]["fechaDescansoFin"] ||
          newObj[i]["fechaCerrado"] <= newObj[i]["fechaDescansoInicio"] ||
          newObj[i]["fechaCerrado"] <= newObj[i]["fechaDescansoFin"]
        ) {
          newObj[i]["fechaAbierto"] = "07:00";
          newObj[i]["fechaCerrado"] = "20:00";
        }
      }
    }
    setObjHorario((newObj) => [...newObj]);
  };

  const guardarHorario =  async () => {
    let cont = 0;
    objHorario.map((e,i) => {
         const keys = Object.keys(e);
             keys.map((name) => {
                 if(name !== "show" && e[name] === ""){
                     showAlert({
                         message: "Favor de checar los datos, fecha inválida.",
                         severity: "error",
                       });
                     cont++;
                 }
             })
      });
      const body = {idSucursal: id, horarios: objHorario};
     if(cont === 0) updateModel(body, true,("sucursal/horario/"+id))
 } 

  useEffect(() => {
    if (models) {
      setObjHorario(models);
    }
  }, [models]);

  if (modelsLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title={"EDITAR HORARIOS SUCURSAL"}
        links={[
          {
            to: "/sucursales",
            icon: <StoreMallDirectoryIcon />,
            label: "Sucursales",
          },
          {
            to: "/sucursal/horario/" + id,
            icon: !editing ? <Add /> : <Edit />,
            label:
              "Editar Horarios - " +
              (model &&
                capitalizeFirst(model?.sucursal && model?.sucursal?.nombreSucursal.toLocaleLowerCase())),
          },
        ]}
        editing={editing}
        dialog={dialog}
      />
      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <strong>{"HORARIO SEMANAL"}</strong>
        </Grid>

        <TableContainer className={classes.container}>
          {modelLoading ? (
            <div>
              <CircularProgress />
            </div>
          ) : (
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Dia</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Abierto</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Cerrado</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Horario Comida</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Inicio</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Fin</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {objHorario &&
                  objHorario.map((item, index) => {
                    return (
                      <TableRow>
                        <TableCell>
                          <Typography>{item.dia}</Typography>
                        </TableCell>
                        <TableCell>
                          <TextField
                            label="Fecha Abierto"
                            name="fechaAbierto"
                            type="time"
                            defaultValue={item.fechaAbierto}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                            onChange={(e) => {
                              if (e.target.value === "") {
                                objHorario[index][e.target.name] = "";
                                setObjHorario((objHorario) => [...objHorario]);
                              }
                            }}
                            onBlur={(e) => {
                              if (
                                e.target.value >= item.fechaCerrado ||
                                (item.show &&
                                  e.target.value >= item.fechaDescansoInicio) ||
                                (item.show &&
                                  e.target.value >= item.fechaDescansoFin)
                              ) {
                                showAlert({
                                  message:
                                    "La fecha Inicial no puede ser superior a la fecha FinalNi a las fechas Inicio y fin de descanso.",
                                  severity: "error",
                                });
                                objHorario[index][e.target.name] =
                                  item.fechaAbierto;
                                setObjHorario((objHorario) => [...objHorario]);
                              } else {
                                cargarHorarios(e, index);
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            label="Fecha Cerrado"
                            name="fechaCerrado"
                            type="time"
                            defaultValue={item.fechaCerrado}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                            onChange={(e) => {
                              if (e.target.value === "") {
                                objHorario[index][e.target.name] = "";
                                setObjHorario((objHorario) => [...objHorario]);
                              }
                            }}
                            onBlur={(e) => {
                              if (
                                item.fechaAbierto >= e.target.value ||
                                (item.show &&
                                  e.target.value <= item.fechaDescansoInicio) ||
                                (item.show &&
                                  e.target.value <= item.fechaDescansoFin)
                              ) {
                                showAlert({
                                  message:
                                    "La fecha final no puede ser superior a la fecha inicial,Ni a las fechas Inicio y fin de descanso.",
                                  severity: "error",
                                });
                                objHorario[index][e.target.name] =
                                  item.fechaCerrado;
                                setObjHorario((objHorario) => [...objHorario]);
                              } else {
                                cargarHorarios(e, index);
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            id={index.toString() + item.dia}
                            name="show"
                            checked={item.show}
                            onChange={(e) => {
                              cargarHorarios(e, index);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            id={index.toString() + item.dia}
                            label="Inicio Desacanso"
                            name="fechaDescansoInicio"
                            type="time"
                            defaultValue={item.fechaDescansoInicio}
                            disabled={!item.show}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                            onChange={(e) => {
                              if (e.target.value === "") {
                                objHorario[index][e.target.name] = "";
                                setObjHorario((objHorario) => [...objHorario]);
                              }
                            }}
                            onBlur={(e) => {
                              if (
                                e.target.value < item.fechaAbierto ||
                                e.target.value > item.fechaCerrado ||
                                e.target.value > item.fechaDescansoInicio
                              ) {
                                showAlert({
                                  message:
                                    "La fecha Inferior a la fecha Abierto ni superior a la fecha Cerrado o Fecha Fin.",
                                  severity: "error",
                                });
                                objHorario[index][e.target.name] =
                                  item.fechaDescansoInicio;
                                setObjHorario((objHorario) => [...objHorario]);
                              } else {
                                cargarHorarios(e, index);
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            id={index.toString() + item.dia}
                            label="Fin Desacanso"
                            name="fechaDescansoFin"
                            type="time"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                            defaultValue={item.fechaDescansoFin}
                            disabled={!item.show}
                            onChange={(e) => {
                              if (e.target.value === "") {
                                objHorario[index][e.target.name] = "";
                                setObjHorario((objHorario) => [...objHorario]);
                              }
                            }}
                            onBlur={(e) => {
                              if (
                                e.target.value <= item.fechaDescansoInicio ||
                                e.target.value >= item.fechaCerrado ||
                                e.target.value < item.fechaAbierto
                              ) {
                                showAlert({
                                  message:
                                    "La fecha Inferior a la fecha Abierto ni superior a la fecha Cerrado o Fecha Fin.",
                                  severity: "error",
                                });
                                objHorario[index][e.target.name] =
                                  item.fechaDescansoInicio;
                                setObjHorario((objHorario) => [...objHorario]);
                              } else {
                                cargarHorarios(e, index);
                              }
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading ||  updateModelLoading ||
                admin ||
                (permiso && permiso[0]["sucursales"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={guardarHorario}
              size="medium"
              startIcon={<SaveIcon />}
              style={{ color: "#fff" }}
            >
              {(!editing ? "GUARDAR" : "MODIFICAR")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Horario;
