//React
import React, { useState, useRef, useEffect } from "react";
import { useAlert } from "../../hooks/useAlert";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import { capitalizeFirst } from "../../utils";
import FirebaseService from "../../services/firebase";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  FormHelperText,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loading from "../../components/FullScreenLoader";
import { useModel } from "../../hooks/useModel";
import PageHeader from "../../components/PageHeader";
import InputRegex from "../../components/InputRegex";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
//----------------------------------------------------------------------------------------------------------------------

//Iconos
import SaveIcon from "@mui/icons-material/Save";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import { Add, Edit } from "@mui/icons-material";
import { Search } from "@mui/icons-material";
import BackButton from "../../components/BackButton";
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
//----------------------------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

function Detail({ match, history, dialog }) {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const id = match.params.id;
  const editing = !!id;
  const [admin] = useLocalStorage("admin", null);
  const { permiso } = useAuth();
  const [propiedades, setPropiedades] = useState({
    nombreDepartamento: "",
  });

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "departamentos",
      id,
      redirectOnPost: true,
    });

  const onGuardar = async () => {
    let body = { ...propiedades };
    if (editing) {
      body.idDepartamento = id;
    }
    updateModel(body, true, "departamentos");
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setPropiedades(model);
    }
    return () => {
      mounted = false;
      setPropiedades({
        nombreDepartamento: "",
      });
    };
  }, [model]);

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title={(!editing ? "Nuevo" : "Editar") + " Departamento"}
        links={[
          {
            to: "/departamentos",
            icon: <HolidayVillageIcon />,
            label: "Departamentos",
          },
          {
            to: !editing ? "/departamentos/nuevo" : "/departamentos/editar/" + id,
            icon: !editing ? <Add /> : <Edit />,
            label: !editing
              ? "Nuevo"
              : "Editar - " +
                capitalizeFirst(
                  propiedades?.nombreDepartamento.toLocaleLowerCase()
                ),
          },
        ]}
        editing={editing}
        dialog={dialog}
      />
      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <strong>{"INFORMACIÓN DEL DEPARTAMENTO"}</strong>
          <Divider />
        </Grid>

        <Grid item xs={12} md={4} style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          padding: "0px",
        }}>
          <TextField
            label="Nombre del departamentos"
            fullWidth
            variant="outlined"
            typeInput="text"
            maxLength={50}
            value={propiedades.nombreDepartamento}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                nombreDepartamento: e.target.value,
              }))
            }
            helperText={modelError.nombreDepartamento}
            error={Boolean(modelError.nombreDepartamento)}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={2}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "8px",
            gap: "8px"
          }}
        >
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading ||
                updateModelLoading ||
                admin ||
                (permiso && permiso[0]["departamentos"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={onGuardar}
              size="medium"
              startIcon={<SaveIcon />}
              style={{ color: "#fff" }}
            >
              {!editing ? "GUARDAR" : "MODIFICAR"}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Detail;
