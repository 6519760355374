import React, { useState } from 'react';
import {
    Paper,
    BottomNavigation,
    BottomNavigationAction,
} from "@mui/material";
import Swal from "sweetalert2";
import { tabStyle } from '../utils';
import ViewListIcon from '@mui/icons-material/ViewList';


const useTabStyle = tabStyle();
const Tabs = ({
    tabs = [{
        title: "Tab 1",
        icon: <ViewListIcon />,
        content: <></>,
        selected: false,
    }],
    pos = 0,
    changePos,
    ...props
}) => {
    const tabStyle = useTabStyle();
    const activeTab = tabs[pos];
    return (
        <>
            <Paper elevation={3} className={tabStyle.tabsPaperNav} style={{display:"flex", flexDirection:"row"}}>
                <BottomNavigation
                    className={tabStyle.btnGroup}
                    showLabels
                    onChange={e => { }}
                >
                    {
                        tabs && tabs.map((tab, i) => {
                            return (
                                <BottomNavigationAction
                                    className={activeTab.title == tab.title ? tabStyle.btnNavActivo : tabStyle.btnNav}
                                    key={i}
                                    label={tab.title}
                                    onClick={async e => {
                                        if (activeTab.title == "Editar") {
                                            const res = await Swal.fire({
                                                title: '¿los datos no guardados se perderan deseas continuar?',
                                                text: "Favor de corroborar sus datos",
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonColor: '#3085d6',
                                                cancelButtonColor: '#d33',
                                                confirmButtonText: 'Si, Continuar!',
                                                cancelButtonText: 'Cancelar'
                                            });
                                        } else {
                                            changePos(i);
                                        }
                                    }}
                                    icon={tab.icon}
                                />

                            )
                        })
                    }
                </BottomNavigation>
            </Paper>
            <Paper className={tabStyle.tabsPaper}>
                {activeTab.content}
            </Paper>
        </>
    );

}

export default Tabs;