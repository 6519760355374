//React
import React, { useState, useRef, useEffect } from "react";
import { useAlert } from "../../hooks/useAlert";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import { capitalizeFirst } from "../../utils";
import FirebaseService from "../../services/firebase";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  FormHelperText,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loading from "../../components/FullScreenLoader";
import { useModel } from "../../hooks/useModel";
import PageHeader from "../../components/PageHeader";
import Geocode from "react-geocode";
import InputRegex from "../../components/InputRegex";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import CardMedia from "@mui/material/CardMedia";
import Map from "../../components/GoogleMap";
//----------------------------------------------------------------------------------------------------------------------

//Iconos
import SaveIcon from "@mui/icons-material/Save";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import { Add, Edit } from "@mui/icons-material";
import { PhotoCamera } from "@mui/icons-material";
import { Search } from "@mui/icons-material";
//----------------------------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

function Detail({ match, history, dialog }) {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const id = match.params.id;
  const editing = !!id;
  const [zoom, setZoom] = useState(12);
  const [admin] = useLocalStorage("admin", null);
  const { permiso } = useAuth();
  const [archivo, setArchivo] = useState();
  const [urlImg, setUrlImg] = useState("");
  const [arrayHorarioInitial, setArrayHorarioInitial] = useState([
    {
      dia: "LUNES",
      fechaAbierto: "07:00",
      show: false,
      fechaCerrado: "20:00",
      fechaDescansoInicio: "15:00",
      fechaDescansoFin: "16:00",
      orden: 1,
    },
    {
      dia: "MARTES",
      fechaAbierto: "07:00",
      show: false,
      fechaCerrado: "20:00",
      fechaDescansoInicio: "15:00",
      fechaDescansoFin: "16:00",
      orden: 2,
    },
    {
      dia: "MIERCOLES",
      fechaAbierto: "07:00",
      show: false,
      fechaCerrado: "20:00",
      fechaDescansoInicio: "15:00",
      fechaDescansoFin: "16:00",
      orden: 3,
    },
    {
      dia: "JUEVES",
      fechaAbierto: "07:00",
      show: false,
      fechaCerrado: "20:00",
      fechaDescansoInicio: "15:00",
      fechaDescansoFin: "16:00",
      orden: 4,
    },
    {
      dia: "VIERNES",
      fechaAbierto: "07:00",
      show: false,
      fechaCerrado: "20:00",
      fechaDescansoInicio: "15:00",
      fechaDescansoFin: "16:00",
      orden: 5,
    },
    {
      dia: "SABADO",
      fechaAbierto: "07:00",
      show: false,
      fechaCerrado: "20:00",
      fechaDescansoInicio: "15:00",
      fechaDescansoFin: "16:00",
      orden: 6,
    },
    {
      dia: "DOMINGO",
      fechaAbierto: "07:00",
      show: false,
      fechaCerrado: "20:00",
      fechaDescansoInicio: "15:00",
      fechaDescansoFin: "16:00",
      orden: 7,
    },
  ]);
  const inputDireccion = useRef();
  const [propiedades, setPropiedades] = useState({
    nombreSucursal: "",
    telefono: "",
    correo: "",
    comentario: "",
    ubicacion: null,
    direccion: "",
    urlFoto:
      "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sucursales%2FImagen_asdasd_894?alt=media&token=78da34f7-90e0-4785-923e-d357ff71755c",
    horarios: [],
  });

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "sucursal",
      expand: "",
      id,
      redirectOnPost: true,
    });

  const onGuardar = async () => {
    let urlFileSucursal = "";
    let body = propiedades;
    if (!editing) body.horarios = arrayHorarioInitial;
    const sucursal = propiedades.nombreSucursal.split(" ").join("").toLowerCase();
    if (archivo) {
      const ranm = Math.floor(Math.random() * 1000);
      const pathFirebase = "Sucursales/" + "Imagen" + "_" + sucursal + "_" + ranm;
      urlFileSucursal = await FirebaseService.uploadFile(pathFirebase, archivo);
      body = { ...body, urlFoto: urlFileSucursal };
    }
    if (editing) {
      body.idSucursal = id;
    }
    updateModel(body, true, "sucursales");
  };

  const deleteImg = async (url) => {
    await FirebaseService.deleteFile("Sucursal/", url);
    setUrlImg("");
  };

  const handleFileRead = async ({ target }) => {
    const file = target.files[0];
    if (file !== undefined) {
      setArchivo(file);
      setPropiedades((prevState) => ({
        ...prevState,
        ...prevState.propiedades,
        urlFoto: URL.createObjectURL(target.files[0]),
      }));
    }
  };

  const onMapsSearch = (address) => {
    Geocode.setApiKey("AIzaSyCi1iCZoUFisXN9MgPS1N0tUyRBn8bC4u4");
    Geocode.setLanguage("es");
    Geocode.setRegion("es");
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        const currentPosition = {
          lat: lat,
          lng: lng,
        };
        setPropiedades((prevState) => ({
          ...prevState,
          ...prevState.propiedades,
          ubicacion: currentPosition,
        }));
        console.log(currentPosition)
        setZoom(19);
      },
      (error) => {
        if (error && address) {
          showAlert({
            message:
              "Dirección no encontrada favor de especificar la direccion un poco más, verificar tener el orden correcto Ciudad, Calle, Número y Colonia.",
            severity: "warning",
          });
        }
      }
    );
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setPropiedades(model);
      onMapsSearch(model?.direccion);
    }
    return () => {
      mounted = false;
      setPropiedades({
        nombreSucursal: "",
        telefono: "",
        correo: "",
        comentario: "",
        ubicacion: null,
        direccion: "",
        urlFoto:
          "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Sucursales%2FImagen_asdasd_894?alt=media&token=78da34f7-90e0-4785-923e-d357ff71755c",
        horarios: [],
      });
    };
  }, [model]);

  useEffect(() => {
    if (modelError) {
      if (urlImg !== "" && urlImg !== undefined) {
        deleteImg(urlImg);
      }
    }
  }, [modelError, urlImg]);

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title={(!editing ? "Nuevo" : "Editar") + " Proyecto"}
        links={[
          {
            to: "/sucursales",
            icon: <StoreMallDirectoryIcon />,
            label: "Sucursales",
          },
          {
            to: !editing ? "/sucursal/nuevo" : "/sucursal/editar/" + id,
            icon: !editing ? <Add /> : <Edit />,
            label: !editing
              ? "Nuevo"
              : "Editar - " +
              capitalizeFirst(
                propiedades?.nombreSucursal.toLocaleLowerCase()
              ),
          },
        ]}
        editing={editing}
        dialog={dialog}
      />
      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <strong>{"INFORMACIÓN DEL PROYECTO"}</strong>
          <Divider />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputRegex
            label="Nombre del proyecto"
            fullWidth
            variant="outlined"
            typeInput="text"
            maxLength={50}
            value={propiedades.nombreSucursal}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                nombreSucursal: e.target.value,
              }))
            }
            helperText={modelError.nombreSucursal}
            error={Boolean(modelError.nombreSucursal)}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InputRegex
            label="Telefono"
            fullWidth
            variant="outlined"
            typeInput="phone"
            maxLength={10}
            value={propiedades.telefono}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                telefono: e.target.value.replaceAll(/[-() ]/g, ""),
              }))
            }
            helperText={modelError.telefono}
            error={Boolean(modelError.telefono)}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InputRegex
            label="Correo"
            fullWidth
            variant="outlined"
            typeInput="text"
            maxLength={50}
            value={propiedades.correo}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                correo: e.target.value,
              }))
            }
            helperText={modelError.correo}
            error={Boolean(modelError.correo)}
          />
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={3}>
              <Typography variant="h5" gutterBottom>
                IMAGEN
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={12}
                md={7}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
              >
                <Divider style={{ marginBottom: "2%" }} />
                {
                  <CardMedia
                    component="img"
                    image={propiedades.urlFoto}
                    title=""
                    style={{ width: "30%", height: "auto", objectFit: "cover" }}
                  />
                }
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={12} md={2}>
                  <div>
                    <input
                      hidden
                      accept="image/x-png,image/jpeg,image/gif"
                      className={classes.input}
                      id="contained-button-file"
                      type="file"
                      onChange={handleFileRead}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        style={{ color: "white" }}
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        <PhotoCamera />
                      </Button>
                    </label>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label="Comentarios"
            fullWidth
            rows={3}
            multiline
            helperText={modelError?.comentario}
            error={Boolean(modelError?.comentario)}
            size="medium"
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                comentario: e.target.value,
              }))
            }
            value={propiedades.comentario}
          />
        </Grid>

        <Grid item xs={12}>
          <strong>{"UBICACIÓN DEL PROYECTO"}</strong>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel
              ref={inputDireccion}
              style={{ color: modelError?.direccion && "red" }}
            >
              Ciudad, Calle, Número, Colonia
            </InputLabel>
            <OutlinedInput
              label="Ciudad, Calle, Número, Colonia"
              style={{ width: "100%" }}
              error={Boolean(modelError?.direccion)}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  direccion: e.target.value,
                }))
              }
              endAdornment={
                <InputAdornment position="end">
                  <Search
                    style={{ cursor: "pointer" }}
                    onClick={() => onMapsSearch(propiedades?.direccion)}
                  ></Search>
                </InputAdornment>
              }
              inputProps={{
                ref: inputDireccion,
                value: propiedades.direccion,
              }}
            />
            <FormHelperText style={{ color: "red" }}>
              {modelError?.direccion}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          {
            propiedades?.ubicacion && (
              <Map position={propiedades?.ubicacion} zoom={zoom} />
            )
          }

        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading || updateModelLoading ||
                  admin ||
                  (permiso && permiso[0]["sucursales"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={onGuardar}
              size="medium"
              startIcon={<SaveIcon />}
              style={{ color: "#fff" }}
            >
              {(!editing ? "GUARDAR" : "MODIFICAR")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Detail;
