import React, { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../utils/variables";
import { baseStyles, capitalizeFirst, internationalCurrency } from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, TableCell, Grid, Menu, MenuItem, Switch, FormControlLabel, FormControl, InputLabel, Select, TextField, Popover, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import moment from "moment-timezone";
import { useModels } from "../../hooks/useModels";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
import { NavLink } from "react-router-dom";
import DatePickerBeta from "../../components/DatePickerBeta";
import Swal from "sweetalert2";
import IconButton from "@mui/material/IconButton";
import { Add, Delete, Edit, Search, MoreVert, RestoreFromTrash, Circle, Save } from "@mui/icons-material";
import { MdPictureAsPdf } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BiPurchaseTagAlt } from "react-icons/bi";
import VisibilityIcon from '@mui/icons-material/Visibility';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { GetTotales, GetVerDetalles, GetVerMas } from './popover';
import Multifile from "../../components/Multifile";
import { useAlert } from "../../hooks/useAlert";
import Service from "../../services/api"
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import LaunchIcon from '@mui/icons-material/Launch';
import { useGet } from "../../hooks/useGet";
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';



const useStyles = baseStyles();
const Index = () => {
  const classes = useStyles();
  const history = useHistory();
  const { showAlert } = useAlert();
  const [anchorEl, setAnchorEl] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [pagina, setPagina] = useState(1);
  const [openMenuRow, setOpenMenuRow] = useState(null);
  const [admin] = useLocalStorage("admin", null);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const { user, permiso } = useAuth();
  const [idActual, setIdActual] = useState(0);
  const header = [
    { prop: "proyecto", name: "NOMBRE DEL PROYECTO", sortable: true },
    { prop: "noOrden", name: "NUMERO DE ORDEN", sortable: true },
  ];
  const [open, setOpen] = useState(false);
  const [openFoto, setOpenFoto] = useState(false);
  const [data, setData] = useState(null);
  const [tipo, setTipo] = useState("tabla");
  const [propiedades, setPropiedades] = useState({
    proyecto: "",
    noOrden: "",
    fechaInicio: moment().startOf("month").format("yyyy/MM/DD"),
    fechaFinal: moment(new Date()).format("yyyy/MM/DD"),
    status: "",
    tipoCompra: "",
    eliminado: 0,
    fecha: moment(new Date()).format("YYYY-MM-DD H:m:s"),
  });

  const Status = {
    'Proceso': '#FFFF00',
    'Aceptado': '#ff8000',
    'Rechazado': '#FF0000',
    'Finalizado': '#008000'
  }

  const parametrosInicialesMemo = useMemo(() => ({
    name: "orden-compra",
    ordenar: "idOrdenCompra.desc",
    limite: 10,
    expand: "desglose, proveedor.datosBancarios, departamento, proveedor.telefonos, proveedor.correos, bancos, archivos"
  }), []);
  const [models, modelsLoading, modelsError, modelsPage, refreshModels, deleteModel, updateModel] = useModels({ ...parametrosInicialesMemo });

  //----------------------------------------------------------------------------------------------------------------------

  //Funciones
  const onSearchClicked = async (eliminado) => {
    let params = {};
    if (eliminado != null) {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
      };
    } else {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage },
      };
    }
    await refreshModels({ isCargando: true, params });
  };

  const changePage = async (page) => {
    setPagina(page + 1);
    let params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ pagina: page + 1, limite: perPage },
    };
    await refreshModels({ isCargando: true, params });
  };

  const changePageRow = async (per) => {
    setPerPage(per);
    const params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ limite: per, pagina: pagina },
    };
    await refreshModels({ isCargando: true, params });
  };

  const onDeleteClicked = async (row, type) => {
    try {
      setOpenMenuRow(false);
      await Swal.fire({
        title: type,
        text: `¿Desea ${type} el dato seleccionado  "${capitalizeFirst(
          row.noOrden.toLowerCase()
        )}"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34AA44",
        cancelButtonColor: "#E72C33",
        confirmButtonText: "Si, deseo Continuar.",
      }).then((result) => {
        if (result.isConfirmed) {
          let params = {};
          params = {
            ...parametrosInicialesMemo,
            ...propiedades,
            ...{ pagina: pagina, limite: perPage },
          };
          deleteModel(row.idOrdenCompra, type, params);
        }
      });
    } catch (error) {
      console.modelsError(error);
    }
  };

  const guardarPDfs = async () => {
    let title = "";
    (title = "¿Deseas Guardar este archivo de pago?");
    const res = await Swal.fire({
      title: title,
      text: "Favor de corroborar sus datos antes de continuar.",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Continuar!",
      cancelButtonText: "Cancelar",
    });
    if (res.isConfirmed) {
      showAlert({
        message: "Guardando archivo de pago",
        severity: "warning",
      });
      let res = await Service.post("orden-compra/guardar-pdf", { idOrdenCompra: idActual, files, fecha: propiedades?.fecha });
      if (res) {
        showAlert({
          message: "Archivo guardado correctamente.",
          severity: "success"
        });
      }
    }
  };

  const guardarFotos = async () => {
    let title = "";
    (title = "¿Deseas Guardar este archivo de pago?");
    const res = await Swal.fire({
      title: title,
      text: "Favor de corroborar sus datos antes de continuar.",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Continuar!",
      cancelButtonText: "Cancelar",
    });
    if (res.isConfirmed) {
      showAlert({
        message: "Guardando archivo de pago",
        severity: "warning",
      });
      let res = await Service.post("orden-compra/guardar-foto", { idOrdenCompra: idActual, files, fecha: propiedades?.fecha });
      if (res) {
        showAlert({
          message: "Foto del articulo guardada correctamente.",
          severity: "success"
        });
      }
    }
  };

  const guardarTipoCompra = async (body) => {
    let title = "";
    (title = "¿Deseas Guardar esta orden de compra?");
    const res = await Swal.fire({
      title: title,
      text: "Favor de corroborar sus datos antes de continuar.",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Continuar!",
      cancelButtonText: "Cancelar",
    });
    if (res.isConfirmed) {
      showAlert({
        message: "Guardando orden de compra",
        severity: "warning",
      });
      updateModel(body, true, "ordenCompra");
    }
  };

  return (
    <>
      <TablePageLayout title="Orden de Pago" model="orden-compra" history={history}
        actionButton={{ to: "/ordenCompra/nuevo", icon: <Add />, label: "Agregar Orden de Pago", permiso: "ordenCompras" }}
        links={[{ label: "Orden de Pago", icon: <BiPurchaseTagAlt fontSize="large" /> }]}
        SearchComponents={
          <>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Nombre del proyecto"
                fullWidth
                variant="outlined"
                size="small"
                typeInput="text"
                maxLength={50}
                value={propiedades.proyecto}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    proyecto: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Número de orden"
                fullWidth
                variant="outlined"
                size="small"
                typeInput="text"
                maxLength={50}
                value={propiedades.noOrden}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    noOrden: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} md={3} >
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel variant="outlined" id="demo-select-small">Estatus</InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  id="demo-select-small"
                  label="Estatus"
                  value={propiedades.status}
                  onChange={(e) =>
                    setPropiedades((prevState) => ({
                      ...prevState,
                      ...prevState.propiedades,
                      status: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="Proceso">Proceso</MenuItem>
                  <MenuItem value="Aceptado">Aceptado</MenuItem>
                  <MenuItem value="Rechazado">Rechazado</MenuItem>
                  <MenuItem value="Finalizado">Finalizado</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <LoadingButton
                variant="contained"
                onClick={() => onSearchClicked(null)}
                loading={modelsLoading && true}
                style={{
                  float: "right",
                  color: "whitesmoke",
                  justifyContent: "flex-end",
                }}
                startIcon={<Search />}
              >
                Buscar
              </LoadingButton>
            </Grid>

            <Grid item xs={12} md={4}>
              <DatePickerBeta
                labelText="Fecha Inicio"
                fullWidth
                inputFormat="dd/MM/yyyy"
                value={propiedades.fechaInicio}
                size="small"
                onChange={(e) => setPropiedades(prevState => ({
                  ...prevState,
                  ...prevState.propiedades,
                  fechaInicio: moment(e).format("YYYY-MM-DD H:m:s")
                }))
                }
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <DatePickerBeta
                labelText="Fecha Final"
                fullWidth
                inputFormat="dd/MM/yyyy"
                value={propiedades.fechaFinal}
                size="small"
                onChange={(e) => setPropiedades(prevState => ({
                  ...prevState,
                  ...prevState.propiedades,
                  fechaFinal: moment(e).format("YYYY-MM-DD H:m:s")
                }))
                }
              />
            </Grid>

          </>
        }
        DisableComponents={
          <>
            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={modelsLoading && true}
                    onChange={(e) => {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        eliminado: e.target.checked ? 1 : 0,
                      }));
                      onSearchClicked(e.target.checked ? 1 : 0);
                    }}
                  />
                }
                label="Ver Finalizadas"
              />
            </Grid>

            <Grid item xs={4} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }} >
              <Grid item xs={2} style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                <IconButton color="error" aria-label="upload picture" component="span" >
                  <a
                    className={classes.btnPdf}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}pdf/orden?
                  ${"admin=" + (admin ? 1 : 0)}
                  ${"&uid=" + user?.idUsuario}
                  ${"&proyecto=" + propiedades.proyecto}
                  ${"&noOrden=" + propiedades.noOrden}
                  ${"&status=" + propiedades.status}
                  ${"&fechaInicio=" + propiedades.fechaInicio}
                  ${"&fechaFinal=" + propiedades.fechaFinal}
                  ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`} >
                    <MdPictureAsPdf />
                  </a>
                </IconButton>
              </Grid>
              <Grid item xs={2} style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                <IconButton color="warning" aria-label="upload picture" component="span">
                  <a className={classes.btnExcel} target="_blank" rel="noreferrer"
                    href={`${baseUrl}excel/orden?
                  ${"admin=" + (admin ? 1 : 0)}
                  ${"&uid=" + user?.idUsuario}
                  ${"&proyecto=" + propiedades.proyecto}
                  ${"&noOrden=" + propiedades.noOrden}
                  ${"&status=" + propiedades.status}
                  ${"&fechaInicio=" + propiedades.fechaInicio}
                  ${"&fechaFinal=" + propiedades.fechaFinal}
                  ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`} >
                    <RiFileExcel2Fill />
                  </a>
                </IconButton>
              </Grid>
            </Grid>
          </>
        }
        tableLoading={modelsLoading}
        tableErrors={modelsError}
        TableComponent={
          <TablePagination
            nombrePermiso="ordenCompras"
            permiso={permiso}
            header={header}
            data={models}
            paginatedApi
            pagina={pagina}
            paginationPageSize={perPage}
            onRowsPerPageChangeApi={changePageRow}
            changePageApi={changePage}
            count={modelsPage !== null ? modelsPage.total : 0}
            labelRowsPerPage={"Renglones por página"}
            extraRows={[
              {
                prop: "status",
                name: "STATUS",
                sortable: false,
                cell: (row, index) => <Circle style={{ paddingRight: 5, color: Status[row?.status] }} />
              },
              {
                prop: "departamento",
                name: "DEPARTAMENTO",
                sortable: false,
                cell: (row, index) => <p>{row?.departamento?.nombreDepartamento}</p>
              },
              {
                prop: "proveedor",
                name: "PROVEEDOR",
                sortable: false,
                cell: (row, index) => {
                  const downloadAll = () => {
                    row.proveedor.datosBancarios.forEach((datosBancarios) => {
                      const link = document.createElement("a");
                      link.href = datosBancarios?.urlArchivo;
                      link.target = "_blank";
                      link.download = datosBancarios?.nombreArchivo;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    });
                  };

                  return (
                    <div>
                      <IconButton onClick={downloadAll}><PresentToAllIcon /> </IconButton>
                    </div>
                  );
                }
              },
              {
                prop: "desglose",
                name: "DESGLOSE",
                sortable: false,
                cell: (row, index) => (
                  <IconButton
                    onClick={() => {
                      setOpen(true)
                      setTipo("tabla")
                      setData(row)
                      console.log("show", row)
                    }}
                    variant="contained"
                  >
                    <VisibilityIcon />
                  </IconButton>
                )
              },
              {
                prop: "tipoCompra",
                name: "TIPO COMPRA",
                sortable: false,
                cell: (row, index) => {
                  const selectValue = propiedades?.[index]?.tipoCompra || '';

                  const saveToDatabase = async (value) => {
                    try {
                      let res = await Service.post("orden-compra/guardar-tipo-compra", { idOrdenCompra: row.idOrdenCompra, tipoCompra: value });
                      console.log(res)
                      if (res?.detalle?.estatus === 200) {
                        showAlert({
                          message: res?.mensaje,
                          severity: "success"
                        });
                      } else {
                        showAlert({
                          message: "no se pudo guardar el tipo de compra",
                          severity: "error"
                        });
                      }
                    } catch (error) {
                      console.error('Error en la solicitud de guardar en la base de datos:', error);
                    }
                  };
                  const savedValue = row.tipoCompra || '';

                  return (
                    <Select
                      style={{ width: "150px" }}
                      size="small"
                      label="Tipo de Compra"
                      value={selectValue || savedValue}
                      onChange={(e) => {
                        const selectedValue = e.target.value;
                        setPropiedades((prevState) => ({
                          ...prevState,
                          [index]: {
                            ...prevState[index],
                            tipoCompra: selectedValue,
                          },
                        }));
                        saveToDatabase(selectedValue);
                      }}
                      variant="outlined"
                      native
                    >
                      <option value="">Seleccionar</option>
                      <option value="COMBUSTIBLE">Combustible</option>
                      <option value="ALIMENTO">Alimento</option>
                      <option value="SERVICIO">Servicios</option>
                      <option value="LIMPIEZA">Limpieza</option>
                      <option value="EQUIPO">Equipos</option>
                      <option value="MATERIAS">Materias primas</option>
                      <option value="BIENES">Bienes</option>
                      <option value="ALQUILER">Alquiler</option>
                      <option value="ELECTRONICOS">Electronicos</option>
                      <option value="TELEFONO">Telefonia</option>
                      <option value="MANTENIMIENTO">Mantenimiento</option>
                      <option value="TRANSPORTE">Transporte</option>
                      <option value="PUBLICIDAD">Publicidad y Propaganda</option>
                      <option value="PAPELERIA">Papeleria</option>
                      <option value="VIAJE">Gastos de viaje</option>
                    </Select>
                  );
                }
              },
              {
                prop: "archivo",
                name: "PDF DE PAGO",
                sortable: false,
                cell: (row, index) => {
                  const hasData = row?.bancos?.length > 0;
                  const buttonColor = hasData ? "green" : "grey";

                  return (admin === true || (permiso && permiso[0]["banco"] & 16) === 16) &&
                    (row.status === "Aceptado" || row.status === "Finalizado") &&
                    (row.eliminado === null || row.eliminado !== null) && (
                      <IconButton
                        onClick={() => {
                          setOpen(true);
                          setTipo("archivo");
                          setFiles(row?.bancos);
                          setPropiedades({ ...propiedades, fecha: moment(new Date(row?.bancos[0]?.fecha)).add(1, "day").format("YYYY-MM-DD H:m:s") });
                          setIdActual(row?.idOrdenCompra);
                        }}
                        variant="contained"
                        style={{ color: buttonColor }}
                      >
                        <UploadFileIcon />
                      </IconButton>
                    );
                },

              },
              {
                prop: "fotoArticulo",
                name: "FOTO DEL ARTICULO",
                sortable: false,
                cell: (row, index) => {
                  const hasData = row?.archivos?.length > 0;
                  const buttonColor = hasData ? "green" : "grey";

                  return (admin === true || (permiso && permiso[0]["ordenCompras"] & 3) === 3) && row.status === "Finalizado" && row.eliminado !== null && (
                    <IconButton
                      onClick={() => {
                        setOpenFoto(true)
                        setTipo("foto")
                        setFiles(row?.archivos)
                        setIdActual(row?.idOrdenCompra);
                      }}
                      variant="contained"
                      style={{ color: buttonColor }}
                    >
                      <AddPhotoAlternateIcon />
                    </IconButton>
                  )
                }
              },
              {
                prop: "acciones",
                name: "ACCIONES",
                sortable: false,
                cell: (row, index) => (permiso && ((permiso[0]["ordenCompras"] & 4) === 4 || (permiso[0]["ordenCompras"] & 8) === 8)) && (
                  <div>
                    <IconButton onClick={(event) => { setOpenMenuRow(index); setAnchorEl(event.currentTarget) }}>
                      <MoreVert />
                    </IconButton>
                    <Menu anchorEl={anchorEl} keepMounted open={index === openMenuRow} onClose={() => setOpenMenuRow(null)}>
                      {(admin === true || (permiso && permiso[0]["ordenCompras"] & 4) === 4) && (row.eliminado == null) && (
                        <NavLink exact to={(`ordenCompra/editar/${row.idOrdenCompra}`)} style={{ color: '#000', textDecoration: 'none' }}>
                          <MenuItem aria-label="editar"
                            onClick={() => {
                              history.push(`ordenCompra/editar/${row.idOrdenCompra}`, { match: row.idOrdenCompra });
                            }}>
                            <Edit style={{ paddingRight: 5 }} />
                            Autorizar
                          </MenuItem>
                        </NavLink>
                      )}
                      {(admin === true || (permiso && permiso[0]["ordenCompras"] & 8) === 8) && (
                        row?.eliminado === null ? (
                          <MenuItem onClick={() => onDeleteClicked(row, "Desabilitar")} >
                            <Delete style={{ paddingRight: 5 }} />
                            Deshabilitar
                          </MenuItem>
                        ) : (
                          <MenuItem onClick={() => onDeleteClicked(row, "Habilitar")} >
                            <RestoreFromTrash style={{ paddingRight: 5 }} />
                            Habilitar
                          </MenuItem>
                        )
                      )}

                    </Menu>
                  </div>
                ),
              },
            ]}
          />
        }
      />
      <Dialog
        open={open}
        fullWidth
        style={{ zIndex: 10, display: 'flex', justifyContent: 'flex-end' }}
        maxWidth="md"
        onClose={() => {
          setOpen(false)
          setFiles([])
        }}
      >
        <DialogTitle>{tipo === "tabla" ? "Orden de Compra:" + data?.noOrden : "SUBIR PDF:"}</DialogTitle>
        <DialogContent>
          {tipo === "tabla" &&
            <DialogContentText>
              Desglose de la Orden de Compra:
            </DialogContentText>
          }
          {tipo === "tabla" ?
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Descripción del artículo</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="right">Cantidad</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="right">U de M</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="right">Costo Unidad</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && data?.desglose && data?.desglose.map((row) => (
                    <TableRow
                      key={row.idDesgloseOrdenCompra}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">{row?.descripcionArticulo}</TableCell>
                      <TableCell align="right">{row?.cantidad}</TableCell>
                      <TableCell align="right">{row?.unidadMedida}</TableCell>
                      <TableCell align="right">{internationalCurrency(row?.costoUnitario)}</TableCell>
                      <TableCell align="right">{internationalCurrency(row?.total)}</TableCell>
                    </TableRow>
                  ))}

                  <TableRow>
                    <TableCell colSpan={3}></TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="right">SubTotal</TableCell>
                    <TableCell align="right">{internationalCurrency(data?.subTotal)}</TableCell>
                  </TableRow>
                  {/* <TableRow>
                    <TableCell colSpan={3}></TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="right">IVA Totales</TableCell>
                    <TableCell align="right">{internationalCurrency(data?.iva)}</TableCell>
                  </TableRow> */}
                  <TableRow>
                    <TableCell colSpan={3}></TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="right">Total</TableCell>
                    <TableCell align="right">{internationalCurrency(data?.total)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            :
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <div style={{ width: "100%" }}>
                <Multifile loading={loading} setLoading={setLoading} files={files.filter(file => file.type === 'application/pdf')} setFiles={setFiles} firebaseFolder={"Bancos"} />
              </div>
              <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div style={{ flex: "1", marginRight: "10px" }}>
                  <DatePickerBeta
                    labelText="Fecha"
                    fullWidth
                    inputFormat="dd/MM/yyyy"
                    value={propiedades?.fecha}
                    size="small"
                    onChange={(e) => setPropiedades(prevState => ({
                      ...prevState,
                      ...prevState.propiedades,
                      fecha: moment(e).format("YYYY-MM-DD H:m:s")
                    }))
                    }
                  />
                </div>
                <Button variant="contained" size="medium" startIcon={<Save />} style={{ color: "#FFF" }} onClick={() => guardarPDfs()}>GUARDAR</Button>
              </div>
            </div>

          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setOpen(false)
            setFiles([])
          }}>Cerrar</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openFoto}
        fullWidth
        style={{ zIndex: 10, display: 'flex', justifyContent: 'flex-end' }}
        maxWidth="md"
        onClose={() => {
          setOpenFoto(false)
          setFiles([])
        }}>
        {tipo === "foto" &&
          <>
            < DialogTitle > SUBIR FOTO DEL ARTICULO</DialogTitle>
            <DialogContent>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ width: "100%" }}>
                  <Multifile loading={loading} setLoading={setLoading} files={files.filter(file => file.type === 'image/jpeg' || file.type === 'image/png')} setFiles={setFiles} firebaseFolder={"Articulos_Orden_Compra"} />
                </div>
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <div style={{ flex: "1", marginRight: "10px" }}>
                    <DatePickerBeta
                      labelText="Fecha"
                      fullWidth
                      inputFormat="yyy/MM/dd"
                      value={propiedades?.fecha}
                      size="small"
                      onChange={(date) => setPropiedades({ ...propiedades, fecha: date })}
                    />
                  </div>
                  <Button variant="contained" size="medium" startIcon={<Save />} style={{ color: "#FFF" }} onClick={() => guardarFotos()}>GUARDAR</Button>
                </div>
              </div>
            </DialogContent>
          </>
        }
        <DialogActions>
          <Button onClick={() => {
            setOpenFoto(false)
            setFiles([])
          }}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Index;
