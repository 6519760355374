import * as React from 'react';
import { preNavPublicoStyle } from "../utils";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const preNavStyles = preNavPublicoStyle();
const Component = (props) => {
    const styles = preNavStyles();
    return (
        <>
            <div className={styles.preNavContainer}>
                <p className={styles.dirNav}>Navarrete 192 local L villa satélite</p>
                <p className={styles.contactoNav}>contacto@mineralesdetarachi.com</p>
                <div className={styles.redesNav}>
                    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/MineralesdeTarachi"><FacebookIcon/></a>
                    <a target="_blank" rel="noreferrer"  href="https://www.instagram.com/mineralesdetarachi/?hl=es"><InstagramIcon/></a>
                    <a target="_blank" rel="noreferrer"  href=""><WhatsAppIcon/></a>
                </div>
            </div>
        </>
    )
  }
  
export default Component;