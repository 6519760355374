//React
import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../utils/variables";
import { baseStyles, capitalizeFirst } from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import {
  Grid,
  Menu,
  MenuItem,
  Switch,
  Avatar,
  Modal,
  FormControlLabel,
} from "@mui/material";
import { useModels } from "../../hooks/useModels";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";

//----------------------------------------------------------------------------------------------------------------------

//Librerias
import Swal from "sweetalert2";
//----------------------------------------------------------------------------------------------------------------------

//Iconos
import { Add, Edit, Delete, Search, AccessTimeFilled } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import { MdPictureAsPdf } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";

//----------------------------------------------------------------------------------------------------------------------

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#FFF",
  outline: "none",
  padding: "15px 0 0 0",
  borderRadius: "5px",
  boxShadow: "0 0 15px black",
};

const useStyles = baseStyles();

const Index = () => {
  //Estados Iniciales
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [perPage, setPerPage] = React.useState(10);
  const [pagina, setPagina] = React.useState(1);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const [imgUsuarioModal, setImgUsuarioModal] = useState("");
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const [admin] = useLocalStorage("admin", null);
  const { user } = useAuth();
  //----------------------------------------------------------------------------------------------------------------------

  //Estados Iniciales Controller
  const header = [
    {
      prop: "nombreSucursal",
      name: "Nombre Proyecto",
    },
    {
      prop: "correo",
      name: "Correo",
    },
    {
      prop: "telefono",
      name: "Telefono",
      sortable: false,
    },
    {
      prop: "direccion",
      name: "Dirección",
      sortable: false,
    },
    {
      prop: "comentario",
      name: "Comentario",
      sortable: false,
    },
  ];

  const [propiedades, setPropiedades] = useState({
    nombreSucursal: "",
    correo: "",
    telefono: "",
    direccion: "",
    eliminado: 0,
  });

  const parametrosInicialesMemo = useMemo(
    () => ({
      name: "sucursal",
      ordenar: "idSucursal.desc",
    }),
    []
  );

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({ ...parametrosInicialesMemo });

  //----------------------------------------------------------------------------------------------------------------------

  //Funciones

  const onSearchClicked = async (eliminado) => {
    let params = {};
    if (eliminado != null) {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
      };
    } else {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage },
      };
    }
    await refreshModels({ isCargando: true, params });
  };

  const changePage = async (page) => {
    setPagina(page + 1);
    let params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ pagina: page + 1, limite: perPage },
    };
    await refreshModels({ isCargando: true, params });
  };

  const changePageRow = async (per) => {
    setPerPage(per);
    const params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ limite: per, pagina: pagina }
    };
    await refreshModels({ isCargando: true, params });
  };

  const onDeleteClicked = async (row, type) => {
    try {
      setOpenMenuRow(false);
      await Swal.fire({
        title: type,
        text: `¿Desea ${type} el dato seleccionado  "${capitalizeFirst(
          row.nombreSucursal.toLowerCase()
        )}"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34AA44",
        cancelButtonColor: "#E72C33",
        confirmButtonText: "Si, deseo Continuar.",
      }).then((result) => {
        if (result.isConfirmed) {
          let params = {};
          params = {
            ...parametrosInicialesMemo,
            ...propiedades,
            ...{ pagina: pagina, limite: perPage },
          };
          deleteModel(row.idSucursal, type, params);
        }
      });
    } catch (error) {
      console.modelsError(error);
    }
  };

  //----------------------------------------------------------------------------------------------------------------------

  //Efectos

  //----------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={modalStyle}>
          <CloseIcon
            onClick={handleClose}
            style={{
              marginBottom: "15px",
              marginRight: "15px",
              display: "flex",
              marginLeft: "auto",
              cursor: "pointer",
            }}
          />
          <img
            style={{ maxHeight: "600px", minHeight: "400px", width: "100%" }}
            src={imgUsuarioModal}
            alt="imgUsuarioModal"
          ></img>
        </div>
      </Modal>
      <TablePageLayout
        title="Proyectos"
        model="sucursal"
        history={history}
        actionButton={{
          to: "/sucursal/nuevo",
          icon: <Add />,
          label: "Agregar Proyecto",
        }}
        links={[
          {
            label: "Sucursales",
            icon: <StoreMallDirectoryIcon />,
          },
        ]}
        SearchComponents={
          <>
            <Grid item xs={12} sm={4}>
              <InputRegex
                color="primary2"
                label="Nombre del proyecto"
                variant="outlined"
                fullWidth
                typeInput="text"
                maxLength={50}
                value={propiedades.nombreSucursal}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    nombreSucursal: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputRegex
                color="primary2"
                label="Correo"
                variant="outlined"
                fullWidth
                typeInput="text"
                maxLength={50}
                value={propiedades.correo}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    correo: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <LoadingButton
                variant="contained"
                onClick={() => onSearchClicked(null)}
                loading={modelsLoading && true}
                style={{
                  float: "right",
                  color: "whitesmoke",
                }}
                startIcon={<Search />}
              >
                Buscar
              </LoadingButton>
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputRegex
                color="primary2"
                label="Telefono"
                variant="outlined"
                fullWidth
                typeInput="phone"
                maxLength={10}
                value={propiedades.telefono}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    telefono: e.target.value.replaceAll(/[-() ]/g, ""),
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputRegex
                color="primary2"
                label="Dirección"
                variant="outlined"
                fullWidth
                typeInput="text"
                maxLength={30}
                value={propiedades.direccion}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    direccion: e.target.value,
                  }))
                }
              />
            </Grid>
          </>
        }
        DisableComponents={
          <>
            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={modelsLoading && true}
                    onChange={(e) => {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        eliminado: e.target.checked ? 1 : 0,
                      }));
                      onSearchClicked(e.target.checked ? 1 : 0);
                    }}
                  />
                }
                label="Ver Desabilitados"
              />
            </Grid>

            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="error"
                  aria-label="upload picture"
                  component="span"
                >
                  <a
                    className={classes.btnPdf}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}pdf/sucursales?${"admin=" + (admin ? 1 : 0)
                      }${"&uid=" + user?.idUsuario}${"&nombreSucursal=" + propiedades.nombreSucursal
                      }${"&direccion=" + propiedades.direccion}${"&telefono=" + propiedades.telefono
                      }${"&correo=" + propiedades.correo}${"&eliminado=" + (propiedades.eliminado ? 1 : 0)
                      }`}
                  >
                    <MdPictureAsPdf />
                  </a>
                </IconButton>
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="warning"
                  aria-label="upload picture"
                  component="span"
                >
                  <a
                    className={classes.btnExcel}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}excel/sucursales?${"admin=" + (admin ? 1 : 0)
                      }${"&uid=" + user?.idUsuario}${"&nombreSucursal=" + propiedades.nombreSucursal
                      }${"&correo=" + propiedades.correo}${"&telefono=" + propiedades.telefono
                      }${"&direccion=" + propiedades.direccion}${"&eliminado=" + (propiedades.eliminado ? 1 : 0)
                      }`}
                  >
                    <RiFileExcel2Fill />
                  </a>
                </IconButton>
              </Grid>
            </Grid>
          </>
        }
        tableLoading={modelsLoading}
        tableErrors={modelsError}
        TableComponent={
          <TablePagination
            header={header}
            data={models}
            paginatedApi
            pagina={pagina}
            paginationPageSize={perPage}
            onRowsPerPageChangeApi={changePageRow}
            changePageApi={changePage}
            count={modelsPage !== null ? modelsPage.total : 0}
            labelRowsPerPage={"Renglones por página"}
            extraRows={[
              {
                prop: "urlFoto",
                name: "FOTO",
                sortable: false,
                cell: (row, index) => (
                  <div>
                    <Avatar
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setImgUsuarioModal(row.urlFoto);
                        handleOpen();
                      }}
                      alt={row.nombre}
                      src={row.urlFoto}
                    />
                  </div>
                ),
              },
              {
                prop: "acciones",
                name: "Acciones",
                sortable: false,
                cell: (row, index) => (
                  <div>
                    <IconButton
                      onClick={(event) => {
                        setOpenMenuRow(index);
                        setAnchorEl(event.currentTarget);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={index === openMenuRow}
                      onClose={() => setOpenMenuRow(null)}
                    >
                      {row?.eliminado === null && (
                        <MenuItem
                          onClick={() =>
                            history.push(`/sucursal/editar/${row.idSucursal}`)
                          }
                        >
                          <Edit style={{ paddingRight: 5 }} />
                          Editar
                        </MenuItem>
                      )}
                      {row?.eliminado === null ? (
                        <MenuItem
                          onClick={() => onDeleteClicked(row, "Desabilitar")}
                        >
                          <Delete style={{ paddingRight: 5 }} />
                          Desabilitar
                        </MenuItem>
                      ) : (
                        <MenuItem
                          onClick={() => onDeleteClicked(row, "Habilitar")}
                        >
                          <RestoreFromTrashIcon style={{ paddingRight: 5 }} />
                          Habilitar
                        </MenuItem>
                      )}
                    </Menu>
                  </div>
                ),
              },
            ]}
          />
        }
      />
    </>
  );
};
export default Index;
