/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { capitalizeFirst, permisosStyle, tableCellStyles, tableRowStyles, internationalCurrency, } from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import { useModel } from "../../hooks/useModel";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAlert } from "../../hooks/useAlert";
import Loading from "../../components/FullScreenLoader";
import PageHeader from "../../components/PageHeader";
import DatePicker from "../../components/DatePickerBeta";
import InputRegex from "../../components/InputRegex";
import FirebaseService from "../../services/firebase";
import FirmaComponentBeta from "../../components/FirmaComponentBeta";
import { useModels } from "../../hooks/useModels";
import { Grid, Divider, Paper, Table, TableHead, TableCell, TableRow, TableBody, TextField, Typography, FormControl, InputLabel, Select, MenuItem, } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Swal from "sweetalert2";
import moment from "moment-timezone";
import { Add, Delete, Edit, Save } from "@mui/icons-material";
import AsyncAutocompleteSearchRework from "../../components/AsyncAutocompleteSearchRework";
import { BiPurchaseTagAlt } from "react-icons/bi";
import Service from '../../services/api'

const useStyles = makeStyles((theme) => ({ paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
const TableCellModify = tableCellStyles()(TableCell);
const TableRowModify = tableRowStyles()(TableRow);
const pStyles = permisosStyle();

const Index = ({ match, history, dialog }) => {
  const classes = useStyles();
  const ps = pStyles();
  const { showAlert } = useAlert();
  const id = match.params.id;
  const editing = !!id;
  const [admin] = useLocalStorage("admin", null);
  const [idSucursal] = useLocalStorage("idSucursal", "");
  const { user, permiso } = useAuth();

  const parametrosInicialesMemo = useMemo(() => ({
      name: "orden-compra",
      ordenar: "idOrdenCompra.desc",
      extraParams: { idCreador: user?.idUsuario },
    }), [user?.idUsuario]
  );
  const [models] = useModels({ ...parametrosInicialesMemo });

  const [proveedor, setProveedor] = useState(null);
  const [departamento, setDepartamento] = useState(null);
  const [contadorFolios, setContadorFolios] = useState(1);
  const [firma, setFirma] = useState(null);
  const [firma2, setFirma2] = useState(null);
  const [totales, setTotales] = useState({
    cantidadTotal: 0,
    costoTotal: 0,
    subTotal: 0,
    total: 0,
  });
  const defaultProducto = {
    cantidad: "",
    descripcionArticulo: "",
    costoUnitario: "",
    unidadMedida: "Pieza",
  };
  const [producto, setProducto] = useState({ ...defaultProducto });
  const [desglose, setDesglose] = useState([]);
  const [propiedades, setPropiedades] = useState({
    idProveedor: "",
    rfc: "",
    idDepartamento: "",
    proyecto: "",
    fecha: moment(new Date()).format("YYYY-MM-DD"),
    noOrden: "",
    fechaConclusion: "",
    status: "Proceso",
    subTotal: 0,
    total: 0,
    firmaElabora: "",
    firmaAutoriza: "",
    nombreElabora: "",
    nombreAutoriza: "",
    puestoElabora: "",
    puestoAutoriza: "",
    justificacionCompra: "",
    fechaAutorizacion: "",
    moneda: "",
    tipoCompra: "",
    modoPago: "",
  });
  const [puestoAutoriza, setPuestoAutoriza] = useState(user?.puesto || "");


  const proveedorParamsMemo = useMemo(
    () =>
      editing
        ? {
          pagina: 1,
          limite: 10,
          ordenar: "nombreProveedor.desc",
          idProveedor: proveedor,
        }
        : { pagina: 1, limite: 10, ordenar: "nombreProveedor.desc" },
    [editing, proveedor]
  );

  const departamentoParamsMemo = useMemo(
    () =>
      editing
        ? {
          pagina: 1,
          limite: 10,
          ordenar: "nombreDepartamento.desc",
          idDepartamento: departamento,
        }
        : { pagina: 1, limite: 10, ordenar: "nombreDepartamento.desc" },
    [departamento, editing]
  );

  const { model, modelLoading, modelError, updateModel, updateModelLoading } = useModel({
    name: "orden-compra",
    id,
    redirectOnPost: true,
    expand: "desglose",
  });

  const handleRemove = (position, row) => {
    let subTotal = totales.costoTotal - parseInt(row.costoUnitario) * parseInt(row.cantidad);
    setTotales({
      cantidadTotal: totales.cantidadTotal - parseInt(row.cantidad),
      costoTotal: totales.costoTotal - parseInt(row.costoUnitario) * parseInt(row.cantidad),
      subTotal: (subTotal - subTotal * 0.16).toFixed(2),
      total: subTotal,
    });
    const copyRows = [...desglose];
    copyRows.splice(position, 1);
    setDesglose(copyRows);
  };

  const addProduct = () => {
    if (
      producto.cantidad !== "" &&
      producto.descripcionArticulo !== "" &&
      producto.costoUnitario !== "" &&
      producto.unidadMedida !== ""
    ) {
      const subtotal = parseFloat(producto.cantidad) * parseFloat(producto.costoUnitario);
      const newProduct = {
        cantidad: producto.cantidad,
        descripcionArticulo: producto.descripcionArticulo,
        costoUnitario: producto.costoUnitario,
        unidadMedida: producto.unidadMedida,
        total: (subtotal).toFixed(2),
      };
      setDesglose([...desglose, newProduct]);
      const newTotales = {
        cantidadTotal: totales.cantidadTotal + parseFloat(producto.cantidad),
        costoTotal: totales.costoTotal + parseFloat(producto.costoUnitario) * parseFloat(producto.cantidad),
        subTotal: (parseFloat(totales.subTotal) + parseFloat(subtotal)).toFixed(2),
        total: (parseFloat(totales.total) + parseFloat(subtotal)).toFixed(2),
      };
      setTotales(newTotales);
      setProducto({ ...defaultProducto });
    } else {
      showAlert({
        message: "Todos los campos son obligatorios",
        severity: "warning",
      });
    }
  };

  const guardar = async (body) => {
    let title = "";
    editing
      ? (title = "¿Deseas Editar esta orden de compra?")
      : (title = "¿Deseas Guardar esta orden de compra?");
    const res = await Swal.fire({
      title: title,
      text: "Favor de corroborar sus datos antes de continuar.",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Continuar!",
      cancelButtonText: "Cancelar",
    });
    if (res.isConfirmed) {
      showAlert({
        message: "Guardando orden de compra",
        severity: "warning",
      });
      updateModel(body, true, "ordenCompra");
    }
  };

  const getFirma = async (tipo) => {
    if (tipo === "elabora") {
      if (user?.urlFirma) {
        return user.urlFirma;
        // let firmaElaborador = "";
        // let ranm = Math.floor(Math.random() * 1000);
        // const pathFirebase1 = "OrdenCompra/Firma_ELABORA_" + ranm;
        // let resp = await fetch(firma).then((res) => res.blob());
        // firmaElaborador = await FirebaseService.uploadFile(pathFirebase1, resp);
        // return firmaElaborador;
      }
    } else {
      if (user?.urlFirma) {
        return user.urlFirma;
      }
    }
  };

  const onGuardar = async () => {
    if (editing) {
      let firma = await getFirma("autoriza");
      await guardar({
        idOrdenCompra: id,
        status: propiedades?.status,
        nombreAutoriza: user?.nombreCompleto,
        firmaAutoriza: firma,
        puestoAutoriza: user?.puesto,
      });
    } else {
      let fechaOrden = moment(new Date()).format("YYYY.MM.DD")
      let nombre = user?.nombreCompleto.split(" ");
      let abreviatura = nombre.map((n) => n.slice(0, 1));
      let res = await Service.get("orden-compra/last");
      let newNoOrden = fechaOrden + "-" + abreviatura.join("").toUpperCase() + "-" + res?.detalle?.estatus;
      let firma = await getFirma("elabora");
      if (desglose.length > 0) {
        await guardar({
          ...propiedades,
          nombreElabora: user?.nombreCompleto,
          firmaElabora: firma,
          noOrden: newNoOrden,
          ...totales,
          idSucursal: idSucursal,
          desgloseOrdenCompra: desglose,
        });
      } else {
        showAlert({
          message: "Debe agregar por lo menos 1 artículo.",
          severity: "warning",
        });
      }
     
    }
  };
  //----------------------------------------------------------------------------------------------------------------------
  //Efectos Callbacks
  useEffect(async () => {
    let fechaOrden = moment(new Date()).format("YYYY.MM.DD")
    let nombre = user?.nombreCompleto.split(" ");
    let abreviatura = nombre.map((n) => n.slice(0, 1));
    let res = await Service.get("orden-compra/last");
    let newNoOrden = fechaOrden + "-" + abreviatura.join("").toUpperCase() + "-" + res?.detalle?.estatus;
    setPropiedades((prop) => ({
      ...prop,
      noOrden: newNoOrden,
    }));
  }, [user?.nombreCompleto]);

  useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setPropiedades(model);
      if (editing) {
        setFirma(model?.firmaElabora);
      }
      if (user?.urlFirma) {
        setFirma2(user.urlFirma);
      }
      setProveedor(model?.idProveedor);
      setDepartamento(model?.idDepartamento);
      setDesglose(model?.desglose);
    }
    return () => {
      mounted = false;
      setPropiedades({
        proyecto: "",
        fecha: "",
        noOrden: "",
        rfc: "",
        fechaConclusion: "",
        status: "",
        subTotal: "",
        total: 0,
        firmaElabora: "",
        firmaAutoriza: "",
        nombreElabora: "",
        nombreAutoriza: "",
        puestoElabora: "",
        puestoAutoriza: "",
        justificacionCompra: "",
        fechaAutorizacion: "",
        moneda: "",
        tipoCompra: "",
        modoPago: "",
      });
    }
  }, [model]);

  useEffect(() => {
    if (desglose) {
      let ct = desglose.reduce((acc, cur) => acc + Number(cur.costoUnitario) * Number(cur.cantidad), 0);
      setTotales({
        cantidadTotal: desglose.reduce((acc, cur) => acc + Number(cur.cantidad), 0),
        subTotal: (ct).toFixed(2),
        total: ct,
      });
    }
  }, [desglose]);

  useEffect(() => {
    if (Object.keys(modelError)?.length > 0 && !updateModelLoading) {
      Object.keys(modelError).forEach(
        error => {
          showAlert({ message: modelError[error], severity: "warning" })
        }
      )
    }
  }, [modelError])

  const setProveedorCallback = useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setProveedor(v);
        setPropiedades((props) => ({
          ...props,
          idProveedor: v.idProveedor,
          rfc: v.rfc,
        }));
      } else {
        setProveedor(null);
      }
    },
    [setProveedor]
  );

  const setDepartamentoCallback = useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setDepartamento(v);
        setPropiedades((props) => ({
          ...props,
          idDepartamento: v.idDepartamento,
        }));
      } else {
        setDepartamento(null);
      }
    },
    [setDepartamento]
  );

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title={(!editing ? "Nueva" : "Editar") + " Orden de Pago"}
        links={[
          {
            to: "/ordenCompra",
            icon: <BiPurchaseTagAlt fontSize="medium" />,
            label: "Orden de Pago",
          },
          {
            to: !editing ? "/ordenCompra/nuevo" : "/ordenCompra/editar/" + id,
            icon: !editing ? (
              <Add fontSize="medium" />
            ) : (
              <Edit fontSize="medium" />
            ),
            label: !editing
              ? "Nuevo"
              : "Editar - " +
              capitalizeFirst(propiedades?.noOrden?.toLocaleUpperCase()),
          },
        ]}
        editing={editing}
        dialog={dialog}
      />
      <Grid component={Paper} container className={classes.paper} spacing={1} style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <div>
            <strong>{"Datos de la Orden de compra"}</strong>
          </div>
          <Divider />
        </Grid>

        {!editing && (
          <>
            <Grid item xs={12} md={4}>
              <AsyncAutocompleteSearchRework
                label="Nombre del proveedor"
                name="proveedores"
                variant="outlined"
                labelProp="nombreProveedor"
                extraParams={proveedorParamsMemo}
                onChange={setProveedorCallback}
                campoError="idProveedor"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputRegex
                label="R.F.C."
                variant="outlined"
                fullWidth
                value={proveedor?.rfc}
                helperText={modelError?.rfc}
                error={Boolean(modelError?.rfc)}
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <AsyncAutocompleteSearchRework
                label="Nombre del departamento"
                variant="outlined"
                name="departamentos"
                labelProp="nombreDepartamento"
                extraParams={departamentoParamsMemo}
                onChange={setDepartamentoCallback}
                campoError="idDepartamento"
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={4}>
          <InputRegex
            label="Proyecto"
            fullWidth
            variant="outlined"
            value={propiedades?.proyecto}
            helperText={modelError?.proyecto}
            error={Boolean(modelError?.proyecto)}
            disabled={editing ? true : false}
            size="small"
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                proyecto: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputRegex
            label="Fecha"
            variant="outlined"
            fullWidth
            value={propiedades?.fecha}
            helperText={modelError?.fecha}
            error={Boolean(modelError?.fecha)}
            size="small"
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputRegex
            label="Numero de orden"
            fullWidth
            variant="outlined"
            value={propiedades?.noOrden}
            helperText={modelError?.noOrden}
            error={Boolean(modelError?.noOrden)}
            size="small"
            disabled
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" fullWidth size="large">
            <InputLabel variant="outlined" id="demo-select-small">
              Status
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              id="demo-select-small"
              label="Status"
              value={propiedades.status}
              disabled={!editing}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  status: e.target.value,
                }))
              }
            >
              <MenuItem value="Proceso">Proceso</MenuItem>
              <MenuItem value="Aceptado">Aceptado</MenuItem>
              <MenuItem value="Rechazado">Rechazado</MenuItem>
              <MenuItem value="Finalizado">Finalizado</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <InputRegex
            label="Nombre de quien elabora"
            fullWidth
            variant="outlined"
            value={user.nombreCompleto}
            size="small"
            disabled
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputRegex
            label="Puesto de quien elabora"
            fullWidth
            variant="outlined"
            value={propiedades?.puestoElabora}
            helperText={modelError?.puestoElabora}
            error={Boolean(modelError?.puestoElabora)}
            size="small"
            disabled={editing ? true : false}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                puestoElabora: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      <Grid component={Paper} container className={classes.paper} spacing={1} style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <div>
            <strong>{"Desglose de la Orden de compra"}</strong>
          </div>
          <Divider />
        </Grid>

        <Grid item xs={12} md={1}>
          <InputRegex
            label="Cantidad"
            fullWidth
            variant="outlined"
            value={producto?.cantidad}
            helperText={modelError?.cantidad}
            error={Boolean(modelError?.cantidad)}
            size="small"
            typeInput="number"
            disabled={editing ? true : false}
            onChange={(e) =>
              setProducto((prevState) => ({
                ...prevState,
                cantidad: e.target.value,
              }))
            }
          />
        </Grid>

        <Grid item xs={12} md={5}>
          <TextField
            label="Descripcion del Articulo"
            fullWidth
            variant="outlined"
            value={producto?.descripcionArticulo}
            helperText={modelError?.descripcionArticulo}
            error={Boolean(modelError?.descripcionArticulo)}
            size="small"
            disabled={editing ? true : false}
            onChange={(e) =>
              setProducto((prevState) => ({
                ...prevState,
                descripcionArticulo: e.target.value,
              }))
            }
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="demo-select-small">
              Unidad de medida
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              id="demo-select-small"
              label="UnidadMedida"
              value={producto?.unidadMedida}
              disabled={editing ? true : false}
              onChange={(e) =>
                setProducto((prevState) => ({
                  ...prevState,
                  unidadMedida: e.target.value,
                }))
              }
            >
              <MenuItem value="Metro">Metro</MenuItem>
              <MenuItem value="Pieza">Pieza</MenuItem>
              <MenuItem value="Kilogramo">Kilogramo</MenuItem>
              <MenuItem value="Caja">Caja</MenuItem>
              <MenuItem value="Bolsa">Bolsa</MenuItem>
              <MenuItem value="Paquete">Paquete</MenuItem>
              <MenuItem value="Litro">Litro</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={2}>
          <InputRegex
            label="Costo Unitario"
            fullWidth
            variant="outlined"
            typeInput="currency"
            value={producto?.costoUnitario}
            helperText={modelError?.costoUnitario}
            error={Boolean(modelError?.costoUnitario)}
            size="small"
            disabled={editing ? true : false}
            onChange={(e) =>
              setProducto((prevState) => ({
                ...prevState,
                costoUnitario: e.target.value,
              }))
            }
          />
        </Grid>

        <Grid item xs={12} md={1} >
          <LoadingButton
            disabled={editing ? true : false}
            variant="contained"
            onClick={() => addProduct()}
            size="small"
            style={{
              color: "#FFF",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "8px 17px",
              gap: "8px",
            }}
          >
            <Add style={{ fontSize: "x-large" }} />
          </LoadingButton>
        </Grid>

        {desglose.length > 0 && (
          <Grid container className={classes.paper} spacing={2} style={{ marginTop: 10 }}>
            <Table stickyHeader aria-label="sticky table" style={{ borderRadius: "5px", boxShadow: "0 0 10px #00000069", marginBottom: "30px" }}>
              <TableHead sx={{ zIndex: "1", position: "relative" }}>
                <TableRowModify>
                  <TableCellModify className={ps.tableCellHead} style={{ borderTopLeftRadius: "5px" }}>
                    Cantidad
                  </TableCellModify>
                  <TableCellModify className={ps.tableCellHead}>
                    Descripcion del articulo
                  </TableCellModify>
                  <TableCellModify className={ps.tableCellHead}>
                    Unidad de medida
                  </TableCellModify>
                  <TableCellModify className={ps.tableCellHead}>
                    Costo unitario
                  </TableCellModify>
                  <TableCellModify className={ps.tableCellHead}>
                    Total
                  </TableCellModify>
                  {!editing && (
                    <TableCellModify className={ps.tableCellHead} style={{ borderTopRightRadius: "5px" }}>
                      Acción
                    </TableCellModify>
                  )}
                </TableRowModify>
              </TableHead>
              <TableBody>
                {desglose.map((prod, index) => (
                  <TableRowModify
                    key={index}
                    className={ps.tableRowBody}
                    style={{ borderBottomRightRadius: "5px", borderBottomLeftRadius: "5px" }}
                  >
                    <TableCellModify className="cellBody">
                      <InputRegex
                        label="Cantidad"
                        fullWidth
                        variant="outlined"
                        value={desglose[index].cantidad}
                        size="small"
                        typeInput="number"
                        disabled={editing ? true : false}
                        onChange={(e) =>
                          setDesglose((prevState) =>
                            prevState.map((v, i) =>
                              i === index
                                ? {
                                  ...v,
                                  cantidad: e.target.value,
                                  total: desglose[index]?.costoUnitario * desglose[index]?.cantidad,
                                }
                                : v
                            )
                          )
                        }
                      />
                    </TableCellModify>
                    <TableCellModify className="cellBody">
                      <TextField
                        label="Descripcion del Articulo"
                        fullWidth
                        disabled={editing ? true : false}
                        value={desglose[index]?.descripcionArticulo}
                        size="small"
                        onChange={(e) =>
                          setDesglose((prevState) =>
                            prevState.map((v, i) =>
                              i === index
                                ? {
                                  ...v,
                                  descripcionArticulo: e.target.value,
                                }
                                : v
                            )
                          )
                        }
                      />
                    </TableCellModify>
                    <TableCellModify className="cellBody">
                      <FormControl variant="outlined" fullWidth size="large">
                        <InputLabel variant="outlined" id="demo-select-small">
                          Unidad de medida
                        </InputLabel>
                        <Select
                          variant="outlined"
                          size="small"
                          disabled={editing ? true : false}
                          id="demo-select-small"
                          label="unidadMedida"
                          value={desglose[index]?.unidadMedida}
                          onChange={(e) =>
                            setDesglose((prevState) =>
                              prevState.map((v, i) =>
                                i === index
                                  ? {
                                    ...v,
                                    unidadMedida: e.target.value,
                                  }
                                  : v
                              )
                            )
                          }
                        >
                          <MenuItem value="Metro">Metro</MenuItem>
                          <MenuItem value="Pieza">Pieza</MenuItem>
                          <MenuItem value="Kilogramo">Kilogramo</MenuItem>
                          <MenuItem value="Caja">Caja</MenuItem>
                          <MenuItem value="Bolsa">Bolsa</MenuItem>
                          <MenuItem value="Paquete">Paquete</MenuItem>
                          <MenuItem value="Litro">Litro</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCellModify>
                    <TableCellModify className="cellBody">
                      <InputRegex
                        label="Costo Unitario"
                        fullWidth
                        variant="outlined"
                        typeInput="currency"
                        disabled={editing ? true : false}
                        value={desglose[index]?.costoUnitario}
                        size="small"
                        onChange={(e) =>
                          setDesglose((prevState) =>
                            prevState.map((v, i) =>
                              i === index
                                ? {
                                  ...v,
                                  costoUnitario: e.target.value,
                                  total: desglose[index]?.costoUnitario * desglose[index]?.cantidad,
                                }
                                : v
                            )
                          )
                        }
                      />
                    </TableCellModify>
                    <TableCellModify className="cellBody">
                      {internationalCurrency(
                        desglose[index]?.costoUnitario * desglose[index]?.cantidad,
                        2
                      )}{" "}
                      Mxn
                    </TableCellModify>

                    {!editing && (
                      <TableCellModify>
                        <Delete
                          className={ps.tableEliminar}
                          onClick={(e) => handleRemove(index, prod)}
                        />
                      </TableCellModify>
                    )}
                  </TableRowModify>
                ))}
              </TableBody>
            </Table>
          </Grid>
        )}
      </Grid>

      {desglose.length > 0 && (
        <Grid component={Paper} container className={classes.paper} spacing={1} style={{ marginTop: 10 }}>
          <Grid container className={classes.paper} spacing={2} style={{ marginTop: 10 }}
          >
            <Table stickyHeader aria-label="sticky table" style={{
                borderRadius: "5px",
                boxShadow: "0 0 10px #00000069",
                marginBottom: "30px",
              }}
            >
              <TableHead sx={{ zIndex: "1", position: "relative" }}>
                <TableRowModify>
                  <TableCellModify
                    className={ps.tableCellHead}
                    style={{ borderTopLeftRadius: "5px" }}
                  >
                    Cantidad Total
                  </TableCellModify>
                  <TableCellModify className={ps.tableCellHead}>
                    subTotal
                  </TableCellModify>
                  <TableCellModify
                    className={ps.tableCellHead}
                    style={{ borderTopRightRadius: "5px" }}
                  >
                    Total
                  </TableCellModify>
                </TableRowModify>
              </TableHead>
              <TableBody>
                <TableRowModify
                  className={ps.tableRowBody}
                  style={{
                    borderBottomRightRadius: "5px",
                    borderBottomLeftRadius: "5px",
                  }}
                >
                  <TableCellModify className="cellBody">
                    {totales.cantidadTotal}
                  </TableCellModify>
                  <TableCellModify className="cellBody">
                    {internationalCurrency(totales.subTotal)} MXN
                  </TableCellModify>
                  <TableCellModify className="cellBody">
                    {internationalCurrency(totales.total)} MXN
                  </TableCellModify>
                </TableRowModify>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      )}

      <Grid component={Paper}  container  className={classes.paper}  spacing={1}
        style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <div>
            <strong>{"Moneda y modo de pago"}</strong>
          </div>
          <Divider />
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="demo-select-small">
              Moneda
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              id="demo-select-small"
              label="Moneda"
              value={propiedades?.moneda}
              helperText={modelError?.moneda}
              error={Boolean(modelError?.moneda)}
              disabled={editing ? true : false}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  moneda: e.target.value,
                }))
              }
            >
              <MenuItem value="Peso">Pesos Mexicanos</MenuItem>
              <MenuItem value="Dollar">Dollar</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={12}>
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel variant="outlined" id="demo-select-small">
              Tipo de Pago
            </InputLabel>
            <Select
              variant="outlined"
              size="small"
              id="demo-select-small"
              label="Modo de Pago"
              value={propiedades?.modoPago}
              helperText={modelError?.modoPago}
              error={Boolean(modelError?.modoPago)}
              disabled={editing ? true : false}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  modoPago: e.target.value,
                  status: e.target.value === "Tarjeta" ? "Finalizado" : "Proceso",
                }))
              }
            >
              <MenuItem value="Tarjeta">Tarjeta</MenuItem>
              <MenuItem value="Transferencia">Transferencia</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid component={Paper} container className={classes.paper} spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} md={12}>
          <TextField
            label="Justificación de la compra"
            variant="outlined"
            fullWidth
            multiline
            rows={6}
            disabled={editing ? true : false}
            value={propiedades?.justificacionCompra}
            helperText={modelError?.justificacionCompra}
            error={Boolean(modelError?.justificacionCompra)}
            size="medium"
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                justificacionCompra: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      <Grid component={Paper} container className={classes.paper} spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Elabora Solicitud
          </Typography>
          <FirmaComponentBeta
            firma={editing ? model?.firmaElabora : user?.urlFirma}
            disabled={editing ? true : false}
            setFirma={setFirma}
            firmante={user.nombreCompleto + " - " + propiedades.puestoElabora}
          />
        </Grid>
      </Grid>

      {editing && (
        <Grid component={Paper} container className={classes.paper} spacing={1}
          style={{ marginTop: 10 }}
        >
          <Grid item xs={12}>
            <div>
              <strong>{"Datos Autoriza"}</strong>
            </div>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Puesto del que Autoriza"
              fullWidth
              disabled={!user?.puesto === ""}
              variant="outlined"
              value={user?.puesto}
              helperText={modelError?.puestoAutoriza}
              error={Boolean(modelError?.puestoAutoriza)}
              size="small"
            />

          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              Autoriza
            </Typography>
            <FirmaComponentBeta
              firma={firma2}
              setFirma={setFirma2}
              firmante={user.nombreCompleto}
            />
          </Grid>
        </Grid>
      )}

      <Grid component={Paper} container className={classes.paper} spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading ||
                  updateModelLoading ||
                  admin ||
                  (permiso && permiso[0]["ordenCompras"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={() => onGuardar()}
              size="medium"
              startIcon={<Save />}
              style={{ color: "#FFF" }}
            >
              {!editing ? "GUARDAR" : "AUTORIZAR"}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Index;