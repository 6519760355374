import React from "react";
import PropTypes from "prop-types";
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const FullScreenLoader = ({ color="primary", color2="",color3}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}   style={{backgroundColor:color2}}>
      <CircularProgress color={color} style={{color:color3}}/>
    </div>
  );
};

FullScreenLoader.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
};

export default FullScreenLoader;