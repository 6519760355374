/* eslint-disable array-callback-return */
import React, {useEffect,useState,useCallback,Fragment} from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useGet } from "./../hooks/useGet";
import { propertyAccesor } from "../utils/index";
import CircularProgress from '@mui/material/CircularProgress';

function AsyncAutocompleteSearchBeta({
  id= "combo-box-demo",
  label,
  ruta,
  publica = false,
  size = "medium",
  defaultValue = null,
  labelProp,
  onChange,
  extraParams,
  clearInput = false,
  color,
  error,
  campoError,
  index,
  ignoreIds = [],
  propId = "",
  helperText = "",
  expand = "",
  variant= "outlined",
  disabled = false,
  ...rest
}) {

  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");
  let [opcions, setOpcions] = useState([]);

 const [models, cargando, actualizar] = useGet({
      initUrl: ruta,
      params: extraParams,
      action: true,
      withOutToken: publica
    });


    useEffect(() => {
      if(cargando && models?.cuerpo || models?.resultado){
        setOpcions([]);
        let arrayOpcion = [];
       if(publica){
        models?.cuerpo?.resultado.map( (e) => {
          arrayOpcion = [...arrayOpcion,e];
        })
       }else{
        models?.resultado.map( (e) => {
          arrayOpcion = [...arrayOpcion,e];
        })
       }
        setOpcions(arrayOpcion);
    }
    }, [cargando, models]);

    useEffect(() => {
        setInput("");
    }, [clearInput]);

    useEffect(() => {
      actualizar({ urlUpdate: ruta, properties: extraParams,loading: true,  withOutToken: publica});
    }, [extraParams]);

    useEffect(() => {
      if (defaultValue) {
        setInput(propertyAccesor(defaultValue, labelProp));
      }
    }, [defaultValue, labelProp]);

  const refreshSearch = useCallback(
    async (e) => {
      let obj = { ...extraParams }
      if(e.target.value) obj = { ...obj, concepto: e.target.value } 
      actualizar({ urlUpdate: ruta, properties: obj,loading: true,  withOutToken: publica});
    },
    [actualizar,ruta,publica,extraParams]
  );

  const onKeyPressCallback = async (e) => {
   
     await refreshSearch(e);
  };

if(clearInput == false){
  return (
    <Autocomplete
        disabled={disabled}
        id={id}
        size={size}
        fullWidth
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onKeyUp={onKeyPressCallback}
        onChange={(e, v) => {
          isNaN(index) ? onChange(e, v) : onChange(index, e, v);
        }}
        defaultValue={defaultValue && defaultValue}
        getOptionLabel={(option) => {
          return propertyAccesor(option, labelProp);
        }}
        loading={cargando}
        clearOnBlur={false}
        inputValue={input}
        onInputCapture={(e) => {
          setInput(e.target.value);
          onChange();
        }}
        onInputChange={(e, r) => setInput(r)}
        noOptionsText="No hay opciones"
        loadingText="Cargando..."
        options={ignoreIds.length
          ? opcions.filter((m) => !ignoreIds.includes(m[propId]))
          : opcions || []}
        
          renderInput={(params) => (
            <TextField
              {...params}
              key="text"
              size={size}
              color={color}
              disabled={disabled}
              label={label}
              variant={variant}
              error={error}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {cargando ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
          {...rest}
    />
);
}else{
    return (
      <>
      </>
  );
}

}

export default AsyncAutocompleteSearchBeta;
