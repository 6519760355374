import React from "react";
import { emptyRequest, getRequest, deleteRequest, enableRequest } from "../constants/request";
import { useHttp } from "./useHttp";

const empty = emptyRequest();

export function useModels({
  name,
  fields = null,
  expand = null,
  ordenar = null,
  limite = null,
  pagina = null,
  extraParams = null,
}) {
  const [params, setParams] = React.useState({});
  const [modelRequest, setModelsRequest] = React.useState(empty);
  const [modelsPage, setModelsPage] = React.useState(null);
  const [models, modelsLoading, modelsError, refreshModels] = useHttp(
    modelRequest
  );

  const [delRequest, setDelRequest] = React.useState(empty);
  const [deleteResult, deleteResultLoading] = useHttp(delRequest);
  const deleteModel = React.useCallback(
    async (id, type, params) => {
      if (!deleteResultLoading) {
        let req = {};
        if(type === "Desabilitar"){
          req = deleteRequest(name, id);
          req.alert = true;
        }else{
          req = enableRequest(name, id);
          req.alert = true;
        }
        setDelRequest(req);
        setParams(params);
      }
    },
    [name, deleteResultLoading, setParams]
  );

  React.useEffect(() => {
    if (!name) {
      setModelsRequest(empty);
      return;
    }
    let params = {};
    if (fields) params = { ...params, fields };
    if (expand) params = { ...params, expand };
    if (ordenar) params = { ...params, ordenar };
    if (limite) params = { ...params, limite };
    if (pagina > 0) params = { ...params, pagina };
    if (extraParams) params = { ...params, ...extraParams };
    const modelReq = getRequest(name, params);
    setModelsRequest(modelReq);

  }, [name, fields, expand, ordenar, limite, pagina, extraParams]);

  React.useEffect(() => {
    if (!modelsLoading && !modelsError && models) {
      const { paginacion } = models;
      setModelsPage(paginacion);
    }
  }, [models, modelsLoading, modelsError]);

  React.useEffect(() => {
    if (!deleteResultLoading && deleteResult) {
      refreshModels({isCargando: true, params});
    }
  }, [deleteResult, deleteResultLoading, refreshModels, params]);

  return React.useMemo(() => {
    let resultado = [];
    if (models && models.resultado && models.resultado.length > 0) {
      resultado = [...models.resultado];
    }
    let modelsLoadingFinal = modelsLoading || deleteResultLoading;
    return [
      resultado,
      modelsLoadingFinal,
      modelsError,
      modelsPage,
      refreshModels,
      deleteModel,
    ];
  }, [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteResultLoading,
    deleteModel,
  ]);
}
