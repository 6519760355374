//React
import React, { useState, useEffect, useMemo } from "react";
import { useAlert } from "../../hooks/useAlert";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import { capitalizeFirst } from "../../utils";
import { useModels } from "../../hooks/useModels";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import { Grid, Paper, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loading from "../../components/FullScreenLoader";
import { useModel } from "../../hooks/useModel";
import PageHeader from "../../components/PageHeader";
import InputRegex from "../../components/InputRegex";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import DatePicker from "../../components/DatePickerBeta";
//----------------------------------------------------------------------------------------------------------------------

//Iconos
import SaveIcon from "@mui/icons-material/Save";
import { Add, Edit } from "@mui/icons-material";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import AsyncAutocompleteSearchRework from "../../components/AsyncAutocompleteSearchRework";
import { useCallback } from "react";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
//----------------------------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

function Detail({ match, history, dialog }) {
  const classes = useStyles();
  const { showAlert } = useAlert();
  const id = match.params.id;
  const editing = !!id;
  const [admin] = useLocalStorage("admin", null);
  const { permiso } = useAuth();
  const [idSucursal, setIdSucursal] = useLocalStorage("idSucursal", "");
  const [sucursal, setSucursal] = useState(null);
  const [equipo, setEquipo] = useState(null);
  const [propiedades, setPropiedades] = useState({
    ...{
      folio: "",
      fecha: moment(new Date()).format("yyyy/MM/DD"),
      equipo: "",
      modelo: "",
      tramo: "",
      hora: "",
      precio: "",
      importe: "",
      trabajo: "",
      noEconomico: "",
    }
  });

  const equipoParamsMemo = useMemo(
    () =>
      editing
        ? {
          pagina: 1,
          limite: 10,
          ordenar: "creado.desc",
          idEquipo: equipo,
        }
        : { pagina: 1, limite: 10, ordenar: "creado.desc" },
    [editing, equipo]
  );

  const sucursalParamsMemo = useMemo(() => (
    editing
      ? { pagina: 1, limite: 10, ordenar: "nombreSucursal.desc", idSucursal: sucursal }
      : { pagina: 1, limite: 10, ordenar: "nombreSucursal.desc" }
  ), [editing, sucursal]);

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "reporte-maquinaria",
      id,
      redirectOnPost: true,
    });

  const onGuardar = async () => {
    let title = "";
    let body = propiedades;
    let nombreSucursal = `${body.nombreSucursal}`;
    if (editing) {
      body.idReporteMaquinaria = id;
    } else {
      editing
        ? (title = "¿Deseas Editar este candidato?")
        : (title = "¿Deseas Guardar este reporte de maquinaria?");
      const res = await Swal.fire({
        title: title,
        text: "Favor de corroborar sus datos antes de continuar.",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Continuar!",
        cancelButtonText: "Cancelar",
      });
      if (res.isConfirmed) {
        showAlert({
          message: "Guardando reporte de maquinaria",
          severity: "warning",
        });
        body = { ...body, nombreSucursal: nombreSucursal };
        updateModel(body, true, "reporteMaquinarias");
      }
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setPropiedades(model);
    }
    return () => {
      mounted = false;
      setPropiedades({
        folio: "",
        fecha: moment(new Date()).format("yyyy/MM/DD"),
        equipo: "",
        modelo: "",
        tramo: "",
        hora: "",
        precio: "",
        importe: "",
        trabajo: "",
        noEconomico: "",
      });
    };
  }, [model]);

  const setEquipoCallback = useCallback(
    (e, v) => {
      if (v !== "" && v) {
        console.log(propiedades);
        setEquipo(v);
        setPropiedades({
          ...propiedades,
          importe: propiedades.hora * v.precio,
          idEquipo: v.idEquipo,
          modelo: v.modelo,
          noEconomico: v.noEconomico,
          precio: v.precio
        });
      } else {
        setEquipo(null);
      }
    },
    [propiedades]
  );

  const setSucursalCallback = useCallback((e, v) => {
    if (v !== "" && v) {
      setSucursal(v);
      setPropiedades((props) => ({ ...props, idSucursal: v.idSucursal }));
    } else {
      setSucursal(null);
    }
  }, [setSucursal]);

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title={(!editing ? "Nuevo" : "Editar") + " Reporte Maquinaria"}
        links={[
          {
            to: "/reporteMaquinarias",
            icon: <ContentPasteIcon />,
            label: "Reporte maquinaria",
          },
          {
            to: !editing
              ? "/reporteMaquinarias/nuevo"
              : "/reporteMaquinarias/editar/" + id,
            icon: !editing ? <Add /> : <Edit />,
            label: !editing
              ? "Nuevo"
              : "Editar - " + capitalizeFirst(propiedades?.folio()),
          },
        ]}
        editing={editing}
        dialog={dialog}
      />
      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <strong>{"INFORMACIÓN DEL REPORTE DE MAQUINARIA"}</strong>
          <Divider />
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <DatePicker
            labelText="Fecha"
            fullWidth
            inputFormat="dd/MM/yyyy"
            value={propiedades.fecha}
            size="small"
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                fecha: moment(e).format("yyyy/MM/DD"),
              }))
            }
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            label="Folio"
            fullWidth
            variant="outlined"
            size="small"
            typeInput="text"
            maxLength={50}
            value={propiedades.folio}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                folio: e.target.value,
              }))
            }
            helperText={modelError.folio}
            error={Boolean(modelError.folio)}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <AsyncAutocompleteSearchRework
            label="Equipo"
            name="equipo"
            variant="outlined"
            labelProp="nombreEquipo"
            extraParams={equipoParamsMemo}
            onChange={setEquipoCallback}
            campoError="idEquipo"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            label="Modelo"
            fullWidth
            disabled
            variant="outlined"
            size="small"
            typeInput="text"
            maxLength={50}
            value={propiedades.modelo}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                modelo: e.target.value,
              }))
            }
            helperText={modelError.modelo}
            error={Boolean(modelError.modelo)}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            label="No. Economico"
            fullWidth
            variant="outlined"
            disabled
            size="small"
            typeInput="text"
            maxLength={50}
            value={propiedades.noEconomico}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                noEconomico: e.target.value,
              }))
            }
            helperText={modelError.noEconomico}
            error={Boolean(modelError.noEconomico)}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            label="Tramo"
            fullWidth
            variant="outlined"
            size="small"
            typeInput="text"
            maxLength={50}
            value={propiedades.tramo}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                tramo: e.target.value,
              }))
            }
            helperText={modelError.tramo}
            error={Boolean(modelError.tramo)}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InputRegex
            label="Hora"
            fullWidth
            variant="outlined"
            size="small"
            type="decimal"
            typeInput="text"
            value={propiedades.hora}
            onChange={(e) => {
              setPropiedades({
                ...propiedades,
                hora: e.target.value,
                importe: (e.target.value * propiedades.precio).toFixed(2),
              });
            }
            }
            helperText={modelError.hora}
            error={Boolean(modelError.hora)}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InputRegex
            label="Precio"
            fullWidth
            variant="outlined"
            disabled
            size="small"
            typeInput="currency"
            value={propiedades.precio}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                precio: e.target.value,
              }))
            }
            helperText={modelError.precio}
            error={Boolean(modelError.precio)}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InputRegex
            label="Importe"
            fullWidth
            variant="outlined"
            size="small"
            typeInput="currency"
            disabled
            value={propiedades.importe}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                importe: e.target.value,
              }))
            }
            helperText={modelError.importe}
            error={Boolean(modelError.importe)}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            label="Trabajo realizado"
            fullWidth
            variant="outlined"
            size="small"
            typeInput="text"
            value={propiedades.trabajo}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                trabajo: e.target.value,
              }))
            }
            helperText={modelError.trabajo}
            error={Boolean(modelError.trabajo)}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <AsyncAutocompleteSearchRework
            defaultValue={sucursal}
            label="Nombre del proyecto"
            name="sucursal"
            variant="outlined"
            size="small"
            labelProp="nombreSucursal"
            extraParams={sucursalParamsMemo}
            onChange={setSucursalCallback}
            campoError="idSucursal"
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <LoadingButton
              disabled={
                modelLoading ||
                  updateModelLoading ||
                  admin ||
                  (permiso && permiso[0]["reporteMaquinaria"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={onGuardar}
              size="medium"
              startIcon={<SaveIcon />}
              style={{ color: "#fff" }}
            >
              {!editing ? "GUARDAR" : "MODIFICAR"}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Detail;
