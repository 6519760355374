//React
import React, { useState, useEffect } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import { capitalizeFirst } from "../../utils";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import {
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loading from "../../components/FullScreenLoader";
import { useModel } from "../../hooks/useModel";
import PageHeader from "../../components/PageHeader";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";

//----------------------------------------------------------------------------------------------------------------------

//Iconos
import SaveIcon from "@mui/icons-material/Save";
import { Add, Edit } from "@mui/icons-material";
import AgricultureIcon from '@mui/icons-material/Agriculture';
//----------------------------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

function Detail({ match, history, dialog }) {
  const classes = useStyles();
  const id = match.params.id;
  const editing = !!id;
  const [admin] = useLocalStorage("admin", null);
  const { permiso } = useAuth();
  const [propiedades, setPropiedades] = useState({
    nombreEquipo: "",
    modelo: "",
    noEconomico: "",
    precio: "",
  });

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "equipo",
      expand: "",
      id,
      redirectOnPost: true,
    });

  const onGuardar = async () => {
    let body = { ...propiedades };
    if (editing) {
      body.idEquipo = id;
    }
    updateModel(body, true, "equipos");
  };

  const currencyFormat = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });

  useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      setPropiedades(model);
    }
    return () => {
      mounted = false;
      setPropiedades({
        nombreEquipo: "",
        modelo: "",
        noEconomico: "",
        precio: ""
      });
    };
  }, [model]);

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title={(!editing ? "Nuevo" : "Editar") + " Equipo"}
        links={[
          {
            to: "/equipos",
            icon: <AgricultureIcon />,
            label: "Equipos",
          },
          {
            to: !editing ? "/equipos/nuevo" : "/equipos/editar/" + id,
            icon: !editing ? <Add /> : <Edit />,
            label: !editing
              ? "Nuevo"
              : "Editar - " +
              capitalizeFirst(
                propiedades?.nombreEquipo.toLocaleLowerCase()
              ),
          },
        ]}
        editing={editing}
        dialog={dialog}
      />
      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <strong>{"INFORMACIÓN DE LOS EQUIPOS"}</strong>
          <Divider />
        </Grid>

        <Grid item xs={12} md={4} style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}>
          <TextField
            label="Nombre del equipo"
            fullWidth
            variant="outlined"
            size="small"
            typeInput="text"
            maxLength={50}
            value={propiedades.nombreEquipo}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                nombreEquipo: e.target.value,
              }))
            }
            helperText={modelError.nombreEquipo}
            error={Boolean(modelError.nombreEquipo)}
          />
        </Grid>

        <Grid item xs={12} md={4} style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <TextField
            label="Modelo"
            fullWidth
            variant="outlined"
            size="small"
            typeInput="text"
            maxLength={50}
            value={propiedades.modelo}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                modelo: e.target.value,
              }))
            }
            helperText={modelError.modelo}
            error={Boolean(modelError.modelo)}
          />
        </Grid>

        <Grid item xs={12} md={4} style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}>
          <TextField
            label="No. Economico"
            fullWidth
            variant="outlined"
            size="small"
            typeInput="text"
            maxLength={50}
            value={propiedades.noEconomico}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                noEconomico: e.target.value,
              }))
            }
            helperText={modelError.noEconomico}
            error={Boolean(modelError.noEconomico)}
          />
        </Grid>

        <Grid item xs={12} md={4} style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <InputRegex
            label="Precio"
            fullWidth
            variant="outlined"
            size="small"
            typeInput="currency"
            maxLength={50}
            value={propiedades?.precio}
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                precio: e.target.value,
              }))
            }
            helperText={modelError.precio}
            error={Boolean(modelError.precio)}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "8px",
            gap: "8px",
          }}
        >
          <Grid container justifyContent="center">
            <LoadingButton
              disabled={
                modelLoading ||
                  updateModelLoading ||
                  admin ||
                  (permiso && permiso[0]["equipos"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={onGuardar}
              size="medium"
              startIcon={<SaveIcon />}
              style={{ color: "#fff" }}
            >
              {!editing ? "GUARDAR" : "MODIFICAR"}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Detail;
