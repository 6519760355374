import firebase from 'firebase/app';

class FirebaseService {
  async getCollection(collection = "") {
    return await firebase.firestore().collection(collection).get();
  }

  async getCollectionWhere(collection = "", where = "", codintion = "", value = "") {
    return await firebase.firestore().collection(collection).where(where, codintion, value).get();
  }

  async getDocById(collection = "", id = "") {
    return await firebase.firestore().collection(collection).doc(id).get();
  }

  async createDoc(collection = "", data = {}) {
    return await firebase.firestore().collection(collection).doc().set(data);
  }

  async deleteFile(path, fileName) {
    const ref = firebase.storage().ref(path);
    const childRef = ref.child(fileName);
    childRef.delete()
  }

  async uploadFile(path = "", _file) {
    try {
      const file = await firebase.storage().ref().child(path).put(_file);
      const fileUrl = await file.ref.getDownloadURL();
      
      return fileUrl.toString();
    } catch (error) {
      console.log(error);
      return 'error';
    }
  }

}

export default new FirebaseService();