import { withStyles, makeStyles } from "@mui/styles";
import { emphasize } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles'
import moment from "moment-timezone";

const facturaTheme = createTheme({
  palette: {
    primary: {
      main: '#1c5e7b'
    }
  },
});

const getWeeksOfMonth = dateOfMonth => {
  
  let month = new Date(dateOfMonth)
  let fechaInicio = moment(month).startOf('month')
  let numeroDeDias = moment(month).daysInMonth()

  let semanas = []
  let semana = []

  for (let index = 0; index < numeroDeDias; index++) {
    let fechaRalativa = moment(fechaInicio).add(index, 'days');
    if (fechaRalativa.day() === 0) {
      semana.push(fechaRalativa.format("YYYY-MM-DD"))
      semanas.push(semana)
      semana = []
    } else {
      semana.push(fechaRalativa.format("YYYY-MM-DD"))
      if (index == (numeroDeDias - 1)) {
        semanas.push(semana)
      }
    }
  }

  return semanas
}

const capitalizeFirst = (string) => {
  const split = string.split("-");
  let palabraUnida = "";
  split.forEach((s) => {
    palabraUnida = palabraUnida + s.charAt(0).toUpperCase() + s.slice(1);
  });
  return palabraUnida;
};
const internationalCurrency = val => {
  const internationalNumberFormat = new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    minimumFractionDigits: 2,
  });

  return internationalNumberFormat.format(val);
}
const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validateRfc = (rfcString) => {
  let re =
    /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/i;
  return re.test(String(rfcString).toLowerCase());
};

const validateName = (name) => {
  let re = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
  return re.test(name);
};

const validateUser = (user) => {
  let re = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/;
  return re.test(user);
};

const validatePassword = (passwd) => {
  let re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  return re.test(passwd);
};

const validateConfirmPassword = (passwd, passwd2) => {
  if (passwd2 !== passwd) return false;
  else return true;
};

const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumberString;
};

const propertyAccesor = (rootObj, accesor = "") => {
  const properties = accesor.split(".");
  let tmp = rootObj;
  properties.forEach((prop) => (tmp = tmp[prop]));
  return tmp;
};

const formatCurrency = (valor, currency) => {
  if (valor) {
    const numberFormat = (parseFloat(valor)).toFixed(currency).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return numberFormat;
  }
}

const formatNumber = (valor, currency) => {
  let options = { minimumFractionDigits: 2 };
  if (currency) options = { ...options, style: 'currency', currency: 'NZD' };
  let numberFormat = new Intl.NumberFormat('en-NZ', options);
  return numberFormat.format(valor);
}

const getPercentage = (firstNum, secondNum) => {
  if (isNaN(firstNum) || isNaN(secondNum)) return "0.00%";
  let result = (firstNum / secondNum) * 100;
  if (isNaN(result)) return "0.00%";
  return `${result.toFixed(2)}%`;
};

const baseStyles = () => {
  const styles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      width: "90%",
      marginTop: "20px",
    },
    typographyModified: {
      padding: theme.spacing(2),
      margin: 'auto',
      width: "90%",
      marginTop: "20px",
      textAlign: "center"
    },
    paginationModified: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    loadingTable: {
      margin: "25px 0px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    fab: {
      margin: theme.spacing(2),
    },
    loadingButton: {
      marginRight: 13,
      marginLeft: 13,
      color: "white"
    },
    paperTable: {
      margin: 'auto',
      width: "90%",
      display: "grid",
      marginTop: "20px"
    },
    successButton: {
      backgroundColor: "#e58526",
      color: "#DAD083",
      '&:hover': {
        background: "#e58526",
      },
    },
    buttonCamara: {
      backgroundColor: "#303F9F",
      color: "white",
      '&:hover': {
        background: "#303F9F",
      },
    },
    btnPdf: {
      color: '#AA0A00 !important',
      '&:hover': {
        color: '#710600 !important',
      }
    },
    btnExcel: {
      color: '#026C38 !important',
      '&:hover': {
        color: '#014825 !important',
      }
    },
  }));

  return styles;
};

// <---------------------------ESTILO FACTURAS--------------------------->
const facturaStyles = () => {
  const styles = makeStyles((theme) => ({

    papper: {
      padding: theme.spacing(2),
      margin: 'auto',
      width: "90%",
      marginTop: "20px",
      boxShadow: '0 0 20px #4645452b, 0 0 10px #00000021 !important',
      '& h4': {
        fontSize: '2.2rem',
        fontWeight: '600',
        textAlign: 'center'
      }
    },
    solicitarFactura: {
      '& img': {
        width: '20%',
        margin: '1rem'
      },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    solicitarFacturaContainer: {
      width: '100%',
      '& div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& div': {
          '& h4': {
            fontSize: '2.2rem',
            fontWeight: '600'
          },
          '& h5': {
            fontSize: '1.2rem',
            fontWeight: '300'
          }
        }
      }
    },
    datosGenerales: {
      '& .campo': {
        '& h6': {
          fontWeight: '600',
          margin: '5px !important'
        },
        '& hr': {
          backgroundColor: '#DAD083 !important',
          marginBottom: '10px !important'
        }
      }
    },
    touchApp: {
      fontSize: '2.2rem !important'
    },
    datosFactura: {
      '& .campo': {
        '& h6': {
          fontWeight: '600',
          margin: '5px !important'
        },
        '& hr': {
          backgroundColor: '#DAD083 !important',
          marginBottom: '10px !important'
        }
      }
    },
    tablaFactura: {
      boxShadow: '0 0 10px #00000045 !important',
      '& table': {
        '& thead': {
          background: '#25607a',
          '& tr': {
            '& th': {
              color: '#FFF'
            }
          }
        },
        '& tbody': {
          '& tr': {
            borderBottom: '2px solid #DAD083',
            marginBottom: '5px',
            '& td': {
              padding: '5px !important',
              '& div': {
                '& div': {
                  '& input': {
                    padding: '5px 14px !important'
                  }
                }
              }
            },
            '& th': {
              padding: '5px !important',
              '& h4': {
                fontSize: '1rem !important',
                margin: '0 !important',
                fontWeight: '300 !important'
              }
            }
          }
        }
      }
    }
  }));
  return styles;
};
// <---------------------------END ESTILO FACTURAS--------------------------->

// <---------------------------ESTILO NAVBAR PUBLICO--------------------------->
const navPublicoStyle = () => {
  const styles = makeStyles((theme) => ({
    navPublico: {
      backgroundColor: '#FFF !important',
      borderBottom: '3px solid #DAD083',
      boxShadow: '0 0 20px #76767630 !important'
    },
    toolBar: {
      justifyContent: 'space-between'
    },
    logo: {
      height: '60px',
      margin: '20px 0'
    },
    buttonMenu: {
      '& .buttonMenuLink': {
        textDecoration: 'none'
      },
      '& Button': {
        background: '#1c5e7b',
        '&:hover': {
          background: '#DAD083'
        },
        '& h6': {
          margin: '0',
          fontSize: '0.7rem',
          fontWeight: '300'
        }
      }
    },
    bBox: {
      '& p': {
        color: '#1c5e7b',
        fontSize: '1.5rem',
        fontWeight: '600'
      }
    },
    '@media (max-width: 600px)': {
      toolBar: {
        justifyContent: 'center'
      },
    }
  }));
  return styles;
};
// <---------------------------END ESTILO NAVBAR PUBLICO--------------------------->

// <---------------------------ESTILO PRENAVBAR--------------------------->
const preNavPublicoStyle = () => {
  const styles = makeStyles((theme) => ({
    preNavContainer: {
      background: '#2D2D2D',
      color: '#FFF',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '4vh'
    },
    redesNav: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 'auto',
      padding: '0 20px',
      '& a': {
        color: '#FFF',
        margin: '0 10px'
      }
    },
    dirNav: {
      margin: '0 20px',
      fontSize: '14px',
      letterSpacing: '0.5px',
      fontWeight: '300',
      lineHeight: '2',
    },
    contactoNav: {
      margin: '0 10px',
      fontSize: '14px',
      letterSpacing: '0.5px',
      fontWeight: '300',
      lineHeight: '2',
    },
    '@media (max-width: 700px)': {
      preNavContainer: {
        display: 'none'
      }
    }
  }));
  return styles;
};
// <---------------------------END ESTILO PRENAVBAR--------------------------->

// <---------------------------ESTILO PERMISOS--------------------------->
const permisosStyle = () => {
  const styles = makeStyles((theme) => ({
    permisosPaperNav: {
      backgroundColor: 'transparent !important',
      position: 'initial',
      top: 0,
      left: 0,
      right: 0,
      width: 'fit-content !important',
      boxShadow: 'none !important'
    },
    btnGroup: {
      backgroundColor: 'transparent !important',
    },
    permisosPaper: {
      borderTopLeftRadius: '0 !important',
      boxShadow: 'none',
      padding: '30px',
    },
    permisosPaperBtn: {
      boxShadow: 'none',
      padding: ' 15px 30px',
      margin: '15px 0'

    },
    btnNav: {
      backgroundColor: '#d7d7d7 !important',
      width: '150px',
      borderTopLeftRadius: '20px !important',
      borderTopRightRadius: '20px !important'
    },
    btnNavActivo: {
      backgroundColor: '#FFF !important',
      width: '150px',
      borderTopLeftRadius: '20px !important',
      borderTopRightRadius: '20px !important'
    },
    accordionPermiso: {
      boxShadow: '0 0 6px #0000004a !important',
      borderBottom: '2px solid #dad083',
      borderTopRightRadius: '5px !important',
      borderTopLeftRadius: '5px !important',
      '&:before': {
        display: 'none !important'
      }
    },
    accordionSummaryPermiso: {
      background: '#2D2D2D !important',
      borderTopLeftRadius: '5px !important',
      borderTopRightRadius: '5px !important',
      color: '#FFF !important'
    },
    tableCellHead: {
      backgroundColor: '#2D2D2D !important',
      color: '#FFF !important'
    },
    tableRowBody: {
      backgroundColor: '#FFF !important',
      '& .cellBodyShow': {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#eaeaea7a',
          borderBottom: '2px solid #dad083'
        }
      }
    },
    tableEliminar: {
      '&:hover': {
        cursor: 'pointer',
        color: '#e13333 !important'
      }
    }

  }));
  return styles;
};
// <---------------------------END ESTILO PERMISOS--------------------------->

// <---------------------------ESTILO TABS--------------------------->
const tabStyle = () => {
  const styles = makeStyles((theme) => ({
    tabsPaperNav: {
      backgroundColor: 'transparent !important',
      position: 'initial',
      top: 0,
      left: 0,
      right: 0,
      width: 'fit-content !important',
      boxShadow: 'none !important'
    },
    btnGroup: {
      backgroundColor: 'transparent !important',
    },
    tabsPaper: {
      borderTopLeftRadius: '0 !important',
      boxShadow: 'none',
      padding: '30px',
    },
    btnNav: {
      backgroundColor: '#d7d7d7 !important',
      width: '150px',
      borderTopLeftRadius: '20px !important',
      borderTopRightRadius: '20px !important'
    },
    btnNavActivo: {
      backgroundColor: '#FFF !important',
      width: '150px',
      borderTopLeftRadius: '20px !important',
      borderTopRightRadius: '20px !important'
    },
  }));
  return styles;
};
// <---------------------------END ESTILO TABS--------------------------->

// <---------------------------ESTILO CAMPAÑA--------------------------->
const campanaStyle = () => {
  const styles = makeStyles((theme) => ({
    titulo: {
      fontWeight: '600',
      fontSize: '1.5rem',
      color: '#666'
    },
    divider: {
      height: '3px',
      width: '100%',
      background: '#dad083 !important',
      boxShadow: '0 3px 5px #00000026',
      marginTop: '35px !important',
      border: 'none !important'
    },
    tabsFile: {
      borderRadius: '5px',
      marginTop: '20px !important',
      marginLeft: '16px !important',
    },
    previewRow: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    previewColLeft: {
      width: '20%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& h1': {
        color: '#c6c6c6',
      }
    },
    previewColCenter: {
      width: '60%',
      '& img': {
        width: '100%',
      }
    },
    previewColRight: {
      width: '20%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }));
  return styles;
};
// <---------------------------END ESTILO CAMPAÑA--------------------------->

// <---------------------------ESTILO FILEMANAGER--------------------------->
const fileManagerStyle = () => {
  const styles = makeStyles((theme) => ({
    selectTipo: {
      height: '56px !important',
      borderTop: '2px solid #dad083 !important',
      borderBottom: '2px solid #dad083 !important',
      fontSize: '1.5rem !important',
      color: '#666 !important',
      textAlign: 'center !important',
      '& ::before': {
        borderBottom: 'none !important'
      },
      '& ::after': {
        borderBottom: 'none !important'
      },
      '& .MuiSelect-select': {
        height: '43px !important',
        fontSize: '1.5rem !important',
        color: '#666 !important',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
          background: 'whitesmoke',
        }
      }
    },
    vinculo: {
      '& ::before': {
        borderBottom: 'none !important'
      },
      '& ::after': {
        borderBottom: 'none !important'
      },
      borderBottom: '2px solid #dad083 !important',
    },
    typeImagenImage: {
      width: '100%',
      maxHeight: '400px',
      objectFit: 'contain',
    },
    typeImagenLeftSide: {
      padding: '0 !important',
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
      border: '2px solid whitesmoke',
    },
    typeImagenRightSide: {
      padding: '0 !important',
      borderTopRightRadius: '5px',
      display: 'flex',
      flexDirection: 'column !important',
      justifyContent: 'center ',
      borderBottomRightRadius: '5px',
      border: '2px solid whitesmoke',
    },
    typeImagenButton: {
      width: '100%',
      fontSize: '1.5rem !important',
      borderRadius: '0 !important',
      color: '#666 !important',
      borderBottom: '2px solid #dad083 !important',
      '&:hover': {
        background: 'whitesmoke !important',
      },
      '& span': {
        fontSize: '1.5rem !important',
      }
    },
    typeImagenButtonDisabled: {
      background: 'whitesmoke !important',
      width: '100%',
      fontSize: '1.5rem !important',
      borderRadius: '0 !important',
      color: '#666 !important',
      borderBottom: '2px solid #dad083 !important',
    },
    typeImagenUrlTextField: {
      '& input': {
        height: '45px',
      }
    },
    typeImagenUrlIcon: {
      margin: '0 10px !important',
      cursor: 'pointer !important',
      '&:hover': {
        color: '#dad083 !important',
      }
    }
  }));
  return styles;
};
// <---------------------------END ESTILO FILEMANAGER--------------------------->



// <---------------------------ESTILO HORARIO--------------------------->
const horarioStyle = () => {
  const styles = makeStyles((theme) => ({
    permisosPaperNav: {
      backgroundColor: 'transparent !important',
      position: 'initial',
      top: 0,
      left: 0,
      right: 0,
      width: 'fit-content !important',
      boxShadow: 'none !important'
    },
    btnGroup: {
      backgroundColor: 'transparent !important',
    },
    permisosPaper: {
      borderTopLeftRadius: '0 !important',
      boxShadow: 'none',
      padding: '30px',
    },
    permisosPaperBtn: {
      boxShadow: 'none',
      padding: ' 15px 30px',
      margin: '15px 0'

    },
    btnNav: {
      backgroundColor: '#d7d7d7 !important',
      width: '150px',
      borderTopLeftRadius: '20px !important',
      borderTopRightRadius: '20px !important'
    },
    btnNavActivo: {
      backgroundColor: '#FFF !important',
      width: '150px',
      borderTopLeftRadius: '20px !important',
      borderTopRightRadius: '20px !important'
    },
    accordionPermiso: {
      boxShadow: '0 0 6px #0000004a !important',
      borderBottom: '2px solid #dad083',
      borderTopRightRadius: '5px !important',
      borderTopLeftRadius: '5px !important',
      '&:before': {
        display: 'none !important'
      }
    },
    accordionSummaryPermiso: {
      background: '#0A0A0A !important',
      borderTopLeftRadius: '5px !important',
      borderTopRightRadius: '5px !important',
      color: '#FFF !important'
    },
    tableCellHead: {
      backgroundColor: '#0A0A0A !important',
      color: '#FFF !important'
    },
    tableRowBody: {
      backgroundColor: '#FFF !important',
      '& .cellBodyShow': {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#eaeaea7a',
          borderBottom: '2px solid #dad083'
        }
      }
    },
    tableEliminar: {
      '&:hover': {
        cursor: 'pointer',
        color: '#e13333 !important'
      }
    },


  }));
  return styles;
};
// <---------------------------END ESTILO HORARIO--------------------------->
// <---------------------------ESTILO HORARIO--------------------------->
const calendarStyle = () => {
  const styles = makeStyles((theme) => ({
    horaBtn: {
      background: '#FFF !important',
      margin: '2px !important',
      color: '#666 !important',
      border: '2px solid whitesmoke !important',
      '&:hover': {
        background: '#0A0A0A !important',
        color: '#FFF !important',
      }
    },

    disable: {
      background: 'whitesmoke !important',
      margin: '2px !important',
      color: '#666 !important',
      border: '2px solid whitesmoke !important',
    },

    horaBtnActive: {
      background: '#0A0A0A !important',
      margin: '2px !important',
      color: '#FFF !important',
      border: '2px solid whitesmoke !important',
    }


  }));
  return styles;
};
// <---------------------------END ESTILO HORARIO--------------------------->

// <---------------------------ESTILO PERMISOS--------------------------->
const entradasStyle = () => {
  const styles = makeStyles((theme) => ({
    tableEntradas: {
      '& thead': {
        '& tr': {
          '& th': {
            background: '#0A0A0A',
            color: '#FFF',
            fontWeight: '600'
          }
        }
      },
      '& tbody': {
        '& tr': {
          '& .totalCell': {
            background: '#e1e1e1',
            color: '#212121',
            textAlign: 'center',
            borderBottom: '2px solid whitesmoke',
            borderRight: '2px solid whitesmoke',
            borderLeft: '2px solid whitesmoke',
            fontSize: '1rem',
            fontWeight: '600'
          }
        }
      }
    },
    verMas: {
      cursor: 'pointer',
      "&:hover": {
        color: '#dad083'
      }
    }
  }));
  return styles;
};
// <---------------------------END ESTILO PERMISOS--------------------------->

// <---------------------------ESTILO Tabla Primary--------------------------->
const tablaPrimaryStyle = () => {
  const styles = makeStyles((theme) => ({
    TablePrimary: {
      '& thead': {
        '& tr': {
          '& th': {
            background: '#0A0A0A !important',
            color: '#FFF',
            fontWeight: '600'
          }
        },
      },
    }
  }));
  return styles;
};
// <---------------------------END ESTILO Tabla Primary--------------------------->
// <---------------------------ESTILO cam Primary--------------------------->
const imageCapture = () => {
  const styles = makeStyles((theme) => ({
    cam: {
      '& div': {
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .MuiFormControl-root': {
          position: 'absolute',
          top: '20px',
          zIndex: '100',
          borderRadius: '10px',
          background: 'whitesmoke',
          width: '90%',
          '& label': {
            background: 'whitesmoke',
            padding: '0 20px',
            borderRadius: '5px',
          },
          '& div': {
          },
        },
      },
    }
  }));
  return styles;
};
// <---------------------------END ESTILO cam Primary--------------------------->

const navigatorStyle = () => {
  const styles = makeStyles((theme) => ({
    acti: {
      color: "#608B9F",
      textDecoration: 'none',
    },
    actiNo: {
      color: "#154357",
      textDecoration: 'none',
    },
    whitoutScroll: {
      "& .MuiDrawer-paper": {
        "&::-webkit-scrollbar": {
          display: "none"
        }
      }
    }
  }));
  return styles;
}

const breadCrumbsStyles = () => {
  const styles = withStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.grey[100],
      height: theme.spacing(3),
      color: theme.palette.grey[800],
      fontWeight: theme.typography.fontWeightRegular,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.grey[300],
      },
      '&:active': {
        boxShadow: theme.shadows[1],
        backgroundColor: emphasize(theme.palette.grey[300], 0.12),
      },
    },
  }));

  return styles;
};

const tableCellStyles = () => {
  const styles = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      zIndex: '0 !important'
    },
    body: {
      fontSize: 14,
    },
  }));

  return styles;
};

const tableRowStyles = () => {
  const styles = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }));

  return styles;
};

const { NODE_ENV: env } = process.env;
const baseUrl =
  env === "development"
    ? "desarrollo/"
    : "produccion/";


export {
  capitalizeFirst,
  validateEmail,
  validateRfc,
  validateName,
  validateUser,
  validatePassword,
  facturaTheme,
  entradasStyle,
  validateConfirmPassword,
  calendarStyle,
  preNavPublicoStyle,
  formatPhoneNumber,
  propertyAccesor,
  navPublicoStyle,
  tabStyle,
  getPercentage,
  baseStyles,
  permisosStyle,
  navigatorStyle,
  fileManagerStyle,
  imageCapture,
  facturaStyles,
  breadCrumbsStyles,
  campanaStyle,
  tableCellStyles,
  tableRowStyles,
  formatNumber,
  baseUrl,
  getWeeksOfMonth,
  horarioStyle,
  formatCurrency,
  tablaPrimaryStyle,
  internationalCurrency
};