/* eslint-disable no-mixed-operators */
//React
import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../utils/variables";
import { baseStyles, capitalizeFirst } from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import {
  Grid,
  Menu,
  MenuItem,
  Switch,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { useModels } from "../../hooks/useModels";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
//----------------------------------------------------------------------------------------------------------------------

//Librerias
import Swal from "sweetalert2";
//----------------------------------------------------------------------------------------------------------------------

//Iconos
import IconButton from "@mui/material/IconButton";
import {
  AccessTimeFilled,
  Add,
  Delete,
  Edit,
  Search,
} from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MdPictureAsPdf } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import AgricultureIcon from '@mui/icons-material/Agriculture';
//----------------------------------------------------------------------------------------------------------------------

const useStyles = baseStyles();

const modalStyle = {
  position: "absolute",
  top: "50%",
  right: "auto",
  left: "50%",
  bottom: "auto",
  transform: "translate(-50%, -50%)",
  marginRight: "-50%",
  background: "#FFF",
  outline: "none",
  padding: "15px 0 0 0",
  borderRadius: "5px",
  boxShadow: "0 0 15px black",
};

const Index = () => {
  //Estados Iniciales
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [pagina, setPagina] = useState(1);
  const [openMenuRow, setOpenMenuRow] = useState(null);
  const [admin] = useLocalStorage("admin", null);
  const { user, permiso } = useAuth();
  //----------------------------------------------------------------------------------------------------------------------

  //Estados Iniciales Controller
  const header = [
    {
      prop: "nombreEquipo",
      name: "Nombre del equipo",
    },
    {
      prop: "modelo",
      name: "Modelo",
    },
    {
      prop: "noEconomico",
      name: "No. Economico",
    },
    {
      prop: "precio",
      name: "Precio",
    },
  ];

  const [propiedades, setPropiedades] = useState({
    nombreEquipo: "",
    modelo: "",
    noEconomico: "",
    precio: "",
    eliminado: 0,
  });

  const parametrosInicialesMemo = useMemo(
    () => ({
      name: "equipo",
      ordenar: "creado.desc",
    }),
    []
  );
  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({ ...parametrosInicialesMemo });
  //----------------------------------------------------------------------------------------------------------------------

  //Funciones

  const onSearchClicked = async (eliminado) => {
    let params = {};
    if (eliminado != null) {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
      };
    } else {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage },
      };
    }
    await refreshModels({ isCargando: true, params });
  };

  const changePage = async (page) => {
    setPagina(page + 1);
    let params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ pagina: page + 1, limite: perPage },
    };
    await refreshModels({ isCargando: true, params });
  };

  const changePageRow = async (per) => {
    setPerPage(per);
    const params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ limite: per, pagina: pagina },
    };
    await refreshModels({ isCargando: true, params });
  };

  const onDeleteClicked = async (row, type) => {
    try {
      setOpenMenuRow(false);
      await Swal.fire({
        title: type,
        text: `¿Desea ${type} el dato seleccionado  "${capitalizeFirst(
          row.nombreEquipo.toLowerCase()
        )}"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34AA44",
        cancelButtonColor: "#E72C33",
        confirmButtonText: "Si, deseo Continuar.",
      }).then((result) => {
        if (result.isConfirmed) {
          let params = {};
          params = {
            ...parametrosInicialesMemo,
            ...propiedades,
            ...{ pagina: pagina, limite: perPage },
          };
          deleteModel(row.idEquipo, type, params);
        }
      });
    } catch (error) {
      console.modelsError(error);
    }
  };
  //----------------------------------------------------------------------------------------------------------------------
  //Efectos
  //----------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <TablePageLayout
        title="Equipos"
        model="equipo"
        history={history}
        actionButton={{
          to: "/equipos/nuevo",
          icon: <Add />,
          label: "Agregar Equipo",
          permiso: "equipos",
        }}
        links={[{ label: "Equipo", icon: <AgricultureIcon fontSize="medium" /> }]}
        SearchComponents={
          <>
            <Grid
              item xs={12} sm={3}>
              <TextField
                label="Nombre del equipo"
                fullWidth
                variant="outlined"
                typeInput="text"
                size="small"
                maxLength={50}
                value={propiedades.nombreEquipo}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    nombreEquipo: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}>
              <TextField
                label="Modelo"
                fullWidth
                variant="outlined"
                typeInput="text"
                size="small"
                maxLength={50}
                value={propiedades.modelo}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    modelo: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={3}>
              <TextField
                label="No. Economico"
                fullWidth
                variant="outlined"
                typeInput="text"
                size="small"
                maxLength={50}
                value={propiedades.noEconomico}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    noEconomico: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <LoadingButton
                variant="contained"
                onClick={() => onSearchClicked(null)}
                loading={modelsLoading && true}
                style={{
                  float: "right",
                  color: "whitesmoke",
                }}
                startIcon={<Search />}
              >
                Buscar
              </LoadingButton>
            </Grid>
          </>
        }
        DisableComponents={
          <>
            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={modelsLoading && true}
                    onChange={(e) => {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        eliminado: e.target.checked ? 1 : 0,
                      }));
                      onSearchClicked(e.target.checked ? 1 : 0);
                    }}
                  />
                }
                label="Ver Deshabilitados"
              />
            </Grid>

            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="error"
                  aria-label="upload picture"
                  component="span"
                >
                  <a
                    className={classes.btnPdf}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}pdf/equipo?
                    ${"admin=" + (admin ? 1 : 0)}
                    ${"&uid=" + user?.idUsuario}
                    ${"&nombreEquipo=" + propiedades.nombreEquipo}
                    ${"&modelo=" + propiedades.modelo}
                    ${"&noEconomico=" + propiedades.noEconomico}
                    ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)
                      }`}
                  >
                    <MdPictureAsPdf />
                  </a>
                </IconButton>
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="warning"
                  aria-label="upload picture"
                  component="span"
                >
                  <a
                    className={classes.btnExcel}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}excel/equipo?
                    ${"admin=" + (admin ? 1 : 0)}
                    ${"&uid=" + user?.idUsuario}
                    ${"&nombreEquipo=" + propiedades.nombreEquipo}
                    ${"&modelo=" + propiedades.modelo}
                    ${"&noEconomico=" + propiedades.noEconomico}
                    ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)
                      }`}
                  >
                    <RiFileExcel2Fill />
                  </a>
                </IconButton>
              </Grid>
            </Grid>
          </>
        }
        tableLoading={modelsLoading}
        tableErrors={modelsError}
        TableComponent={
          <TablePagination
            nombrePermiso="equipos"
            permiso={permiso}
            header={header}
            data={models}
            paginatedApi
            pagina={pagina}
            paginationPageSize={perPage}
            onRowsPerPageChangeApi={changePageRow}
            changePageApi={changePage}
            count={modelsPage !== null ? modelsPage.total : 0}
            labelRowsPerPage={"Renglones por página"}
            extraRows={[
              {
                prop: "acciones",
                name: "Acciones",
                sortable: false,
                cell: (row, index) =>
                  ((permiso && (permiso[0]["equipos"] & 4) === 4) ||
                    (permiso[0]["equipos"] & 8) === 8) && (
                    <div>
                      <IconButton
                        onClick={(event) => {
                          setOpenMenuRow(index);
                          setAnchorEl(event.currentTarget);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={index === openMenuRow}
                        onClose={() => setOpenMenuRow(null)}
                      >
                        {row?.eliminado === null && (
                          <MenuItem
                            onClick={() =>
                              history.push(
                                `/equipos/editar/${row.idEquipo}`
                              )
                            }
                          >
                            <Edit style={{ paddingRight: 5 }} />
                            Editar
                          </MenuItem>
                        )}
                        {row?.eliminado === null ? (
                          <MenuItem
                            onClick={() => onDeleteClicked(row, "Desabilitar")}
                          >
                            <Delete style={{ paddingRight: 5 }} />
                            Desabilitar
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={() => onDeleteClicked(row, "Habilitar")}
                          >
                            <RestoreFromTrashIcon style={{ paddingRight: 5 }} />
                            Habilitar
                          </MenuItem>
                        )}
                      </Menu>
                    </div>
                  ),
              },
            ]}
          />
        }
      />
    </>
  );
};
export default Index;
