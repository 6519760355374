import React, {useMemo } from "react";
import logo from './logo.svg';
import './App.css';
import { CacheBusterProvider } from "./hooks/CacheBuster";
import { AlertProvider, useAlert } from "./hooks/useAlert";
import { AuthProvider, useAuth } from "./hooks/useAuth";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import FullScreenLoader from "./components/FullScreenLoader";
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import { AppProvider } from "./hooks/useApp";

const RootComponent = () => {
  const { session, sessionLoading } = useAuth();
  const { open, position, severity, message } = useAlert();
  const AlertMemo = useMemo(
    () => (
      <Snackbar open={open} anchorOrigin={position}>
        <Alert severity={severity} elevation={6} variant="filled">
          {message}
        </Alert>
      </Snackbar>
    ),
    [open, position, severity, message]
  );

  if (sessionLoading) return <FullScreenLoader/>;

  return (
    <>
     {!session ? (
          <PublicRoutes />
       ):
       ( 
          <PrivateRoutes/>
       )
      }
      {AlertMemo}
    </>
  );
};

function App() {
  return (
    <CacheBusterProvider>
      <AppProvider>
        <AlertProvider>  
          <AuthProvider>
            <RootComponent />
          </AuthProvider>
        </AlertProvider>
      </AppProvider>
    </CacheBusterProvider>
  );
}

export default App;
