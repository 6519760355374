import { makeStyles } from "@mui/styles";
import { fontStyle } from "@mui/system";

// <---------------------------ESTILO back button--------------------------->
const backButton = () => {
  const styles = makeStyles((theme) => ({
    button: {
      borderRadius: "100%",
      backgroundColor: "#CACACA",
      border: "none",
      cursor: "pointer",
      padding: "0",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        backgroundColor: "#737373",
      },
    },
    arrow: {
      color: "#F5F5F5",
    },
  }));
  return styles;
};
// <---------------------------END ESTILO back button--------------------------->

// <---------------------------ESTILO TITULOS----------------------------------->
const titulo = () => {
  const styles = makeStyles((theme) => ({
    title: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "32px",
      lineHeight: "48px",
      color: "#1E2024",
    },
  }));
  return styles;
};
// <---------------------------END ESTILO TITULOS------------------------------->

// <---------------------------ESTILO tabla major----------------------------------->
const tableMajor = () => {
  const style = makeStyles((theme) => ({
    TableMajor: {
      "& thead": {
        "& tr": {
          "& th": {
            background: "#1F1F1F !important",
            color: "#FFF",
            fontWeight: "600",
          },
        },
      },
    },
  }));
  return style;
};
// <---------------------------END ESTILO tabla major------------------------------->

// <---------------------------Estilo tabla row------------------------------------->
const tableRow = () => {
  const style = makeStyles((theme) => ({
    
  }))
}
// <---------------------------End tabla row---------------------------------------->
export { 
  backButton, 
  titulo,
  tableMajor
 };
