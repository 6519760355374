import React,{createContext,useState,useContext} from "react";
import { createTheme } from '@mui/material/styles';

const AppContext = createContext();

export function AppProvider(props) {
  const [token, setToken] = useState(null);
  
  let theme = createTheme({
    palette: {
      primary: {
        light: '#E72C33',
        main: '#2F2F2F',
        dark: '#E72C33',
      },
      primary2: {
        light: '#2F2F2F',
        main: '#E72C33',
        dark: '#2F2F2F',
      },
      secondary: {
        main:'#FFF'
      },
      error: {
        light:'#C65B55',
        main:'#AA0A00',
        dark:'#710600'
      },
      warning: {
        light:'#569D7A',
        main:'#026C38',
        dark:'#014825'
      }
    },
    typography: {
      h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5,
      },
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiPaper:{
        styleOverrides: {
          root: {
            background:'red'
          },
        },
      }
    },
    mixins: {
      toolbar: {
        minHeight: 48,
      },
    },
  });
  
  theme = {
    ...theme,
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: '#2F2F2F',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
          contained: {
            boxShadow: 'none',
            '&:active': {
              boxShadow: 'none',
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            marginLeft: theme.spacing(1),
          },
          indicator: {
            height: 3,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            backgroundColor: theme.palette.common.white,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            margin: '0 16px',
            minWidth: 0,
            padding: 0,
            [theme.breakpoints.up('md')]: {
              padding: 0,
              minWidth: 0,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: theme.spacing(1),
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            borderRadius: 4,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgb(255,255,255,0.15)',
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontSize: 14,
            fontWeight: theme.typography.fontWeightMedium,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: 'inherit',
            minWidth: 'auto',
            marginRight: theme.spacing(2),
            '& svg': {
              fontSize: 20,
            },
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            width: 32,
            height: 32,
          },
        },
      },
    },
  };

  const memData = React.useMemo(() => {
    return {
      MuiTheme: theme,
      token,
      setToken,
    };
  }, [theme, token, setToken]);

  return <AppContext.Provider value={memData} {...props} />;
}

export function useApp() {
  const context = useContext(AppContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: app context not defined.";
  }
  return context;
}