/* eslint-disable no-unused-vars */
import * as React from 'react';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import logo from '../assets/img/logominerales.png';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import {navPublicoStyle} from "../utils";
import PreNavbar from "./preNavbar";
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material';

const navPublico = navPublicoStyle();

export const Navigation = (props) => {
  const navPublicoStyle = navPublico();

  const location = useLocation().pathname;
  var text = "";
  if(location === "/factura"){
    text = "Bienvenido a tu factura electronica";
  }else{
    text = "Bienvenido, Minerales de Tarachi";
  }

  return (
    <>
      <PreNavbar/>
      <AppBar position="static" className={navPublicoStyle.navPublico}>
          <Container maxWidth="xl">
            <Toolbar className={navPublicoStyle.toolBar} disableGutters>
             <Grid container spacing={2} >
                <Grid item xs={4}>
                   <img className={navPublicoStyle.logo} src={logo} alt="Logo"/>
                </Grid>
                <Grid  className={navPublicoStyle.P} item xs={4}>
                  <p >{text}</p>
                </Grid>
                <Grid item xs={4} >
                 
                </Grid>
             </Grid>
        
            </Toolbar>
          </Container>
      </AppBar>
    </>
  
  )
}
