//React
import React, { useState, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../utils/variables";
import { baseStyles, capitalizeFirst } from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import {
  Grid,
  Menu,
  MenuItem,
  Switch,
  Avatar,
  Modal,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { useModels } from "../../hooks/useModels";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
import AsyncAutocompleteSearchRework from "../../components/AsyncAutocompleteSearchRework";
//----------------------------------------------------------------------------------------------------------------------

//Librerias
import Swal from "sweetalert2";
//----------------------------------------------------------------------------------------------------------------------

//Iconos
import {
  Add,
  Edit,
  Delete,
  Search,
  AccessTimeFilled,
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import { MdPictureAsPdf } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import moment from "moment";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import BackButton from "../../components/BackButton";
import AutocompleteDeluxe from "../../components/AutocompleteDelux";

//----------------------------------------------------------------------------------------------------------------------

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#FFF",
  outline: "none",
  padding: "15px 0 0 0",
  borderRadius: "5px",
  boxShadow: "0 0 15px black",
};

const useStyles = baseStyles();

const Index = () => {
  //Estados Iniciales
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [perPage, setPerPage] = React.useState(10);
  const [pagina, setPagina] = React.useState(1);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const [imgUsuarioModal, setImgUsuarioModal] = useState("");
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [admin] = useLocalStorage("admin", null);
  const { user, permiso } = useAuth();
  const [idSucursal] = useLocalStorage("idSucursal", null);
  const [sucursal, setSucursal] = useState(null);
  const [equipo, setEquipo] = useState(null);
  //----------------------------------------------------------------------------------------------------------------------

  //Estados Iniciales Controller

  const [propiedades, setPropiedades] = useState({
    fecha: moment(new Date()).format("YYYY-MM-DDTHH"),
    noEconomico: "",
    idEquipo: 0,
    operador: "",
    despachador: "",
    precio: "",
    importe: "",
    empresa: "",
    litros: "",
  });

  const paramsMemo = useMemo(
    () => ({
      ordenar: "nombreEquipo.asc",
    }),
    []
  );

  const parametrosInicialesMemo = useMemo(
    () => ({
      name: "diesel-movimientos",
      ordenar: "creado.desc",
      expand: "equipo",
    }),
    []
  );

  const equipoParamsMemo = useMemo(
    () => ({
      pagina: 1,
      limite: 10,
      ordenar: "creado.desc",
      idEquipo: equipo,
    }),
    [equipo]
  );

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
  ] = useModels({ ...parametrosInicialesMemo });

  //----------------------------------------------------------------------------------------------------------------------

  //Funciones

  const onSearchClicked = async (eliminado) => {
    let params = {};
    if (eliminado != null) {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
      };
    } else {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage },
      };
    }
    await refreshModels({ isCargando: true, params });
  };

  const changePage = async (page) => {
    setPagina(page + 1);
    let params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ pagina: page + 1, limite: perPage },
    };
    await refreshModels({ isCargando: true, params });
  };

  const changePageRow = async (per) => {
    setPerPage(per);
    const params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ limite: per, pagina: pagina },
    };
    await refreshModels({ isCargando: true, params });
  };

  const setEquipoCallback = useCallback(
    (e, v) => {
      if (v !== "" && v) {
        console.log(propiedades);
        setEquipo(v);
        setPropiedades({
          ...propiedades,
          idEquipo: v.idEquipo,
        });
      } else {
        setEquipo(null);
        setPropiedades("");
      }
    },
    [propiedades]
  );

  //----------------------------------------------------------------------------------------------------------------------

  //Efectos

  //----------------------------------------------------------------------------------------------------------------------
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={modalStyle}>
          <CloseIcon
            onClick={handleClose}
            style={{
              marginBottom: "15px",
              marginRight: "15px",
              display: "flex",
              marginLeft: "auto",
              cursor: "pointer",
            }}
          />
        </div>
      </Modal>
      <TablePageLayout
        title="Movimiento Diesel"
        model="dieselMivimientos"
        history={history}
        actionButton={{
          to: "/dieselMovimientos/nuevo",
          icon: <Add />,
          label: "Agregar Movimientos de Diesel",
          permiso: "dieselMovimientos",
        }}
        links={[
          {
            label: "Diesel Movimientos",
            icon: <ImportExportIcon />,
          },
        ]}
        SearchComponents={
          <>
            <Grid item xs={12} sm={3}>
              <TextField
                color="primary2"
                label="Folio"
                variant="outlined"
                size="small"
                fullWidth
                typeInput="text"
                maxLength={50}
                value={propiedades.folio}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    folio: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                color="primary2"
                label="Operador"
                variant="outlined"
                size="small"
                fullWidth
                typeInput="text"
                maxLength={50}
                value={propiedades.operador}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    operador: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                color="primary2"
                label="Despachador"
                variant="outlined"
                fullWidth
                size="small"
                typeInput="text"
                maxLength={50}
                value={propiedades.despachador}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    despachador: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <LoadingButton
                variant="contained"
                onClick={() => onSearchClicked(null)}
                loading={modelsLoading && true}
                style={{
                  float: "right",
                  color: "whitesmoke",
                }}
                startIcon={<Search />}
              >
                Buscar
              </LoadingButton>
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                color="primary2"
                label="No. Economico"
                variant="outlined"
                fullWidth
                size="small"
                typeInput="text"
                maxLength={30}
                value={propiedades.noEconomico}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    noEconomico: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <AsyncAutocompleteSearchRework
                label="Equipo"
                name="equipo"
                variant="outlined"
                labelProp="nombreEquipo"
                extraParams={equipoParamsMemo}
                onChange={setEquipoCallback}
                campoError="idEquipo"
              />
            </Grid>
          </>
        }
        DisableComponents={
          <>
            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={modelsLoading && true}
                    onChange={(e) => {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        eliminado: e.target.checked ? 1 : 0,
                      }));
                      onSearchClicked(e.target.checked ? 1 : 0);
                    }}
                  />
                }
                label="Ver Desabilitados"
              />
            </Grid>

            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="error"
                  aria-label="upload picture"
                  component="span"
                >
                  <a
                    className={classes.btnPdf}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}pdf/diesel?
                    ${"admin=" + (admin ? 1 : 0)}
                    ${"&uid=" + user?.idUsuario}
                    ${"&operador=" + propiedades.operador}
                    ${"&despachador=" + propiedades.despachador}
                    ${"&idEquipo=" + propiedades.idEquipo}
                    ${"&economico=" + propiedades.noEconomico}
                    ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)
                      }`}
                  >
                    <MdPictureAsPdf />
                  </a>
                </IconButton>
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="warning"
                  aria-label="upload picture"
                  component="span"
                >
                  <a
                    className={classes.btnExcel}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}excel/diesel?
                    ${"admin=" + (admin ? 1 : 0)}
                    ${"&uid=" + user?.idUsuario}
                    ${"&operador=" + propiedades.operador}
                    ${"&despachador=" + propiedades.despachador}
                    ${"&idEquipo=" + propiedades.idEquipo}
                    ${"&economico=" + propiedades.noEconomico}
                    ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)
                      }`}
                  >
                    <RiFileExcel2Fill />
                  </a>
                </IconButton>
              </Grid>
            </Grid>
          </>
        }
        tableLoading={modelsLoading}
        tableErrors={modelsError}
        TableComponent={
          <TablePagination
            nombrePermiso="dieselMovimientos"
            permiso={permiso}
            header={[]}
            data={models}
            paginatedApi
            pagina={pagina}
            paginationPageSize={perPage}
            onRowsPerPageChangeApi={changePageRow}
            changePageApi={changePage}
            count={modelsPage !== null ? modelsPage.total : 0}
            labelRowsPerPage={"Renglones por página"}
            extraRows={[
              {
                prop: "folio",
                name: "Folio",
                cell: (row, index) => <p key={index}>{row?.folio}</p>,
              },
              {
                prop: "fecha",
                name: "Fecha",
                cell: (row, index) => (
                  <p key={index}>{moment(row?.fecha).format("DD/MM/yyy")}</p>
                ),
              },
              {
                prop: "economico",
                name: "No. Economico",
                sortable: false,
                cell: (row, index) => <p key={index}>{row?.noEconomico}</p>,
              },
              {
                prop: "idEquipo",
                name: "Equipo",
                sortable: false,
                cell: (row, index) => <p key={index}>{row?.equipo.nombreEquipo}</p>,
              },
              {
                prop: "operador",
                name: "Operador",
                sortable: false,
                cell: (row, index) => <p key={index}>{row?.operador}</p>,
              },
              {
                prop: "despachador",
                name: "Despachador",
                sortable: false,
                cell: (row, index) => <p key={index}>{row?.despachador}</p>,
              },
              {
                prop: "litros",
                name: "Litros",
                sortable: false,
                cell: (row, index) => <p key={index}>{row?.litros}</p>,
              },
              {
                prop: "empresa",
                name: "Empresa",
                sortable: false,
                cell: (row, index) => <p key={index}>{row?.empresa}</p>,
              },
              {
                prop: "precio",
                name: "Precio",
                sortable: false,
                cell: (row, index) => <p key={index}>{row?.precio}</p>,
              },
              {
                prop: "importe",
                name: "importe",
                sortable: false,
                cell: (row, index) => <p key={index}>{row?.importe}</p>,
              },
            ]}
          />
        }
      />
    </>
  );
};
export default Index;
