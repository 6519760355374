import React, { useState, useRef } from "react";
import SignaturePad from "react-signature-canvas";
import { TbSignature } from "react-icons/tb";
import { FaFileSignature, FaEraser } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";
import { Popover, IconButton, Grid, Input } from "@mui/material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";

const FirmaComponentBeta = ({ firma, disabled, setFirma, firmante }) => {
  const [imageURL, setImageURL] = useState(null);
  const [select, setSelect] = useState("");
  const sigCanvas = useRef({});
  const clear = () => sigCanvas.current.clear();
  const save = async () => {
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    setFirma(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
  };

  return (
    <>
      {select === "" ? (
        <>
          {firma !== null &&
          firma !==
            "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/DoctoresDetalles%2Ffirma_default.png?alt=media&token=c657d017-f580-487d-b518-f79db11a76db" ? (
            <>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <img
                  src={firma}
                  alt="my signature"
                  style={{
                    width: "300px",
                    height: "150px",
                    backgroundColor: "transparent",
                    borderBottomColor: "black",
                    borderBottomStyle: "outset",
                    paddingBottom: "5px",
                  }}
                />
              </Grid>
              <p
                style={{
                  fontSize: "20px",
                  whiteSpace: "nowrap",
                  margin: 0,
                  padding: "15px 0px",
                  textAlign: "center",
                }}
              >
                {firmante}
              </p>
            </>
          ) : (
            <></>
          )}
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Grid margin={0.5}>
              <LoadingButton
                variant="contained"
                disabled={disabled}
                onClick={(e) => setSelect("firma")}
                size="medium"
                style={{ color: "#FFF" }}
              >
                FIRMAR
              </LoadingButton>
            </Grid>
            <Grid margin={0.5}>
              <LoadingButton
                variant="contained"
                disabled={disabled}
                onClick={(e) => setSelect("SeleccionarFirma")}
                size="medium"
                style={{ color: "#FFF" }}
              >
                SELECCIONAR FIRMA
              </LoadingButton>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {select === "firma" ? (
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <PopupState
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                variant="popover"
                popupId="demo-popup-popover"
              >
                {(popupState) => (
                  <div>
                    {imageURL ? (
                      <>
                        <IconButton
                          variant="contained"
                          {...bindTrigger(popupState)}
                        >
                          <img
                            src={imageURL}
                            alt="my signature"
                            style={{
                              width: "300px",
                              height: "150px",
                              backgroundColor: "transparent",
                              borderBottomColor: "black",
                              borderBottomStyle: "outset",
                              paddingBottom: "5px",
                            }}
                          />
                        </IconButton>
                        <p
                          style={{
                            fontSize: "20px",
                            whiteSpace: "nowrap",
                            margin: 0,
                            padding: "15px 0px",
                            textAlign: "center",
                          }}
                        >
                          {firmante}
                        </p>
                      </>
                    ) : (
                      <>
                        <IconButton
                          variant="contained"
                          {...bindTrigger(popupState)}
                        >
                          <img
                            style={{
                              width: "300px",
                              height: "150px",
                              objectFit: "contain",
                            }}
                            src="https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/DoctoresDetalles%2Ffirma_default.png?alt=media&token=c657d017-f580-487d-b518-f79db11a76db"
                            alt={"firma"}
                          />
                        </IconButton>
                        <p
                          style={{
                            fontSize: "20px",
                            whiteSpace: "nowrap",
                            margin: 0,
                            padding: "15px 0px",
                            textAlign: "center",
                          }}
                        >
                          {firmante}
                        </p>
                      </>
                    )}
                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{ vertical: "top", horizontal: "left" }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                    >
                      <SignaturePad
                        ref={sigCanvas}
                        canvasProps={{
                          style: {
                            border: "1px solid grey",
                            width: "100%",
                            minHeight: "300px",
                          },
                        }}
                      />
                      <Grid
                        display={"flex"}
                        justifyContent={"space-around"}
                        margin={1}
                      >
                        <LoadingButton
                          variant="contained"
                          size="medium"
                          startIcon={
                            <Save
                              style={{ fontSize: "xx-large", color: "#FFF" }}
                            />
                          }
                          style={{ color: "#FFF" }}
                          onClick={() => {
                            save();
                            popupState.close();
                          }}
                        >
                          GUARDAR
                        </LoadingButton>
                        <LoadingButton
                          variant="contained"
                          size="medium"
                          startIcon={
                            <FaEraser
                              style={{ fontSize: "xx-large", color: "#FFF" }}
                            />
                          }
                          style={{ color: "#FFF" }}
                          onClick={clear}
                        >
                          BORRAR
                        </LoadingButton>
                      </Grid>
                    </Popover>
                  </div>
                )}
              </PopupState>
              <Grid>
                <LoadingButton
                  variant="contained"
                  onClick={(e) => {
                    setSelect("");
                    setImageURL("");
                    setFirma(null);
                  }}
                  size="medium"
                  startIcon={
                    <GiCancel style={{ fontSize: "xx-large", color: "#FFF" }} />
                  }
                  style={{ color: "#FFF" }}
                >
                  CANCELAR
                </LoadingButton>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <IconButton variant="contained">
                <label htmlFor="icon-button-file">
                  <Input
                    onChange={(e) => {
                      setFirma(URL.createObjectURL(e.target.files[0]));
                    }}
                    style={{ display: "none" }}
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                  />
                  <img
                    style={{
                      width: "300px",
                      height: "150px",
                      objectFit: "contain",
                      borderBottomColor: "black",
                      borderBottomStyle: "outset",
                      paddingBottom: "5px",
                    }}
                    src={
                      firma ||
                      "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/DoctoresDetalles%2Ffirma_default.png?alt=media&token=c657d017-f580-487d-b518-f79db11a76db"
                    }
                    alt={"firma"}
                  />
                  <p
                    style={{
                      fontSize: "20px",
                      whiteSpace: "nowrap",
                      margin: 0,
                      padding: "15px 0px",
                      textAlign: "center",
                    }}
                  >
                    {firmante}
                  </p>
                </label>
              </IconButton>
              <Grid>
                <LoadingButton
                  variant="contained"
                  onClick={(e) => {
                    setSelect("");
                    setImageURL("");
                    setFirma(null);
                  }}
                  size="medium"
                  startIcon={
                    <GiCancel style={{ fontSize: "xx-large", color: "#FFF" }} />
                  }
                  style={{ color: "#FFF" }}
                >
                  CANCELAR
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default FirmaComponentBeta;
