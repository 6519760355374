import * as React from 'react';
import { TextField, Box, Container, Grid, Alert } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { obtenerErrorFirebase } from "../utils/erroresFirebase";
import { useAuth } from "../hooks/useAuth";
import acceso from "../assets/img/acceso.png";
import logo from "../assets/img/logominerales.png";
import background from "../assets/img/fondoTarachi.jpg";

export default function SignIn() {
  const { signIn, errorFirebase, loginLoading } = useAuth();
  const [correo, setCorreo] = React.useState("");
  const [clave, setClave] = React.useState("");

  const onSubmitClicked = async (event) => {
    try {
      event.preventDefault();
      window.localStorage.clear();
      await signIn(correo, clave);
    } catch (error) {
      //
    }
  };

  return (
    <Container className="login" component="main" maxWidth="xs">

      <Box
        sx={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img src={logo} style={{ height: 100 }} alt="logominerales" />

        <p><h4>Iniciar sesion</h4></p>

        <Box>
          <TextField
            margin="normal"
            fullWidth
            label="Correo Electronico"
            type="text"
            onChange={(e) => setCorreo(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Contraseña"
            type="password"
            onChange={(e) => setClave(e.target.value)}
          />
          {errorFirebase !== null && (
            <Grid container>
              <Alert variant="filled" severity="error" style={{ width: "100%" }}>
                {obtenerErrorFirebase(errorFirebase?.message)}
              </Alert>
            </Grid>
          )}
          <Grid sx={{
          marginTop: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
          <LoadingButton
            loading={loginLoading}
            variant="contained"
            onClick={onSubmitClicked}
            size="large"
            style={{
              margin: "10px 0px", backgroundColor: "#2F2F2F", width: 150
            }}
          >
            Entrar
          </LoadingButton>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}