import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import FullScreenLoader from "./FullScreenLoader";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user, userLoading,permiso } = useAuth();
  const { permisoCheck, disabledPermission } = rest;

  if (!user || user === null || userLoading || !permiso) 
    return <FullScreenLoader />;
  if (user && (!user && !permiso[permisoCheck]) && !user.admin)
    return <Redirect to="/inicio" />;
  if (!user.admin && (permiso[0][permisoCheck] & 1) !== 1)
    return <Redirect to="/inicio" />;
  if (user && disabledPermission === "/perfil")
    return <Route render={(props) => <Component {...props} />} />;
  if ((user.admin === true) || (permiso[0][permisoCheck] & 1) === 1 )
    return <Route {...rest} render={(props) => <Component {...props} />} />;

  return null;
};

PrivateRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.func,
};

export default PrivateRoute;