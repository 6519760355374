//React
import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../utils/variables";
import { baseStyles, capitalizeFirst } from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import {
  Grid,
  Menu,
  MenuItem,
  Switch,
  Avatar,
  Modal,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { useModels } from "../../hooks/useModels";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
import BackButton from "../../components/BackButton";

//----------------------------------------------------------------------------------------------------------------------

//Librerias
import Swal from "sweetalert2";
//----------------------------------------------------------------------------------------------------------------------

//Iconos
import {
  Add,
  Edit,
  Delete,
  Search,
  AccessTimeFilled,
  TextFields,
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import { MdPictureAsPdf } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import { Input } from "rsuite";
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';

//----------------------------------------------------------------------------------------------------------------------

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#FFF",
  outline: "none",
  padding: "15px 0 0 0",
  borderRadius: "5px",
  boxShadow: "0 0 15px black",
};

const useStyles = baseStyles();

const Index = () => {
  //Estados Iniciales
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [perPage, setPerPage] = React.useState(10);
  const [pagina, setPagina] = React.useState(1);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const [admin] = useLocalStorage("admin", null);
  const { user } = useAuth();
  //----------------------------------------------------------------------------------------------------------------------

  //Estados Iniciales Controller
  const header = [
    {
      prop: "nombreDepartamento",
      name: "Nombre Departamento",
    },
  ];

  const [propiedades, setPropiedades] = useState({
    nombreDepartamento: "",
    eliminado: 0,
  });

  const parametrosInicialesMemo = useMemo(
    () => ({
      name: "departamentos",
      ordenar: "creado.desc",
    }),
    []
  );

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({ ...parametrosInicialesMemo });

  //----------------------------------------------------------------------------------------------------------------------

  //Funciones

  const onSearchClicked = async (eliminado) => {
    let params = {};
    if (eliminado != null) {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage, eliminado: eliminado },
      };
    } else {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage },
      };
    }
    await refreshModels({ isCargando: true, params });
  };

  const changePage = async (page) => {
    setPagina(page + 1);
    let params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ pagina: page + 1, limite: perPage },
    };
    await refreshModels({ isCargando: true, params });
  };

  const changePageRow = async (per) => {
    setPerPage(per);
    const params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ limite: per, pagina: pagina },
    };
    await refreshModels({ isCargando: true, params });
  };

  const onDeleteClicked = async (row, type) => {
    try {
      setOpenMenuRow(false);
      await Swal.fire({
        title: type,
        text: `¿Desea ${type} el dato seleccionado  "${capitalizeFirst(
          row.nombreDepartamento.toLowerCase()
        )}"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34AA44",
        cancelButtonColor: "#E72C33",
        confirmButtonText: "Si, deseo Continuar.",
      }).then((result) => {
        if (result.isConfirmed) {
          let params = {};
          params = {
            ...parametrosInicialesMemo,
            ...propiedades,
            ...{ pagina: pagina, limite: perPage },
          };
          deleteModel(row.idDepartamento, type, params);
        }
      });
    } catch (error) {
      console.modelsError(error);
    }
  };

  //----------------------------------------------------------------------------------------------------------------------

  //Efectos

  //----------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={modalStyle}>
          <CloseIcon
            onClick={handleClose}
            style={{
              marginBottom: "15px",
              marginRight: "15px",
              display: "flex",
              marginLeft: "auto",
              cursor: "pointer",
            }}
          />
        </div>
      </Modal>

      <TablePageLayout
        title="Departamentos"
        model="departamentos"
        history={history}
        actionButton={{
          to: "/departamentos/nuevo",
          icon: <Add />,
          label: "Agregar Departamento",
        }}
        links={[
          {
            label: "Departamentos",
            icon: <HolidayVillageIcon />,
          },
        ]}
        SearchComponents={
          <>
            <Grid item xs={8} sm={4}>
              <TextField
                color="primary2"
                label="Nombre del departamento"
                typeInput="text"
                variant="outlined"
                fullWidth
                size="small"
                background="#F5F5F5"
                border="1px solid #CACACA"
                borderRadius="9px"
                padding="11px 8px 8px 17px"
                gap="8px"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
                maxLength={50}
                value={propiedades.nombreDepartamento}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    nombreDepartamento: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid xs={8} sm={4}></Grid>
            <Grid item xs={8} sm={4} style={{ display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                variant="contained"
                width="93px"
                height="39px"
                onClick={() => onSearchClicked(null)}
                loading={modelsLoading && true}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: "whitesmoke",
                  alignItems: "flex-end",
                  padding: "8px",
                  gap: "8px",
                }}
                startIcon={<Search />}
              >
                Buscar
              </LoadingButton>
            </Grid>
          </>
        }
        DisableComponents={
          <>
            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={modelsLoading && true}
                    onChange={(e) => {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        eliminado: e.target.checked ? 1 : 0,
                      }));
                      onSearchClicked(e.target.checked ? 1 : 0);
                    }}
                  />
                }
                label="Ver Desabilitados"
              />
            </Grid>

            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="error"
                  aria-label="upload picture"
                  component="span"
                >
                  <a
                    className={classes.btnPdf}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}pdf/departamentos?
                    ${"admin=" + (admin ? 1 : 0)}
                    ${"&uid=" + user?.idUsuario}
                    ${"&nombreDepartamento=" + propiedades.nombreDepartamento}
                    ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)
                      }`}
                  >
                    <MdPictureAsPdf />
                  </a>
                </IconButton>
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="warning"
                  aria-label="upload picture"
                  component="span"
                >
                  <a
                    className={classes.btnExcel}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}excel/departamentos?
                    ${"admin=" + (admin ? 1 : 0)}
                    ${"&uid=" + user?.idUsuario}
                    ${"&nombreDepartamento=" + propiedades.nombreDepartamento}
                    ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)}`}
                  >
                    <RiFileExcel2Fill />
                  </a>
                </IconButton>
              </Grid>
            </Grid>
          </>
        }
        tableLoading={modelsLoading}
        tableErrors={modelsError}
        TableComponent={
          <TablePagination
            header={header}
            data={models}
            paginatedApi
            pagina={pagina}
            paginationPageSize={perPage}
            onRowsPerPageChangeApi={changePageRow}
            changePageApi={changePage}
            count={modelsPage !== null ? modelsPage.total : 0}
            labelRowsPerPage={"Renglones por página"}
            extraRows={[
              {
                prop: "acciones",
                name: "Acciones",
                sortable: false,
                cell: (row, index) => (
                  <div>
                    <IconButton
                      onClick={(event) => {
                        setOpenMenuRow(index);
                        setAnchorEl(event.currentTarget);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={index === openMenuRow}
                      onClose={() => setOpenMenuRow(null)}
                    >
                      {row?.eliminado === null && (
                        <MenuItem
                          onClick={() =>
                            history.push(
                              `/departamentos/editar/${row.idDepartamento}`
                            )
                          }
                        >
                          <Edit style={{ paddingRight: 5 }} />
                          Editar
                        </MenuItem>
                      )}
                      {row?.eliminado === null ? (
                        <MenuItem
                          onClick={() => onDeleteClicked(row, "Desabilitar")}
                        >
                          <Delete style={{ paddingRight: 5 }} />
                          Desabilitar
                        </MenuItem>
                      ) : (
                        <MenuItem
                          onClick={() => onDeleteClicked(row, "Habilitar")}
                        >
                          <RestoreFromTrashIcon style={{ paddingRight: 5 }} />
                          Habilitar
                        </MenuItem>
                      )}
                    </Menu>
                  </div>
                ),
              },
            ]}
          />
        }
      />
    </>
  );
};
export default Index;
