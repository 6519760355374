/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-concat */
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Breadcrumbs,
  Paper,
  TextField,
  Typography,
  Chip,
} from "@mui/material";
import Divider from '@mui/material/Divider';
import { useLocalStorage } from "./../../hooks/useLocalStorage";
import Box from '@mui/material/Box';
import { Home, KeyOutlined } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import CardMedia from '@mui/material/CardMedia'
import AsyncAutocompleteSearchBeta from '../../components/AsyncAutocompleteSearchBeta';
import Button from '@mui/material/Button'
import { PhotoCamera } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAuth } from "../../hooks/useAuth";
import { useGet } from "../../hooks/useGet";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { usePost } from "../../hooks/usePost";
import Checkbox from '@mui/material/Checkbox';
import FullScreenLoader from "../../components/FullScreenLoader";
import { baseStyles, breadCrumbsStyles } from "../../utils";
import Swal from "sweetalert2";
import FirebaseService from "../../services/firebase";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FirmaComponentBeta from "../../components/FirmaComponentBeta";

const useStyles = baseStyles();
const StylesBreadCrumb = breadCrumbsStyles()(Chip);

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  color: "black",
  padding: theme.spacing(1),
  BorderStyle: "outset"
}));

const Index = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();

  const urlObtenerSucursal = "sucursal";

  const editing = match.params.idUsuario !== undefined;

  const [showPassword, setShowPassword] = useState("password");

  const [clearInputSucursal, setClearInputSucursal] = useState(false);

  let [sucursal, setSucursal] = useState(null);

  const [admin] = useLocalStorage("admin", null);

  const [showRepetirPassword, setShowRepetirPassword] = useState("password");

  const [errores, cargandoBoton, peticion, setCargando, detalles] = usePost();

  const { permiso, user } = useAuth();

  const [urlImg, setUrlImg] = useState("");

  const [archivo, setArchivo] = useState();

  const [firma, setFirma] = useState(null);

  const crearDefaultPermiso = permiso => {
    let permisosKeys = Object.keys(permiso);
    let permisoCreado = {};
    permisosKeys.map(key => {
      if (key != "idUsuario" && key != "idSucursal") {
        if (key.charAt(key.length - 1) == "s") {
          permisoCreado = { ...permisoCreado, [key]: { ver: true, agregar: false, editar: false, eliminar: false, key: key } }
        } else {
          permisoCreado = { ...permisoCreado, [key]: { val: false, key: key } }
        }
      }
    });
    return permisoCreado;
  }

  const [propiedades, setPropiedades] = useState({
    ine: "",
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    admin: false,
    correo: "",
    urlFoto: "",
    contraseña: '',
    repetirContraseña: '',
    permisos: crearDefaultPermiso(permiso[0]),
    puesto: "",
    firma: "",
  });

  const [usuario, cargando] = useGet({
    initUrl: "usuario/index",
    params: {
      idUsuario: match.params.idUsuario,
    },
    action: editing ? true : false,
  });

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    if (file !== undefined) {
      const urlFoto = URL.createObjectURL(event.target.files[0]);
      setArchivo(file);
      setPropiedades((prevState) => ({
        ...prevState,
        ...prevState.propiedades,
        urlFoto: urlFoto,
      }));
    }
  };

  const getFirma = async () => {
    if (firma) {
      let urlFirma = "";
      let ranm = Math.floor(Math.random() * 1000);
      const pathFirebase1 = "OrdenCompra/Firma_USUARIO_" + ranm;
      let resp = await fetch(firma).then((res) => res.blob());
      urlFirma = await FirebaseService.uploadFile(pathFirebase1, resp);
      return urlFirma;
    }
  }

  const guardarUsuario = async () => {
    setCargando(true);
    let urlFileUsuario = "";
    let obj = propiedades;
    if (archivo) {
      const ranm = Math.floor(Math.random() * 1000);
      const idUsuario = (obj.nombre + obj.apellidoPaterno + obj.apellidoMaterno).toLowerCase();
      const pathFirebase = "Usuarios/" + "Imagen" + "_" + idUsuario + ranm;
      setUrlImg("Imagen" + "_" + idUsuario + ranm);
      urlFileUsuario = await FirebaseService.uploadFile(pathFirebase, archivo);
      obj = { ...obj, urlFoto: urlFileUsuario };
    }
    if (!editing) {
      obj = { ...obj, permisos: formatearPermisos() };
    }
    if ((!sucursal && sucursal == null && !editing)) {
      setCargando(false);
      Swal.fire({
        icon: 'warning',
        title: 'Favor de agregar los permisos y/o roles de la sucursal al usuario',
      });
      return;
    }
    // Obtener la URL de la firma
    const urlFirma = await getFirma();

    // Agregar la URL de la firma al objeto
    obj = { ...obj, urlFirma: urlFirma };

    peticion("usuario/guardar", obj, "/usuarios");
  }

  const deleteImg = async (url) => {
    await FirebaseService.deleteFile("Usuarios/", url);
    setUrlImg("");
  }



  const sucursalParamsMemo = useMemo(
    () => ({
      pagina: 1,
      limite: 20,
      ordenar: "nombreSucursal.desc"
    }),
    [sucursal]
  );

  const setSucursalCallback = useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setSucursal(v);
      } else {
        setSucursal(null);
      }
    },
    [setSucursal]
  );

  // PERMISOS



  const formatearPermisos = () => {
    let permisosFormated = [];
    let permisoFormated = {};
    Object.keys(propiedades.permisos).map(
      key => {
        let p = propiedades.permisos[key];
        if (p.key.charAt(p.key.length - 1) == "s") {
          let v = 0;
          if (p.ver) {
            v += 1;
          }
          if (p.agregar) {
            v += 2;
          }
          if (p.editar) {
            v += 4;
          }
          if (p.eliminar) {
            v += 8;
          }
          permisoFormated = { ...permisoFormated, [key]: v }
        } else {
          let v = 0;
          if (p.val) {
            v += 16;
          }
          permisoFormated = { ...permisoFormated, [key]: v }
        }
      }
    );
    permisoFormated = { ...permisoFormated, idSucursal: sucursal.idSucursal };
    permisosFormated.push(permisoFormated);
    return permisosFormated;
  }

  // -----------------------------------------------------------
  useEffect(() => {
    if (editing) {
      let data = usuario && usuario.resultado && usuario.resultado[0];
      setPropiedades(data);
    }
  }, [usuario, editing]);


  useEffect(() => {
    if (detalles && detalles?.estatus == 400) {
      Swal.fire({
        icon: 'error',
        title: detalles?.mesagge,
        confirmButtonColor: '#ff0000',
      });

      if (urlImg != "" && urlImg != undefined) {
        deleteImg(urlImg);
      }
    }
  }, [detalles]);

  if (editing && cargando) return <FullScreenLoader />

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h4" gutterBottom style={{ textAlign: "center" }}>
          {editing ? `EDITANDO USUARIO` : `NUEVA USUARIO`}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <StylesBreadCrumb
                component="a"
                label="Inicio"
                icon={<Home fontSize="small" />}
                onClick={() => history.push("/")}
              />
              <StylesBreadCrumb
                component="a"
                label="Usuarios"
                onClick={() => history.push("/usuarios")}
              />
              <StylesBreadCrumb
                component="a"
                label={editing ? `Editar Usuario: ${propiedades.nombreCompleto}` : `Crear Usuario`}
              />
            </Breadcrumbs>
            <br></br>
          </Grid>

          <Grid item xs={12}>
            <Div><strong>{"INFORMACIÓN DEL USUARIO"}</strong></Div>
            <Divider />
          </Grid>
          <Grid item xs={12} md={4} >
            <TextField
              label="INE"
              variant="outlined"
              fullWidth
              helperText={errores?.ine}
              error={Boolean(errores?.ine)}
              size="small"
              onChange={(e) => setPropiedades(prevState => ({
                ...prevState,
                ...prevState.propiedades,
                ine: e.target.value
              }))
              }
              inputProps={{
                value: propiedades.ine,
              }}
            />
          </Grid>


          <Grid item xs={12} md={4} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Switch
              checked={propiedades.admin}
              onChange={
                (e) => {
                  setPropiedades(prevState => ({
                    ...prevState,
                    ...prevState.propiedades,
                    admin: e.target.checked
                  }));
                }
              }
            />
            CEO
          </Grid>

          <Grid item xs={12} md={4} >

          </Grid>


          <Grid item xs={12} md={4} >
            <TextField
              label="Nombre del Usuario"
              variant="outlined"
              fullWidth
              helperText={errores?.nombre}
              error={Boolean(errores?.nombre)}
              size="small"
              onChange={(e) => setPropiedades(prevState => ({
                ...prevState,
                ...prevState.propiedades,
                nombre: e.target.value
              }))
              }
              inputProps={{
                value: propiedades.nombre,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="standard-multiline-static"
              label="Apellido Paterno"
              fullWidth
              variant="outlined"
              typeInput="text"
              size="small"
              inputProps={{
                value: propiedades.apellidoPaterno,
              }}
              onChange={(e) =>
                setPropiedades((prevState) => ({
                  ...prevState,
                  ...prevState.propiedades,
                  apellidoPaterno: e.target.value,
                }))
              }
              helperText={errores?.apellidoPaterno}
              error={Boolean(errores?.apellidoPaterno)}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              label="Apellido Materno"
              variant="outlined"
              fullWidth
              helperText={errores?.apellidoMaterno}
              error={Boolean(errores?.apellidoMaterno)}
              size="small"
              onChange={(e) => setPropiedades(prevState => ({
                ...prevState,
                ...prevState.propiedades,
                apellidoMaterno: e.target.value
              }))
              }
              inputProps={{
                value: propiedades.apellidoMaterno,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Puesto"
              variant="outlined"
              fullWidth
              helperText={errores?.puesto}
              error={Boolean(errores?.puesto)}
              size="small"
              onChange={(e) => setPropiedades(prevState => ({
                ...prevState,
                ...prevState.propiedades,
                puesto: e.target.value
              }))
              }
              inputProps={{
                value: propiedades.puesto,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Correo electronico"
              variant="outlined"
              fullWidth
              helperText={errores?.correo}
              error={Boolean(errores?.correo)}
              size="small"
              onChange={(e) => setPropiedades(prevState => ({
                ...prevState,
                ...prevState.propiedades,
                correo: e.target.value
              }))
              }
              inputProps={{
                value: propiedades.correo,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField type={showPassword}
                label="Contraseña"
                helperText={errores?.contraseña}
                error={Boolean(errores?.contraseña)}
                size="small"
                style={{ width: "100%" }}
                onChange={(e) => setPropiedades(prevState => ({
                  ...prevState,
                  ...prevState.propiedades,
                  contraseña: e.target.value
                }))
                }
                variant="outlined"
              />
              {showPassword === "password" ? (
                <VisibilityOffIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowPassword("text")}
                />
              ) : (
                <VisibilityIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowPassword("password")}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField type={showRepetirPassword}
                label="Repetir Contraseña"
                style={{ width: "100%" }}
                helperText={errores?.repetirContraseña}
                error={Boolean(errores?.repetirContraseña)}
                size="small"
                onChange={(e) => setPropiedades(prevState => ({
                  ...prevState,
                  ...prevState.propiedades,
                  repetirContraseña: e.target.value
                }))
                }
                variant="outlined"
              />
              {showRepetirPassword === "password" ? (
                <VisibilityOffIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowRepetirPassword("text")}
                />
              ) : (
                <VisibilityIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowRepetirPassword("password")}
                />
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'center' }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={3}>
                <Typography variant="h5" gutterBottom>
                  IMAGEN
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={7} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '30px' }}>
                <Divider style={{ marginBottom: "2%" }} />
                <CardMedia
                  component="img"
                  image={propiedades.urlFoto}
                  title=""
                  style={{ width: "150px", height: "150px", objectFit: 'cover', borderRadius: '100%' }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={2}>
                <div>
                  <input
                    hidden
                    accept="image/x-png,image/jpeg,image/gif"
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                    onChange={handleFileRead}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      style={{ color: 'white' }}
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      <PhotoCamera />
                    </Button>
                  </label>
                </div>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Paper>

      <Paper className={classes.paper}>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Firma Usuario
          </Typography>
          <FirmaComponentBeta
            firma={firma}
            disabled={editing ? true : false}
            setFirma={setFirma}
            firmante={propiedades.nombreCompleto}
          />
        </Grid>
      </Paper>

      {
        (!editing) && (
          <Paper className={classes.paper}>
            <Typography style={{ textAlign: 'center' }} variant="h4">PERMISOS</Typography>
            <AsyncAutocompleteSearchBeta
              size="medium"
              label="Sucursales"
              ruta="sucursal"
              labelProp="nombreSucursal"
              extraParams={sucursalParamsMemo}
              clearInput={clearInputSucursal}
              publica={false}
              onChange={setSucursalCallback}
              campoError="idSucursal"
            />
            {
              (sucursal && sucursal != null) && (
                <>
                  <Typography style={{ textAlign: 'center' }} variant="h6">Roles</Typography>
                  <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                    {/* checkbox */}
                    {
                      Object.keys(propiedades?.permisos).map(
                        key => {
                          let rol = propiedades.permisos[key];
                          if (rol.key.charAt(rol.key.length - 1) != "s") {
                            return (
                              <FormControlLabel key={key} control={<Checkbox
                                onChange={e => {
                                  let newPermisos = propiedades.permisos;
                                  newPermisos[key] = { ...newPermisos[key], val: e.target.checked };
                                  setPropiedades({ ...propiedades, permisos: newPermisos });
                                }}
                              />} label={rol.key} />
                            );
                          }
                        }
                      )
                    }
                  </FormGroup>
                  {/* ACORDIONES */}
                  {
                    Object.keys(propiedades?.permisos).map(
                      key => {
                        let permiso = propiedades.permisos[key];
                        if (permiso.key.charAt(permiso.key.length - 1) == "s") {
                          return (
                            <Accordion key={key} style={{ boxShadow: '0 0 6px #0000004a', borderBottom: '2px solid #fff' }}>
                              <AccordionSummary

                                style={{ background: '#1F1F1F', borderTopLeftRadius: '5px', borderTopRightRadius: '5px', color: '#FFF', borderRadio: "none" }}
                                expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography component={'span'}>{key}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography component={'span'}>
                                  <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                    <FormControlLabel className={classes.switchColor} control={<Switch
                                      checked={(propiedades.permisos[key].ver)}
                                      onChange={e => {

                                        let newPermisos = propiedades.permisos;
                                        if (!e.target.checked) {
                                          newPermisos[key] = {
                                            ...newPermisos[key],
                                            ver: e.target.checked,
                                            agregar: false,
                                            editar: false,
                                            eliminar: false,
                                          };
                                        } else {
                                          newPermisos[key] = { ...newPermisos[key], ver: e.target.checked };
                                        }
                                        setPropiedades({ ...propiedades, permisos: newPermisos });

                                      }}
                                      defaultChecked />} label="Ver" />
                                    <FormControlLabel className={classes.switchColor} control={<Switch
                                      checked={(propiedades.permisos[key].agregar)}
                                      onChange={e => {
                                        let newPermisos = propiedades.permisos;
                                        newPermisos[key] = { ...newPermisos[key], agregar: e.target.checked };
                                        setPropiedades({ ...propiedades, permisos: newPermisos });
                                      }}
                                      disabled={(!propiedades.permisos[key].ver)} />} label="Agregar" />
                                    <FormControlLabel className={classes.switchColor} control={<Switch
                                      checked={propiedades.permisos[key].editar}
                                      onChange={e => {
                                        let newPermisos = propiedades.permisos;
                                        newPermisos[key] = { ...newPermisos[key], editar: e.target.checked };
                                        setPropiedades({ ...propiedades, permisos: newPermisos });
                                      }}
                                      disabled={(!propiedades.permisos[key].ver)} />} label="Editar" />
                                    <FormControlLabel className={classes.switchColor} control={<Switch
                                      checked={(propiedades.permisos[key].eliminar)}
                                      onChange={e => {
                                        let newPermisos = propiedades.permisos;
                                        newPermisos[key] = { ...newPermisos[key], eliminar: e.target.checked };
                                        setPropiedades({ ...propiedades, permisos: newPermisos });
                                      }}
                                      disabled={(!propiedades.permisos[key].ver)} />} label="Deshabilitar" />
                                  </FormGroup>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>

                          )
                        }
                      }
                    )
                  }
                </>
              )
            }
          </Paper>

        )
      }

      <Paper className={classes.paper}>
        <Grid item xs={12} xl={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={(admin === true || (permiso && permiso[0]["usuarios"] & 2) === 2) ? false : true}
              loading={cargandoBoton}
              variant="contained"
              size="large"
              onClick={guardarUsuario}
              style={{ color: "#fff" }}
              className={classes.successButton}
            >
              GUARDAR USUARIO
            </LoadingButton>
          </Grid>
        </Grid>
      </Paper>

    </div>
  );
}

export default Index;