/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */

//React
import React, { useEffect, useState } from "react";

import { capitalizeFirst } from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
import FirebaseService from "../../services/firebase";
import Multifile from "../../components/Multifile";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import { useModel } from "../../hooks/useModel";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAlert } from "../../hooks/useAlert";
import Loading from "../../components/FullScreenLoader";
import PageHeader from "../../components/PageHeader";
import InputRegex from "../../components/InputRegex";

//----------------------------------------------------------------------------------------------------------------------

//Librerias
import { Grid, TextField, Divider, Paper, IconButton, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Swal from "sweetalert2";
//----------------------------------------------------------------------------------------------------------------------

//Iconos
import {
  Add,
  AddIcCall,
  Delete,
  Edit,
  Save,
  Email,
  AddCard,
} from "@mui/icons-material";
import { FaUserTag } from "react-icons/fa";
//----------------------------------------------------------------------------------------------------------------------

//Imagenes
//----------------------------------------------------------------------------------------------------------------------

//Styles
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
//----------------------------------------------------------------------------------------------------------------------
const DatosBancarios = ({ onChange, onRemove, banco, numeroTarjeta, cuentaClabe, monedaPago, index, setDisabledByName, ...props }) => {
  const classes = useStyles();
  return (
    <Grid component={Paper} container className={classes.paper} spacing={1} style={{ marginTop: 10 }}>
      <Grid item xs={12}>
        <div><strong>{"Datos Bancarios #" + (index + 1)}</strong></div>
        <Divider />
      </Grid>
      <Grid item xs={12} md={4} style={{ padding: '5px' }}>
        <InputRegex
          label={"Banco " + (index + 1)}
          variant="outlined"
          fullWidth
          size="small"
          onChange={e => onChange("banco", e.target.value)}
          inputProps={{ value: banco }}
        />
      </Grid>
      <Grid item xs={12} md={4} style={{ padding: '5px' }}>
        <InputRegex
          label={"Numero de tarjeta " + (index + 1)}
          variant="outlined"
          fullWidth
          size="small"
          onChange={e => onChange("numeroTarjeta", e.target.value)}
          inputProps={{ value: numeroTarjeta }}
        />
      </Grid>
      <Grid item xs={12} md={4} style={{ padding: '5px' }}>
        <InputRegex
          label={"Cuenta CLABE " + (index + 1)}
          variant="outlined"
          fullWidth
          size="small"
          onChange={e => onChange("cuentaClabe", e.target.value)}
          inputProps={{ value: cuentaClabe }}
        />
      </Grid>
      <Grid item xs={12} md={4} style={{ padding: '5px' }}>
        <FormControl variant="outlined" fullWidth size="small">
          <InputLabel variant="outlined" id="demo-select-small">
            Tipo de Moneda aceptada
          </InputLabel>
          <Select
            variant="outlined"
            size="small"
            id="demo-select-small"
            label="Moneda"
            onChange={e => onChange("monedaPago", e.target.value)}
            inputProps={{ value: monedaPago }}
          >
            <MenuItem value="MXN">MXN$</MenuItem>
            <MenuItem value="USD">US$</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}></Grid>
      <Grid item xs={2} md={2} style={{ backgroundColor: '#1E2024', size: 'small', borderRadius: '5px', textAlign: 'center', marginLeft: 35, padding: '5px' }}>
        <IconButton style={{ width: '100%', borderRadius: 0 }} onClick={onRemove}>
          <Delete style={{ fontSize: "xx-large", color: '#FFF' }} />
        </IconButton>
      </Grid>
    </Grid>
  );
}

const defaultPropsDatosBancarios = {
  banco: "",
  numeroTarjeta: "",
  cuentaClabe: "",
  monedaPago: "",
}

const Index = ({ match, history, dialog, datosBancariosArchivos }) => {
  //Estados Iniciales
  const classes = useStyles();
  const { showAlert } = useAlert();
  const id = match.params.id;
  const editing = !!id;
  const [admin] = useLocalStorage("admin", null);
  const { permiso } = useAuth();
  //----------------------------------------------------------------------------------------------------------------------
  //Estados Iniciales Controller
  const [telefonos, setTelefonos] = useState([]);
  const [correos, setCorreos] = useState([]);
  const [datosBancarios, setDatosBancarios] = useState([defaultPropsDatosBancarios]);
  const [archivo, setArchivo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [propiedades, setPropiedades] = useState({
    nombreProveedor: "",
    rfc: "",
    razonSocial: "",
  });

  const { model, modelLoading, modelError, updateModel, updateModelLoading } =
    useModel({
      name: "proveedores",
      id,
      redirectOnPost: true,
      expand: "telefonos, correos, datosBancarios",
    });
  //----------------------------------------------------------------------------------------------------------------------
  //Funciones Basicas

  //? input de telefonos
  const addInputButton = (e, type) => {
    e.preventDefault();
    if (type === "telefono") {
      setTelefonos([...telefonos, ""]);
    }
    if (type === "correo") {
      setCorreos([...correos, ""]);
    }
    if (type === 'dato-bancario') {
      setDatosBancarios(datosBancarios.concat(defaultPropsDatosBancarios));
    }
  };

  //? remover el input de telefonos
  const handleRemove = (position, type) => {
    if (type === "telefono") {
      setTelefonos([...telefonos.filter((_, index) => index !== position)]);
    }
    if (type === "correo") {
      setCorreos([...correos.filter((_, index) => index !== position)]);
    }
    if (type === 'dato-bancario') {
      const copyRows = [...datosBancarios];
      copyRows.splice(position, 1);
      setDatosBancarios(copyRows);
    }
  };

  //? informacion del input
  const handleChange = (e, index, type, name, value) => {
    if (type === "telefono") {
      telefonos[index] = e.target.value;
      setTelefonos([...telefonos]);
    }
    if (type === "correo") {
      correos[index] = e.target.value;
      setCorreos([...correos]);
    }
  };

  const handleOnChange = (index, name, value) => {
    const copyRows = [...datosBancarios];
    copyRows[index] = { ...copyRows[index], [name]: value };
    setDatosBancarios(copyRows);
  };

  const onGuardar = async () => {
    let title = "";
    let body = propiedades;
    let datoBancario = datosBancarios;
    if (editing) {
      body.idProveedor = id;
    }

    if (datosBancarios) {
      datosBancarios.map(db => {
        if (
          (db.banco === null || db.banco === undefined || db.banco === "") ||
          (db.numeroTarjeta === null || db.numeroTarjeta === undefined || db.numeroTarjeta === "") ||
          (db.cuentaClabe === null || db.cuentaClabe === undefined || db.cuentaClabe === "") ||
          (db.monedaPago === null || db.monedaPago === undefined || db.monedaPago === "")
        ) {
          datoBancario = 0;
          showAlert({
            message: "Ingrese Por lo menos 1 dato bancario.",
            severity: "warning",
          })
        } else {
          datoBancario = datosBancarios
        }
      })
    }

    if (telefonos.length === 0) {
      showAlert({
        message: "Ingrese Por lo menos 1 numero de telefono.",
        severity: "warning",
      });
    } else if (correos.length === 0) {
      showAlert({
        message: "Ingrese Por lo menos 1 correo.",
        severity: "warning",
      });
    } else {
      if (telefonos[0] === "") {
        showAlert({
          message: "El campo no puede estar vacio.",
          severity: "warning",
        });
      } else {
        if (datosBancarios !== 0) {
          const telefono = telefonos.filter((tel) => tel.length > "");
          const correo = correos.filter((email) => email.length > "");
          editing
            ? (title = "¿Deseas Editar este proveedor?")
            : (title = "¿Deseas Guardar este proveedor?");
          const res = await Swal.fire({
            title: title,
            text: "Favor de corroborar sus datos antes de continuar.",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, Continuar!",
            cancelButtonText: "Cancelar",
          });
          if (res.isConfirmed) {
            showAlert({
              message: "Guardando proveedor",
              severity: "warning",
            });

            body = { ...body, telefono: telefonos, correo: correos, datoBancario, files };
            await updateModel(body, true, "proveedores");
          }
        }
      }
    }
  };
  //----------------------------------------------------------------------------------------------------------------------
  //Efectos Callbacks
  useEffect(() => {
    let mounted = true;
    if (mounted && model) {
      model?.telefonos.map((row, i) => {
        if (row.telefono !== telefonos[i]) {
          telefonos.push(row.telefono);
        }
      });
      model?.correos.map((row, i) => {
        if (row.correo !== correos[i]) {
          correos.push(row.correo);
        }
      });
      console.log(model)
      setDatosBancarios(model.datosBancarios);
      setFiles(model?.datosBancariosArchivos)
      setPropiedades(model);
    }
    return () => {
      mounted = false;
      setPropiedades({
        nombreProveedor: "",
        rfc: "",
        razonSocial: ""
      });
    };
  }, [model]);

  if (modelLoading) return <Loading />;

  return (
    <>
      <PageHeader
        history={history}
        title={(!editing ? "NUEVO" : "EDITAR") + " PROVEEDOR"}
        links={[
          {
            to: "/proveedores",
            icon: <FaUserTag fontSize="large" />,
            label: "Proveedores",
          },
          {
            to: !editing ? "/proveedores/nuevo" : "/proveedores/editar/" + id,
            icon: !editing ? (
              <Add fontSize="medium" />
            ) : (
              <Edit fontSize="medium" />
            ),
            label: !editing
              ? "Nuevo"
              : "Editar - " +
              capitalizeFirst(
                propiedades?.nombreProveedor?.toLocaleLowerCase()
              ),
          },
        ]}
        editing={editing}
        dialog={dialog}
      />
      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div>
            <strong>{"Datos del proveedor"}</strong>
          </div>
          <Divider />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputRegex
            label="Nombre del proveedor"
            fullWidth
            variant="outlined"
            value={propiedades?.nombreProveedor}
            helperText={modelError?.nombreProveedor}
            error={Boolean(modelError?.nombreProveedor)}
            size="small"
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                nombreProveedor: e.target.value,
              }))
            }
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputRegex
            label="R. F. C."
            fullWidth
            variant="outlined"
            value={propiedades?.rfc}
            helperText={modelError?.rfc}
            error={Boolean(modelError?.rfc)}
            size="small"
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                rfc: e.target.value,
              }))
            }
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <InputRegex
            label="Razon Social"
            fullWidth
            variant="outlined"
            value={propiedades?.razonSocial}
            helperText={modelError?.razonSocial}
            error={Boolean(modelError?.razonSocial)}
            size="small"
            onChange={(e) =>
              setPropiedades((prevState) => ({
                ...prevState,
                ...prevState.propiedades,
                razonSocial: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      <Grid component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}>

        <Grid item xs={12}>
          <div>
            <strong>{"Datos bancarios "}</strong>
          </div>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          {datosBancarios.map((trabajo, index) => (
            <DatosBancarios
              {...trabajo}
              index={index}
              onChange={(name, value) => handleOnChange(index, name, value, false)}
              onRemove={() => handleRemove(index, 'dato-bancario')}
              key={index}
            />
          ))}

          <Grid item xs={2} md={2} style={{ backgroundColor: '#1E2024', borderRadius: '5px', textAlign: 'center', marginLeft: 35, marginTop: 10 }}>
            <IconButton style={{ width: '100%', borderRadius: 0 }} onClick={(e) => addInputButton(e, 'dato-bancario')}>
              <AddCard style={{ fontSize: "xx-large", color: '#FFF' }} />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>

      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div>
            <strong>{"Archivos Datos bancarios "}</strong>
          </div>
          <Divider />
        </Grid>

        <Multifile loading={loading} setLoading={setLoading} files={files} setFiles={setFiles} firebaseFolder={"Datos_Bancarios"} />

      </Grid>

      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div>
            <strong>{"Telefonos"}</strong>
          </div>
          <Divider />
        </Grid>

        {telefonos.length === 0 && (
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <Grid
              item
              xs={2}
              md={2}
              style={{
                backgroundColor: "#1E2024",
                borderRadius: "5px",
                marginTop: 10,
              }}
            >
              <IconButton
                style={{ width: "100%", borderRadius: 0 }}
                onClick={(e) => addInputButton(e, "telefono")}
              >
                <AddIcCall
                  style={{ width: "100%", fontSize: "xx-large", color: "#FFF" }}
                />
              </IconButton>
            </Grid>
          </Grid>
        )}

        {telefonos.map((phone, index) => (
          <Grid
            item
            xs={12}
            md={12}
            key={index}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <Grid item xs={7} md={7}>
              <InputRegex
                label={`Telefono ${index + 1}`}
                fullWidth
                variant="outlined"
                maxLength={10}
                helperText={modelError?.telefonos}
                error={Boolean(modelError?.telefonos)}
                size="small"
                value={phone?.replaceAll(/[-() ]/g, "")}
                typeInput="phone"
                onChange={(e) => handleChange(e, index, "telefono")}
              />
            </Grid>

            <Grid
              item
              xs={2}
              md={2}
              style={{
                backgroundColor: "#1E2024",
                borderRadius: "5px",
                textAlign: "center",
                marginLeft: 35,
              }}
            >
              <IconButton
                style={{ width: "100%", borderRadius: 0 }}
                onClick={(e) => addInputButton(e, "telefono")}
              >
                <AddIcCall
                  style={{ width: "100%", fontSize: "xx-large", color: "#FFF" }}
                />
              </IconButton>
            </Grid>
            {index !== 0 ? (
              <Grid
                item
                xs={2}
                md={2}
                style={{
                  backgroundColor: "#1E2024",
                  borderRadius: "5px",
                  textAlign: "center",
                  marginLeft: 35,
                }}
              >
                <IconButton
                  style={{ width: "100%", borderRadius: 0 }}
                  onClick={() => handleRemove(index, "telefono")}
                >
                  <Delete style={{ fontSize: "xx-large", color: "#FFF" }} />
                </IconButton>
              </Grid>
            ) : (
              <Grid item xs={1} md={1} />
            )}
          </Grid>
        ))}
      </Grid>

      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <div>
            <strong>{"Correos"}</strong>
          </div>
          <Divider />
        </Grid>

        {correos.length === 0 && (
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <Grid
              item
              xs={2}
              md={2}
              style={{
                backgroundColor: "#1E2024",
                borderRadius: "5px",
                marginTop: 10,
              }}
            >
              <IconButton
                style={{ width: "100%", borderRadius: 0 }}
                onClick={(e) => addInputButton(e, "correo")}
              >
                <Email
                  style={{ width: "100%", fontSize: "xx-large", color: "#FFF" }}
                />
              </IconButton>
            </Grid>
          </Grid>
        )}

        {correos.map((email, index) => (
          <Grid
            item
            xs={12}
            md={12}
            key={index}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <Grid item xs={7} md={7}>
              <TextField
                label={`Correo ${index + 1}`}
                variant="outlined"
                fullWidth
                maxLength={100}
                helperText={modelError?.correos}
                error={Boolean(modelError?.correos)}
                size="small"
                value={email}
                typeInput="text"
                onChange={(e) => handleChange(e, index, "correo")}
              />
            </Grid>

            <Grid
              item
              xs={2}
              md={2}
              style={{
                backgroundColor: "#1E2024",
                borderRadius: "5px",
                textAlign: "center",
                marginLeft: 35,
              }}
            >
              <IconButton
                style={{ width: "100%", borderRadius: 0, size: "small" }}
                onClick={(e) => addInputButton(e, "correo")}
              >
                <Email
                  style={{ width: "100%", fontSize: "xx-large", color: "#FFF" }}
                />
              </IconButton>
            </Grid>
            {index !== 0 ? (
              <Grid
                item
                xs={2}
                md={2}
                style={{
                  backgroundColor: "#1E2024",
                  borderRadius: "5px",
                  textAlign: "center",
                  marginLeft: 35,
                }}
              >
                <IconButton
                  style={{ width: "100%", borderRadius: 0 }}
                  onClick={() => handleRemove(index, "correo")}
                >
                  <Delete style={{ fontSize: "xx-large", color: "#FFF" }} />
                </IconButton>
              </Grid>
            ) : (
              <Grid item xs={1} md={1} />
            )}
          </Grid>
        ))}
      </Grid>

      <Grid
        component={Paper}
        container
        className={classes.paper}
        spacing={1}
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              disabled={
                modelLoading ||
                  updateModelLoading ||
                  admin ||
                  (permiso && permiso[0]["proveedores"] & 2) === 2
                  ? false
                  : true
              }
              loading={modelLoading || updateModelLoading}
              variant="contained"
              onClick={() => onGuardar()}
              size="medium"
              startIcon={<Save />}
              style={{ color: "#FFF" }}
            >
              {!editing ? "GUARDAR" : "MODIFICAR"}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Index;
