//React
import React, { useState, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { baseUrl } from "../../utils/variables";
import { baseStyles, capitalizeFirst } from "../../utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAuth } from "../../hooks/useAuth";
//----------------------------------------------------------------------------------------------------------------------

//Componentes
import {
  Grid,
  Menu,
  MenuItem,
  Switch,
  Avatar,
  Modal,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { useModels } from "../../hooks/useModels";
import TablePageLayout from "../../components/TablePageLayout";
import TablePagination from "../../components/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import InputRegex from "../../components/InputRegex";
import AsyncAutocompleteSearchRework from "../../components/AsyncAutocompleteSearchRework";
import DatePicker from "../../components/DatePickerBeta";
//----------------------------------------------------------------------------------------------------------------------

//Librerias
import Swal from "sweetalert2";
//----------------------------------------------------------------------------------------------------------------------

//Iconos
import {
  Add,
  Edit,
  Delete,
  Search,
  AccessTimeFilled,
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import { MdPictureAsPdf } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import moment from "moment";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";

//----------------------------------------------------------------------------------------------------------------------

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#FFF",
  outline: "none",
  padding: "15px 0 0 0",
  borderRadius: "5px",
  boxShadow: "0 0 15px black",
};

const useStyles = baseStyles();

const Index = () => {
  //Estados Iniciales
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [perPage, setPerPage] = React.useState(10);
  const [pagina, setPagina] = React.useState(1);
  const [openMenuRow, setOpenMenuRow] = React.useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [admin] = useLocalStorage("admin", null);
  const { user, permiso } = useAuth();
  const [idSucursal] = useLocalStorage("idSucursal", null);
  const [sucursal, setSucursal] = useState(null);
  const [clear, setClear] = useState(false);
  const [equipo, setEquipo] = useState(null);
  //----------------------------------------------------------------------------------------------------------------------

  //Estados Iniciales Controller
  const header = [];

  const [propiedades, setPropiedades] = useState({
    folio: "",
    fecha: moment(new Date()).format("YYYY-MM-DDTHH"),
    modelo: "",
    tramo: "",
    hora: "",
    precio: "",
    importe: "",
    trabajo: "",
    noEconomico: "",
    fechaInicio: moment().startOf("month").format("yyyy/MM/DD"),
    fechaFinal: moment(new Date()).format("yyyy/MM/DD"),
  });

  const paramsMemo = useMemo(
    () => ({
      ordenar: "nombreSucursal.asc",
      idSucursal,
    }),
    [idSucursal]
  );

  const parametrosInicialesMemo = useMemo(
    () => ({
      name: "reporte-maquinaria",
      ordenar: "idReporteMaquinaria.desc",
      expand: "equipo, creador",
      extraParams: { idCreador: user?.idUsuario }
    }),
    [user?.idUsuario]
  );

  const equipoParamsMemo = useMemo(
    () => ({
      pagina: 1,
      limite: 10,
      ordenar: "creado.desc",
      idEquipo: equipo,
    }),
    [equipo]
  );

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
    deleteModel,
  ] = useModels({ ...parametrosInicialesMemo });

  //----------------------------------------------------------------------------------------------------------------------

  //Funciones

  const onSearchClicked = async (creado) => {
    let params = {};
    if (creado != null) {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage, creado: creado },
      };
    } else {
      params = {
        ...parametrosInicialesMemo,
        ...propiedades,
        ...{ pagina: pagina, limite: perPage },
      };
    }
    await refreshModels({ isCargando: true, params });
  };

  const changePage = async (page) => {
    setPagina(page + 1);
    let params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ pagina: page + 1, limite: perPage },
    };
    await refreshModels({ isCargando: true, params });
  };

  const changePageRow = async (per) => {
    setPerPage(per);
    const params = {
      ...parametrosInicialesMemo,
      ...propiedades,
      ...{ limite: per, pagina: pagina },
    };
    await refreshModels({ isCargando: true, params });
  };

  //----------------------------------------------------------------------------------------------------------------------

  //Efectos

  //----------------------------------------------------------------------------------------------------------------------
  const setSucursalCallback = useCallback(
    (e, v) => {
      if (v !== "" && v) {
        setSucursal(v);
        setPropiedades({ ...propiedades, idSucursal: v.nombreSucursal });
      } else {
        setSucursal(null);
        setPropiedades({ ...propiedades, idSucursal: "" });
      }
    },
    [setSucursal, setPropiedades, propiedades]
  );

  const setEquipoCallback = useCallback(
    (e, v) => {
      if (v !== "" && v) {
        console.log(propiedades);
        setEquipo(v);
        setPropiedades({
          ...propiedades,
          idEquipo: v.idEquipo,
        });
      } else {
        setEquipo(null);
        setPropiedades("");
      }
    },
    [propiedades]
  );

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={modalStyle}>
          <CloseIcon
            onClick={handleClose}
            style={{
              marginBottom: "15px",
              marginRight: "15px",
              display: "flex",
              marginLeft: "auto",
              cursor: "pointer",
            }}
          />
        </div>
      </Modal>
      <TablePageLayout
        title="Reporte Maquinaria"
        model="reporteMaquinaria"
        history={history}
        actionButton={{
          to: "/reporteMaquinarias/nuevo",
          icon: <Add />,
          label: "Agregar Reporte Maquinaria",
          permiso: "reporteMaquinaria",
        }}
        links={[
          {
            label: "Reporte Maquinaria",
            icon: <ContentPasteIcon />,
          },
        ]}
        SearchComponents={
          <>
            <Grid item xs={12} md={3}>
              <AsyncAutocompleteSearchRework
                label="Equipo"
                variant="outlined"
                name="equipo"
                labelProp="nombreEquipo"
                extraParams={equipoParamsMemo}
                onChange={setEquipoCallback}
                campoError="idEquipo"
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                color="primary2"
                label="Modelo"
                variant="outlined"
                fullWidth
                size="small"
                typeInput="text"
                maxLength={50}
                value={propiedades.modelo}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    modelo: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                color="primary2"
                label="Tramo"
                variant="outlined"
                fullWidth
                size="small"
                typeInput="text"
                maxLength={10}
                value={propiedades.tramo}
                onChange={(e) =>
                  setPropiedades((prevState) => ({
                    ...prevState,
                    ...prevState.propiedades,
                    tramo: e.target.value,
                  }))
                }
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <LoadingButton
                variant="contained"
                onClick={() => onSearchClicked(null)}
                loading={modelsLoading && true}
                style={{
                  float: "right",
                  color: "whitesmoke",
                }}
                startIcon={<Search />}
              >
                Buscar
              </LoadingButton>
            </Grid>

            <Grid item xs={12} md={4}>
              <DatePicker
                labelText="Fecha Inicio"
                fullWidth
                inputFormat="dd/MM/yyyy"
                value={propiedades.fechaInicio}
                size="small"
                onChange={(e) => setPropiedades(prevState => ({
                  ...prevState,
                  ...prevState.propiedades,
                  fechaInicio: moment(e).format("YYYY-MM-DD H:m:s")
                }))
                }
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <DatePicker
                labelText="Fecha Final"
                fullWidth
                inputFormat="dd/MM/yyyy"
                value={propiedades.fechaFinal}
                size="small"
                onChange={(e) => setPropiedades(prevState => ({
                  ...prevState,
                  ...prevState.propiedades,
                  fechaFinal: moment(e).format("YYYY-MM-DD H:m:s")
                }))
                }
              />
            </Grid>


          </>
        }
        DisableComponents={
          <>
            <Grid item xs={8}>
              {/* <FormControlLabel
                control={
                  <Switch
                    disabled={modelsLoading && true}
                    onChange={(e) => {
                      setPropiedades((prevState) => ({
                        ...prevState,
                        ...prevState.propiedades,
                        eliminado: e.target.checked ? 1 : 0,
                      }));
                      onSearchClicked(e.target.checked ? 1 : 0);
                    }}
                  />
                }
                label="Ver Desabilitados"
              /> */}
            </Grid>

            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="error"
                  aria-label="upload picture"
                  component="span"
                >
                  <a
                    className={classes.btnPdf}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}pdf/maquinaria?
                    ${"admin=" + (admin ? 1 : 0)}
                    ${"&uid=" + user?.idUsuario}
                    ${"&idEquipo=" + propiedades.idEquipo}
                    ${"&modelo=" + propiedades.modelo}
                    ${"&tramo=" + propiedades.tramo}
                    ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)
                      }`}
                  >
                    <MdPictureAsPdf />
                  </a>
                </IconButton>
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="warning"
                  aria-label="upload picture"
                  component="span"
                >
                  <a
                    className={classes.btnExcel}
                    target="_blank"
                    rel="noreferrer"
                    href={`${baseUrl}excel/maquinaria?
                    ${"admin=" + (admin ? 1 : 0)}
                    ${"&uid=" + user?.idUsuario}
                    ${"&idEquipo=" + propiedades.idEquipo}
                    ${"&modelo=" + propiedades.modelo}
                    ${"&tramo=" + propiedades.tramo}
                    ${"&eliminado=" + (propiedades.eliminado ? 1 : 0)
                      }`}
                  >
                    <RiFileExcel2Fill />
                  </a>
                </IconButton>
              </Grid>
            </Grid>
          </>
        }
        tableLoading={modelsLoading}
        tableErrors={modelsError}
        TableComponent={
          <TablePagination
            nombrePermiso="reporteMaquinaria"
            permiso={permiso}
            header={[]}
            data={models}
            paginatedApi
            pagina={pagina}
            paginationPageSize={perPage}
            onRowsPerPageChangeApi={changePageRow}
            changePageApi={changePage}
            count={modelsPage !== null ? modelsPage.total : 0}
            labelRowsPerPage={"Renglones por página"}
            extraRows={[
              {
                prop: "folio",
                name: "Folio",
                cell: (row, index) => <p key={index}>{row.folio}</p>,
              },
              {
                prop: "fecha",
                name: "Fecha",
                cell: (row, index) => (
                  <p key={index}>{moment(row.fecha).format("DD/MM/yyy")}</p>
                ),
              },
              {
                prop: "equipo",
                name: "Equipo",
                sortable: false,
                cell: (row, index) => <p key={index}>{row.equipo.nombreEquipo}</p>,
              },
              {
                prop: "modelo",
                name: "Modelo",
                sortable: false,
                cell: (row, index) => <p key={index}>{row.modelo}</p>,
              },
              {
                prop: "tramo",
                name: "Tramo",
                sortable: false,
                cell: (row, index) => <p key={index}>{row.tramo}</p>,
              },
              {
                prop: "hora",
                name: "Hora",
                sortable: false,
                cell: (row, index) => <p key={index}>{row.hora}</p>,
              },
              {
                prop: "precio",
                name: "Precio",
                sortable: false,
                cell: (row, index) => <p key={index}>{row.precio}</p>,
              },
              {
                prop: "importe",
                name: "importe",
                sortable: false,
                cell: (row, index) => <p key={index}>{row.importe}</p>,
              },
              {
                prop: "trabajo",
                name: "Trabajo",
                sortable: false,
                cell: (row, index) => <p key={index}>{row.trabajo}</p>,
              },
              {
                prop: "creador",
                name: "Nombre de quien la elaboro",
                sortable: false,
                cell: (row, index) => <p key={index}>{row?.creador?.nombreCompleto}</p>
              }
            ]}
          />
        }
      />
    </>
  );
};
export default Index;
