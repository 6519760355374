/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { formatPhoneNumber, formatNumber, internationalCurrency, formatCurrency } from "../utils";
import {
  TextField,
  OutlinedInput,
  FormControl,
  Input,
  InputLabel,
  FormHelperText
} from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';

export default function InputRegex({
  id = "textfied-regex-beta",
  typeInput = "text",
  variant = "standard",
  type = "text",
  label = "",
  onChange,
  onBlur,
  size = "medium",
  helperText,
  disabled = false,
  error,
  fullWidth,
  value = "",
  maxLength = "150",
  minLength = "1",
  onKeyUp,
  multiLine2,
  rows,
  ...rest
}) {
  const [valueText, setValueText] = useState("");

  const newDecimal = (e) => {
    if (e.target.value != "") {
      let v = e.target.value.replaceAll(/,/g, "");
      let f = formatNumber(v);
      setValueText(f);
    }
  }

  useEffect(() => {
    if (typeInput == "phone") {
      const valoresAceptados = /^[0-9]+$/;
      if (value.match(valoresAceptados) || value == "") {
        setValueText(formatPhoneNumber(value));
      }
    } else if (typeInput == "text") {
      setValueText(value);
    } else if (typeInput == "nombre") {
      const valoresAceptados = /^(([A-Za-z]+[,.]?[ ]?|[a-z]+['-]?)+)$/g;
      if (value.match(valoresAceptados) || value == "") {
        setValueText(value);
      }
    } else if (typeInput == "ine") {
      const valoresAceptados = /^[A-Za-z0-9\s]+$/g;
      if (value.match(valoresAceptados) || value == "") {
        setValueText(value);
      }
    } else if (typeInput == "number") {
      const valoresAceptados = /^[0-9]+$/;
      if (value.toString().match(valoresAceptados) || value == "") {
        setValueText(value);
      }
    } else if (typeInput == "currency") {
      const valoresAceptados = /^[0-9]+[.]?[0-9]?[0-9]?$/g;
      if (value.toString().match(valoresAceptados) || value == "") {
        setValueText(value);
      }
    } else if (typeInput == "%") {
      const valoresAceptados = /^[0-9]+[.]?[0-9]?[0-9]?$/g;
      if (value.toString().match(valoresAceptados) || value == "") {
        setValueText(value);
      }
    }
    else if (typeInput == "decimal") {
      const valoresAceptados = /^[0-9]+[.]?[0-9]?[0-9]?$/g;
      if (value.toString().match(valoresAceptados) || value == "") {
        setValueText(value);
      }
    }
  }, [value, typeInput])

  if (typeInput == "currency") {
    if (variant == "outlined") {
      return (
        <FormControl size={size} fullWidth={fullWidth} variant="outlined">
          <InputLabel htmlFor={id}>{label}</InputLabel>
          <OutlinedInput
            id={id}
            label={label}
            onChange={onChange}
            disabled={disabled}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            endAdornment={<InputAdornment position="end">MxN</InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
              maxLength: maxLength,
              minLength: minLength,
              value: valueText
            }}
            error={Boolean(error)}
          />
          <FormHelperText id="outlined-weight-helper-text" style={{ color: "#B65662" }}>{helperText}</FormHelperText>
        </FormControl>
      )
    }
    if (variant == "standard") {
      return (
        <FormControl size={size} fullWidth={fullWidth} variant="standard">
          <InputLabel htmlFor={id}>{label}</InputLabel>
          <Input
            id={id}
            label={label}
            onChange={onChange}
            disabled={disabled}
            startAdornment={valueText && <InputAdornment position="start">$</InputAdornment>}
            endAdornment={valueText ? <InputAdornment position="end">MxN</InputAdornment> : <InputAdornment position="start">$</InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
              maxLength: maxLength,
              minLength: minLength,
              value: valueText
            }}
            error={Boolean(error)}
          />
          <FormHelperText id="outlined-weight-helper-text" style={{ color: "#B65662" }}>{helperText}</FormHelperText>
        </FormControl>
      )
    }
  }
  else if (typeInput == "%") {
    if (variant == "outlined") {
      return (
        <FormControl size={size} fullWidth={fullWidth} variant="outlined">
          <InputLabel htmlFor={id}>{label}</InputLabel>
          <OutlinedInput
            id={id}
            label={label}
            onChange={onChange}
            disabled={disabled}
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
              maxLength: maxLength,
              minLength: minLength,
              value: valueText
            }}
            error={Boolean(error)}
          />
          <FormHelperText id="outlined-weight-helper-text" style={{ color: "#B65662" }}>{helperText}</FormHelperText>
        </FormControl>
      )
    }
    if (variant == "standard") {
      return (
        <FormControl size={size} fullWidth={fullWidth} variant="standard">
          <InputLabel htmlFor={id}>{label}</InputLabel>
          <Input
            id={id}
            label={label}
            onChange={onChange}
            disabled={disabled}
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
              maxLength: maxLength,
              minLength: minLength,
              value: valueText
            }}
            error={Boolean(error)}
          />
          <FormHelperText id="outlined-weight-helper-text" style={{ color: "#B65662" }}>{helperText}</FormHelperText>
        </FormControl>
      )
    }
  } else {
    return (
      <TextField
        id={id}
        size={size}
        label={label}
        disabled={disabled}
        fullWidth={fullWidth}
        variant={variant}
        onChange={onChange}
        onKeyUp={onKeyUp}
        type={type}
        helperText={helperText}
        error={Boolean(error)}
        inputProps={{
          value: valueText,
          maxLength: maxLength,
          minLength: minLength
        }}
        {...rest}
      />
    )
  }

}